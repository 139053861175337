import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { firstValueFrom } from "rxjs";
import { HttpTranslateUrl } from "./constants/languages.constants";
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http,HttpTranslateUrl);
}

export function appInitializerFactory(translate: TranslateService) {
    return () => firstValueFrom(translate.use('es'));
  }