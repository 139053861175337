import { WebviewManager } from '@core/services/webview-manager/webview-manager';
import { Injectable, inject } from '@angular/core';
import { SegmentService } from './segment/segment.service';
import {  IEventBaseDataSchema, IEventDataSchema, ITrackData, IUserTrack, ProjectTag, TrackingEvent } from './interfaces/tracking-manager.interfaces';
import { filter, first, map, Observable, take, tap } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TRACK_DATA_CONSTANTS } from './constants/tracking.constants';
import { ErrorModak } from '@core/errors/models/interface/error.old-modak';
import { TrackingUtils } from './tracking.utils';
import { IDeviceEventSchema } from './interfaces/device.event.data.interfaces';
import { IErrorEventDataDefinition, IErrorEventDataSchema } from './interfaces/error.event.data.interfaces';
import { IMoEngageUserEvent, IUserEventDataDefinition, IUserEventSchema } from './interfaces/user.event.data.interfaces';
import { ErrorPlatform } from '@core/errors/models/types/errors.types';
import { ErrorsUtils } from '@core/errors/errors.utils';
import { AuthUtils } from 'src/app/modules/authentication/authentication.utils';
import { Store } from '@ngrx/store';
import { GlobalState } from '@core/global-state/app.reducer';
import { selectUser } from '@pages/auth/state/authentication.selectors';
import { MODAK_GOAL } from '@pages/auth2/models/constants/auth.constant';

@Injectable({
    providedIn: 'root'
})
export class TrackingManager {
    //#region [---- DEPENDENCIES ----]
    private readonly segmentService: SegmentService = inject(SegmentService)
    private readonly deviceService: DeviceDetectorService = inject(DeviceDetectorService)
    private readonly trackingUtils : TrackingUtils = inject(TrackingUtils)
    private readonly errorsUtils: ErrorsUtils = inject(ErrorsUtils)
    private readonly authUtils: AuthUtils = inject(AuthUtils);
    private readonly store: Store<GlobalState> = inject(Store);
    private readonly webViewManager : WebviewManager = inject(WebviewManager)
    //#endregion
    
    /**
    * @deprecated Use trackEventV2
    */
    public trackEvent(event: TrackingEvent, project_tag: ProjectTag, data?: any, userTrack?: IUserTrack, trackPii?: boolean) {
        const deviceInfo = this.deviceService.getDeviceInfo();
        const trackData: ITrackData = {
            source: this.webViewManager.isWebViewActive() ? TRACK_DATA_CONSTANTS.MOBILE : TRACK_DATA_CONSTANTS.SOURCE,
            data: data,
            browser: deviceInfo.browser,
            device: deviceInfo.deviceType,
            project_tag: project_tag,
            user_email: TRACK_DATA_CONSTANTS.DEFAULT_EMAIL
        };        
        this.setUserData(trackData, userTrack, trackPii);
        this.setError(trackData);        
        this.segmentService.trackEvent(event, trackData);
    }

    public trackEventV2(trackEventName:TrackingEvent,eventData?:IEventBaseDataSchema,error?: ErrorPlatform,userTrack?: IUserTrack, trackPii?: boolean){
        const deviceInfo: IDeviceEventSchema = this.getDeviceInfo()
        const user: IUserEventSchema = this.getUserSchema(userTrack,eventData?.user)
        const modakGoal = this.getModakGoalFromLocalStorage();
        if (modakGoal) user.user.goal_with_modak = modakGoal;
        const {user_uuid,user_type} :IMoEngageUserEvent = this.trackingUtils.transformUserToUserForMoEngageUser(user?.user)
        const errorSchema =  this.getErrorSchema(error)
        const event: IEventDataSchema = {...deviceInfo,...eventData,...user,user_uuid,user_type,...errorSchema }
        if(!trackPii) {
            event.user.email = undefined
            event.user.first_name = undefined
            event.user.last_name = undefined
            event.user.name = undefined
            event.user.phone = undefined
        }
        this.segmentService.trackEventV2(trackEventName,event);
    }

    private getModakGoalFromLocalStorage() {
        return localStorage.getItem(MODAK_GOAL);
    }

    private getErrorSchema(error?: ErrorPlatform): IErrorEventDataSchema | null {
        if(!error) return null
        const errorData = this.errorsUtils.getEventDataError(error)
        if (errorData)
            return {
                error: errorData
            }
        return null
    }

    private setUserData(trackData: ITrackData, userTrack?: IUserTrack, trackPii?: boolean): void {
        if (userTrack) {
            trackData.user_uuid = userTrack?.uuid;
            trackData.user_type = userTrack?.type;
            trackData.user_phone = userTrack?.phone;
            trackData.user_email = userTrack?.email ?? TRACK_DATA_CONSTANTS.DEFAULT_EMAIL
            if (userTrack?.email) trackData.user_email = userTrack?.email;

            if(!trackPii) {
                trackData.user_phone = undefined
                trackData.user_email = undefined
            }

            return;
        }

        this.getUserTrack().pipe(first()).subscribe(user => {
            if (user) {
                trackData.user_uuid = user?.uuid;
                trackData.user_type = user?.type;
                trackData.user_phone = user?.phone;
                trackData.user_email = user?.email ?? TRACK_DATA_CONSTANTS.DEFAULT_EMAIL;

                if(!trackPii) {
                    trackData.user_phone = undefined
                    trackData.user_email = undefined
                }
            }
        });
    }
    private setError(trackData: ITrackData): void {
        const errorProperty = 'error'
        const errorsProperty = 'errors'
        if(trackData?.data?.hasOwnProperty(errorProperty)) {
            const error = trackData.data.error
            const modakError = error as ErrorModak
            if (error?.hasOwnProperty(errorProperty)) {
                const bodyHttpError = error?.error
                
                if(bodyHttpError?.hasOwnProperty(errorsProperty)) {
                    if(bodyHttpError?.errors?.length > 0) {
                        const lastError = bodyHttpError?.errors[bodyHttpError?.errors?.length - 1];
                        if(lastError) {
                            const lastErrorData :  IErrorEventDataDefinition = {
                                code: lastError?.code,
                                message: lastError?.message,
                            }
                            trackData.error = lastErrorData
                            delete trackData.data.error
                            return;
                        }
                    }
                }
            }

            if(modakError && modakError.error) {
                const errorForEvent:IErrorEventDataDefinition = {
                    code: modakError?.error?.code,
                    message:modakError?.error?.cause
                }
                trackData.error = errorForEvent
                delete trackData.data.error
                return;
            }
        }
    }

    private getUserSchema(userTrack?:IUserTrack,user?:IUserEventDataDefinition) : IUserEventSchema{
        let userSchema : IUserEventSchema = {
            user: null
        }
        if (userTrack) {
            userSchema=this.trackingUtils.transformUserTrackToUserUserSchema(userTrack)
        } else {
            this.getUserTrack().pipe(
            take(1),
            filter(user=>Boolean(user)),
            tap(user=>{
                userSchema = this.trackingUtils.transformUserTrackToUserUserSchema(user)
            }))
            .subscribe()
        }
        userSchema.user = {...userSchema.user,...user}
        return userSchema
    }
    private getDeviceInfo() :IDeviceEventSchema{

        const {browser}= this.deviceService.getDeviceInfo();
        return this.trackingUtils.transformDeviceInfoToDeviceSchema(browser)
    }

    //REFACTOR: In a future, when auth module is refactor. Think better the next one:
    private getUserTrack(): Observable<IUserTrack> {
        return this.store.select(selectUser).pipe(
          filter(Boolean),
          map((user) => {
            let tokenUserId = null;
            if (user && user.accessToken) {
                const tokenDecoded = this.authUtils.getTokenDecodedUser(user.accessToken);
                tokenUserId = tokenDecoded.user_uuid
            }
            return {
              uuid: user?.id ?? tokenUserId,
              email: user?.email,
              phone: user?.phone,
              type: user.role,
              name: user?.name
            };
          })
        );
      }
}