import { Component, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'mdk-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Input() title?:string;
  @Input() acceptText?:string;
  @Input() cancelText?:string;
  @Input() showModal:boolean = false
  @Input() message:string;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  checkCloseModal(event:MouseEvent){
    event.preventDefault()
    const element = event.target as HTMLElement
    const idElement = element.getAttribute('id')
    if(idElement === 'modalBackground') this.modalEmit(false)
  }

  modalEmit(event:boolean){
    this.closeModal.emit(event)
  }
}
