<section class="container">
    <div class="bar-line {{colorClass}}">
    </div>
    <section class="elements-container {{colorPastelClass}}"
    [ngClass]="{'fullWidth': fullWidth}"
    >
        <div class="icon-container">
            <i class="pi {{iconClass}} icon {{colorClass}}"></i> 
        </div>
        <div class="content">
            <h4 class="title {{colorClass}}">{{title}}</h4>
            <section class="data {{colorClass}}">
                <div class="data-element" *ngFor="let message  of messages">
                    <span class="data-simbol">&bull;</span>
                    <p class="data-message">{{message}}</p>
                </div>
            </section>
        </div>
    </section>
</section>