import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFull'
})
export class CapitalizeFullPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';
    const words = value.split(' ')
    const formatWords = words.map(word => word?.toLocaleLowerCase()).map(word => `${word?.charAt(0).toUpperCase()}${word?.slice(1)}`).join(' ')
    return formatWords;
  }
}
