import { COnboardingTypeStates } from "@pages/parents/modules/onboarding/models/constants/onboarding.constants"
import { onboardingTypeState } from "@pages/parents/modules/onboarding/models/types/onboarding.types"
import { C_KYC_IN_REVIEW_SUB_STATES } from "@pages/parents/modules/onboarding/modules/kyc/models/constants/kyc.constants"
import { ISubStateInReview } from "@pages/parents/modules/onboarding/modules/kyc/models/interfaces/kyc.interfaces"
import { StatusKYC2 } from "@pages/parents/modules/onboarding/modules/kyc/models/types/kyc.types"
import { CStatusKYC } from "@pages/parents/pages/onboarding/pages/kyc/models/constants/kyc.constants"
import { OnboardingVersionType } from "../models/interfaces/onboarding.responses"

export interface OnboardingState {
    state:onboardingTypeState,
    isFirstFunding:boolean,
    kycStatus:StatusKYC2,
    kycInReviewSubState:ISubStateInReview
    onboarding_version: OnboardingVersionType | null;
}


export const initialOnboardingState: OnboardingState = {
    isFirstFunding: false,
    state:  COnboardingTypeStates.PENDING,
    kycStatus: CStatusKYC.NONE,
    kycInReviewSubState: {
        subStateInReview: C_KYC_IN_REVIEW_SUB_STATES.NONE,
    },
    onboarding_version: null,
}