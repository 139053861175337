import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedGuard } from './core/guards/logged.guard';
import { RolesAccount } from './pages/auth/interfaces/auth.constant';
import { PublicGuard } from '@core/guards/public.guard';
import { AliasPaymentLinkOutcomeRedirectGuard, AliasPaymentLinkRedirectGuard } from '@core/alias-routes/alias.guard';
import { AliasRoutesComponent } from '@core/alias-routes/alias-routes.component';
import { FeatureFlagsGuard } from '@core/features-flags/feature-flags.guard';
import { CFeatures } from '@core/features-flags/models/constants/features-flags.constants';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth/parents?signin=true',
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
    canMatch: [PublicGuard, FeatureFlagsGuard],
    data: { feature: CFeatures.AUTHENTICATION_V3, path: '/authentication', shouldBe: false }
  },
  {
    path: 'authentication',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
    canMatch: [PublicGuard, FeatureFlagsGuard],
    data: { feature: CFeatures.AUTHENTICATION_V3, path: '/auth/parents?signin=true', shouldBe: true }
  },
  {
    path: 'parents',
    loadChildren: () => import('./pages/parents/parents.module').then(m => m.ParentsModule),
    canActivate: [LoggedGuard],
    data: {
      roles: [RolesAccount.PARENT]
    }
  },
  // ALIAS
  {
    path: 'payment-link/:link-id',
    component:AliasRoutesComponent,
    canActivate:[AliasPaymentLinkRedirectGuard],
  },
  {
    path: 'payment-link-outcome',
    component:AliasRoutesComponent,
    canActivate:[AliasPaymentLinkOutcomeRedirectGuard],
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'auth/parents?signin=true',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
