export const C_WEB_VIEW_ACTIONS = {
  CLEAR_LOCAL_STORAGE: "CLEAR_LOCAL_STORAGE",
} as const;

export const C_WEB_VIEW_MESSAGES = {
  SKIPPED: "SKIPPED",
  PLAID_REDIRECTION : "PLAID_REDIRECTION",
  SHOW_LOADER_TRUE : "SHOW_LOADER_TRUE",
  SHOW_LOADER_FALSE : "SHOW_LOADER_FALSE",
  PLAID_LINK_BANK_ACCOUNT:"PLAID_LINK_BANK_ACCOUNT"
} as const;

export const C_WEB_VIEW_PROPERTIES = {
  MESSAGE: "window:message",
  EVENT:'$event'
} as const;

export const C_IDV_WEBVIEW_STEPS = {
  RESUBMIT: "RESUBMIT",
  FIRST_TIME: "FIRST_TIME"
} as const;
