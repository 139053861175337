import { createAction, props } from "@ngrx/store";
import { onboardingTypeState } from "@pages/parents/modules/onboarding/models/types/onboarding.types";
import { ISubStateInReview } from "@pages/parents/modules/onboarding/modules/kyc/models/interfaces/kyc.interfaces";
import { StatusKYC2 } from "@pages/parents/modules/onboarding/modules/kyc/models/types/kyc.types";
import { OnboardingVersionType } from "../models/interfaces/onboarding.responses";


const setFirstFunding       = createAction('[Onboarding] setFirstFundingUser',props<{isFirstFunding:boolean}>());
const setStateOnboarding    = createAction('[Onboarding] setStateOnboarding',props<{onboardingState:onboardingTypeState}>());
const setKycStatus          = createAction('[Onboarding] setKycStatus',props<{kycStatus: StatusKYC2}>());
const setInReviewSubState   = createAction('[Onboarding] kycInReviewSubState',props<{kycInReviewSubState: ISubStateInReview}>());
const setOnboardingVersion = createAction('[Onboarding] Set Onboarding Version',props<{ onboarding_version: OnboardingVersionType }>());

const onboardingActions = {
    setFirstFunding,
    setStateOnboarding,
    setKycStatus,
    setInReviewSubState,
    setOnboardingVersion
}

export default onboardingActions