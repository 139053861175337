import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "mdk-agreement",
    templateUrl: "./agreement.component.html",
    styleUrls: ["./styles/agreement.component.mobile.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: AgreementComponent,
        },
    ]
})
export class AgreementComponent implements ControlValueAccessor {


    //#region [---- PROPERTIES ----]
    touched = false;
    disabled = false;
    //#endregion

    //#region [---- INPUT OUTPUTS ----]
    // REFACTOR: Change inputs for a list
    @Input() canCheckOnLabel = true;
    @Input() isChecked = false;
    @Input() label: string = "";
    @Input() secondLabel: string = "";
    @Input() textLink: string;
    @Input() link: string;
    @Input() secondTextLink: string;
    @Input() secondLink: string;
    @Input() error: boolean;
    @Input() activeTooltip: boolean;
    @Input() descriptionTooltip: string;
    @Output() isCheckedChange = new EventEmitter<boolean>();
    //#endregion
    
    //#region [---- LOGIC ----]


    

    changeCheck() {
        this.onChange(this.isChecked);
        this.isCheckedChange.emit(this.isChecked);
    }

    checkLabel() {
        if (!this.canCheckOnLabel) return;
        this.isChecked = !this.isChecked;
        this.changeCheck();
    }

    onChange = (isChecked:boolean) => {
        isChecked
    };

    onTouched = () => {};

    writeValue(isChecked: boolean): void {
        this.isChecked = isChecked;
    }

    registerOnChange(fn: (isChecked:boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    //#endregion
}