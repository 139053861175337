<section>
  <section class="input-container" [ngClass]="{ 'disabled-input': disabled, 'invalid-input': isInvalid }">
    <span class="p-float-label">
      <input
        [id]="uniqueId"
        [type]="type"
        pInputText
        class="input-element"
        #inputElement
        [value]="maskToApply ? (inputValue | mask: maskToApply) : inputValue"
        [ngClass]="{ 'input-with-button': buttonText, 'invalid-input': (!buttonText && isInvalid), 'invalid-with-button': (buttonText && isInvalid) }"
        (input)="onInput($event)"
        (blur)="onBlur()"
        (keydown)="onKeyDown($event)"
        [disabled]="disabled"
        [attr.maxlength]="maxCharacterAllowed"
        [placeholder]="placeholder"
        [pattern]="pattern"
      />
      <label class="label-text" for="inputElement" [ngClass]="{ 'label-disabled': disabled, 'label-error': isInvalid, 'top-label': inputValue }">
        {{ label }}
      </label>
      <i *ngIf="isInvalid" class="pi pi-exclamation-circle icon-warning label-error"></i>
      <mdk-btn
        *ngIf="buttonText && !isInvalid"
        class="button-element"
        [customWidth]="buttonCustomWidth"
        [buttonText]="buttonText"
        (buttonClick)="onButtonClick()" />
    </span>
  </section>
  <section class="labels-container">
    <span class="helper-text">{{ helperText || '' }}</span>
    <span *ngIf="showCharacterAllowed && maxCharacterAllowed && inputElement.value.length" class="characters-count-element">{{inputElement.value.length}}/{{maxCharacterAllowed}}</span>
  </section>
  <p *ngIf="isInvalid" class="label-error label-message">{{ labelError }}</p>
  <p *ngIf="labelSuccess" class="label-success label-message">{{ labelSuccess }}</p>
</section>
