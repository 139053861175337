import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IParentTransactionResponse, IFamilyResponse, IAccountTeenResponse, IMemberFullData, IPreloadParentData, IParentInfoCardResource } from './interfaces/parent.responses';
import { Observable, map, forkJoin, of, catchError, throwError } from 'rxjs';
import { ILastTransactionParent, IParentGroupTransaction, IWalletTeen } from './interfaces/parent.interfaces';
import { ParentsFunctions } from './parents.functions';
import { DateTime } from 'luxon';
import { CFormatDate } from 'src/app/shared/utils/dates/date.constants';
import { ErrorsManager } from '@core/errors/errors.manager';
import { CParentsErrorsTitles } from '@pages/parents/models/constants/parents.errors.titles.constants';
import { CParentsLastTransactionsLength } from '@pages/parents/models/constants/parents.transactions.constants';
import { HIDE_SPINNER } from '@core/interceptors/constants/interceptors.constants';

@Injectable({
  providedIn: 'root'
})
export class ParentsApi {

  //#region [---- PROPERTIES ----]
  private USERS_DOMAIN_URL: string = environment.APIS.USERS_DOMAIN_URL;
  private BANKING_DOMAIN_URL: string = environment.APIS.BANKING_DOMAIN_URL;
  //#endregion

  //#region [---- DEPENDENCIES ----]
  private http:HttpClient = inject(HttpClient)
  private parentsFunctions: ParentsFunctions = inject(ParentsFunctions)
  private errorsManager: ErrorsManager = inject(ErrorsManager)
  //#endregion

  //#region [---- LOGIC ----]
  preloadedParentData(invitationCode:string):Observable<IPreloadParentData>{
    return this.http.get<IPreloadParentData>(`${this.USERS_DOMAIN_URL}/invitation/info/v1/${invitationCode}`)
  }

  // Home Parents
  getWalletTeen(id: string): Observable<IWalletTeen | null> {
    const observables = forkJoin({
      account: this.getAccountTeen(id),
      teen: this.getTeen(id)
    });

    return observables.pipe(map(results => {
      const account = results.account;
      const teen = results.teen;

      if (!account || !teen) return null;

      return { ...account, ...teen };
    }))
  }

  getTeenDataWithSSN(id: string):Observable<IMemberFullData | null> {
    const observables = forkJoin({
      cardRequest: this.getTeenCardRequest(id),
      teen: this.getTeen(id)
    });

    return observables.pipe(map(results => {
      const cardRequest = results.cardRequest;
      const teen = results.teen;

      if (!cardRequest || !teen) return null;

      teen.date_of_birth = DateTime.fromISO(cardRequest.cardholder_data.date_of_birth)
        .toFormat(CFormatDate.DATE_OF_BIRTH);
      teen.social_security_number = cardRequest.cardholder_data.id_number;

      return teen;
    }));
  }

  getTeenCardRequest(teenId: string): Observable<IParentInfoCardResource | null> {
    return this.http.get<IParentInfoCardResource[]>(`${this.BANKING_DOMAIN_URL}/card/request/v1`).pipe(
      map(response => {
        const filteredData = response ? response.filter(item => item.cardholder_user_id === teenId) : [];
        return filteredData.length > 0 ? filteredData[0] : null;
      })
    );
  }

  getTeen(id: string):Observable<IMemberFullData | null> {
    if(!id || id === 'undefined')
      return of(null)

    return this.http.get<IMemberFullData>(`${this.USERS_DOMAIN_URL}/family/v1/teen/${id}`).pipe(
      map(response => {
          return response ? this.parentsFunctions.transformMemberFullData(response) : null
        })
      )
  }

  getAccountTeen(id: string): Observable<IWalletTeen | null>{
    if(!id || id === 'undefined')
      return of(null)

    return this.http.get<IAccountTeenResponse>(`${this.BANKING_DOMAIN_URL}/account/v1/teens/${id}`).pipe(
      map(response => {
        return response ? this.parentsFunctions.transformWalletTeen(response) : null
      })
    )
  }

  getWalletsTeens(teens: IMemberFullData[]):Observable<IWalletTeen[]> {
    const observables = forkJoin({
      accounts: this.getAccountTeens(),
      teens: of(teens)
    });

    return observables.pipe(map(results => {
      const accounts = results.accounts;
      const teens = results.teens;

      if(teens.length && accounts.length) {
        const wallets = accounts.map(account => {
          const teen = teens.find(teen => teen.uuid === account.user_id);
          if (teen) {
            return { ...account, ...teen };
          }
          return null
        }).filter(account => account !== null)

        return wallets;
      }

      return []
    }))
  }

  getTeens():Observable<IMemberFullData[]> {
    const context: HttpContext = new HttpContext().set(HIDE_SPINNER, true)
    return this.http.get<IFamilyResponse>(`${this.USERS_DOMAIN_URL}/family/v1`, { context: context }).pipe(
      map(response => {
        return this.parentsFunctions.transformMembersData(response?.teens || [])
      })
    )
  }

  getAccountTeens(hideSpinner:boolean = false): Observable<IWalletTeen[]>{
    const context: HttpContext = new HttpContext().set(HIDE_SPINNER, hideSpinner)
    return this.http.get<IAccountTeenResponse[]>(`${this.BANKING_DOMAIN_URL}/account/v1/teens`, { context:context }).pipe(
      map(response => {
        if (!response) return []
        return response.length ? this.parentsFunctions.transformWalletTeens(response) : []
      })
    )
  }

  getTransactions():Observable<IParentGroupTransaction[]>{
    const url = `${this.BANKING_DOMAIN_URL}/account/v1/transactions`
    return this.http.get<IParentTransactionResponse[]>(url).pipe(
      map(response => {
          if (!response) return [];
          return response.length ? this.parentsFunctions.agroupTransactionByDate(response) : [] 
        })
      )
  }

  getLastTransactions(id?:string):Observable<ILastTransactionParent[]>{
    const url = `${this.BANKING_DOMAIN_URL}/account/v1/transactions/${id ? `:${id}` : ""}`
    return this.http.get<IParentTransactionResponse[]>(url).pipe(
      map(response => {
        if (!response) return [];
        const lastTransactions:ILastTransactionParent[] = response.map(transaction => {
          return this.parentsFunctions.transformTransaction(transaction)
        })
        if (!lastTransactions || !lastTransactions.length) return [];
        if (lastTransactions.length > CParentsLastTransactionsLength)
          return lastTransactions.slice(0,CParentsLastTransactionsLength)
        return lastTransactions
      })
    )
  }

  getTeenTransactions(teenId:string):Observable<IParentGroupTransaction[]> {
    if (!teenId || teenId === 'undefined')
      return of([])
    
    const url = `${this.BANKING_DOMAIN_URL}/account/v1/teens/${teenId}/transactions`;
    return this.http.get<IParentTransactionResponse[]>(url).pipe(
      map(response => {
          if(!response) return [];
          return response.length ? this.parentsFunctions.agroupTransactionByDate(response) : [] 
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorsManager.manageErrors(error, null, {
          title: CParentsErrorsTitles.TEEN_TRANSACTIONS,
        })
        return throwError(() => error);
      })
    )
  }

  getLastTeenTransactions(teenId:string):Observable<ILastTransactionParent[]> {
    if (!teenId || teenId === 'undefined')
      return of([])

    const url = `${this.BANKING_DOMAIN_URL}/account/v1/teens/${teenId}/transactions`;
    return this.http.get<IParentTransactionResponse[]>(url).pipe(
      map(response => {
        if(!response) return [];
        const lastTransactions:ILastTransactionParent[] = response.map(transaction => {
          return this.parentsFunctions.transformTransaction(transaction)
        })
        if (!lastTransactions || !lastTransactions.length) return [];
        if (lastTransactions.length > CParentsLastTransactionsLength)
          return lastTransactions.slice(0,CParentsLastTransactionsLength)
        return lastTransactions
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorsManager.manageErrors(error, null, {
          title: CParentsErrorsTitles.LAST_TEEN_TRANSACTIONS,
        })
        return throwError(() => error);
      })
    )
  }
  //#endregion
}

