export const CtypeUser = {
    TEEN: 'TEEN',
    PARENT: 'PARENT',
  } as const;
  

  export const CCardRequestStatus = {
    PENDING_PARENT_DATA:    'pending_parent_data',
    PENDING_PARENT_KYC:     'pending_parent_kyc',
    PARENT_KYC_APPROVED:    'parent_kyc_approved',
    PARENT_KYC_IN_REVIEW:   'parent_kyc_in_review',
    PARENT_KYC_DECLINED:    'parent_kyc_declined',
    PARENT_DATA_CONFIRMED:  'parent_data_confirmed',
    REQUESTED: 'requested',
  } as const;

  export const CCardRequestQueryStatusResponse = {
    PARENT_KYC_APPROVED:    'approved',
    PARENT_KYC_IN_REVIEW:   'review',
    PARENT_KYC_DECLINED:    'declined',
  } as const;

  export const CCardType = {
    PHYSICAL: 'PHYSICAL',
    VIRTUAL: 'VIRTUAL',
  } as const;


  export const CParentPriorityType = {
    STANDARD: 'STANDARD',
    PRIORITY: 'PRIORITY',
  } as const;

  export const CParentTransactionType = {
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT',
    FEE: 'FEE',
    REFUND: 'REFUND',
    FUNDING: 'FUNDING'
  } as const;

  export const CParentTransactionProcessStatus = {
    CANCELED: 'CANCELED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
  } as const;

  export const CParentTransferType = {
    INTRABANK: 'intrabank',
    ACH: 'ach',
    CARD: 'card',
    DOMESTICWIRE: 'domesticWire',
    INTERNATIONALWIRE: 'internationalWire',
    DEBIT_CARD: 'debitCard',
    SOLID_CARD: 'solidCard',
    PHYSICALCHECK: 'physicalCheck',
    BUY: 'buy',
    SELL: 'sell',
    SEND: 'send',
    RECEIVE : 'receive'
  } as const;

  export const CStatusShippingCard ={
    PENDING:'pending',
    SHIPPED:'shipped',
    DELIVERED: 'delivered',
    RETURNED: 'returned',
    FAILURE: 'failure',
    CANCELED: 'canceled',
    NO_REQUESTED: 'no_requested'
 }

 export const CWalletNames = {
  WALLET: 'Wallet'
 }