import { NgModule } from '@angular/core';
import { StaticFilesManager } from '@core/static-serve/static-serve.manager';
import { StaticServePipe } from '@core/static-serve/static-serve.pipe';



@NgModule({
  declarations: [
    StaticServePipe
  ],
  providers:[
    StaticFilesManager
  ],
  exports:[
    StaticServePipe
  ]
})
export class StaticServeModule { }
