<section *ngIf="item" class="container" [ngClass]="{ 'container-ends': item.endsTime }">
  <section class="icon-line-container">
    <figure class="icon-container">
      <img class="icon-element" [ngSrc]="item.iconPath | staticServe : folderPath" width="24" height="24" alt="" />
    </figure>
    <div *ngIf="!item.endsTime && !item.notLine" class="line"></div>
  </section>
  <section class="text-container">
    <h1 class="text-title-element">{{ item.title }}</h1>
    <p *ngIf="item.description" class="text-description-element">
      {{ item.description }}
    </p>
    <ng-container *ngIf="item?.shareLink">
      <section class="share-links-container">
        <div class="share-links-buttons">
          <p class="links-url-text">{{ item.shareLink.url }}</p>
          <div class="link-buttons">
            <mdk-copy-clipboard class="copy-clipboard-button" [messageToCopy]="item.shareLink.url" />
            <button pButton type="button" icon="pi pi-qrcode" (click)="isDisplayingModal = true" class="p-button-rounded p-button-shared-link-icon"></button>
          </div>
        </div>
      </section>
      <mdk-modal class="modal-container" (hide)="isDisplayingModal = false" [display]="isDisplayingModal">
        <section class="qr-container">
          <img [ngSrc]="'qr-onboarding.svg' | staticServe" width="290" height="287" />
          <p>{{ item.shareLink.description }}</p>
        </section>
      </mdk-modal>
    </ng-container>
  </section>
</section>
