import { FIVE_SECONDS, MINUTE } from "@shared/models/constants/time.constant";

export const CPaymentLinkOutcomeQueryParams = {
    ID: 'id',
    SINGLE_PAYMENT: 'single_payment'
} as const;

export const CPaymentLinkOutcomePulling = {
    INTERVAL_DURATION: FIVE_SECONDS,
    MAX_TRIES_IN_MINUTES: (MINUTE / FIVE_SECONDS)
} as const;