import { Directive, ElementRef, Output, EventEmitter, Input, AfterViewInit, NgZone, OnDestroy, inject } from '@angular/core';
import { ErrorsManager } from '@core/errors/errors.manager';
import { NewErrorBodyModak, NewErrorModak } from '@core/errors/models/interface/error-modak';
import {Address} from "./models/interfaces/objects/address";
import {Options} from "./models/interfaces/objects/options/options";

declare var google: any;
@Directive({
  selector: '[mdkGooglePlacesModak]'
})
export class GooglePlacesModakDirective implements AfterViewInit,OnDestroy {

  //#region [---- DEPENDENCIES ----]

  private el:ElementRef = inject(ElementRef)
  private ngZone:NgZone = inject(NgZone)
  private errorsManager:ErrorsManager = inject(ErrorsManager)

  //#endregion

  //#region [---- INPUTs & OUTPUTs ----]

  @Input() optionsAddress: Options;
  @Output() placeSelected = new EventEmitter<any>();

  //#endregion

  //#region [---- PROPERTIES ----]

  private autocomplete: any;
  private eventListener: any;
  private inputElement:HTMLInputElement;
  public place: Address;

  //#endregion

  //#region [---- LIFE CYCLES ----]

  ngAfterViewInit(): void {
    if (!this.optionsAddress) this.optionsAddress = new Options();
    this.initAutocomplete();
  }

  ngOnDestroy(): void {
    this.removeEventListeners();
  }

  //#endregion

  private initAutocomplete() {
    if (!this.isGoogleLibExists()) {
      const bodyError: NewErrorBodyModak = {
        code :'',
        message :'Google maps library can not be found'
      }
      const error = new NewErrorModak(bodyError)
      this.errorsManager.manageErrors(error, null, {
        title: `Google Maps - Library not found`
      })
      throw error
    }

    this.inputElement = this.el.nativeElement;

    this.autocomplete = new google.maps.places.Autocomplete(this.inputElement, this.optionsAddress);

    if (!this.autocomplete.addListener != null) {
      this.eventListener = this.autocomplete.addListener('place_changed', () => {
          this.handleChangeEvent()
      });

      this.inputElement.addEventListener('keydown', this.onKeyDown);
    }

  }

  private isGoogleLibExists(): boolean {
    return !(!google || !google.maps || !google.maps.places);
  }

  private handleChangeEvent(): void {
    this.ngZone.run(() => {
        this.place = this.autocomplete.getPlace();
        if (this.place) this.placeSelected.emit(this.place);
    });
  }

  private onKeyDown(event: KeyboardEvent): void {
    if (!event.key) {
      return;
    }
  
    const key = event.key.toLowerCase();
  
    if (key === 'enter' && event.target === this.inputElement) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private removeEventListeners(): void {
    if (this.eventListener) {
      google.maps.event.removeListener(this.eventListener);
    }

    if (this.inputElement) {
      this.inputElement.removeEventListener('keydown', this.onKeyDown);
    }
  }


}
