import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FeatureFlagsDirective } from '@core/features-flags/feature-flags.directive';
import { featureFactory } from '@core/features-flags/feature-flags.factory';
import { FeatureFlagsManager } from '@core/features-flags/feature-flags.manager';

@NgModule({
  declarations: [FeatureFlagsDirective],
  providers:[
    {
      provide: APP_INITIALIZER,
      useFactory:featureFactory,
      deps: [FeatureFlagsManager],
      multi:true
    }
  ],
  exports:[FeatureFlagsDirective]
})
export class FeatureFlagsModule { }
