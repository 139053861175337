import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CErrorReferralsContext } from '@core/errors/models/constants/contexts/referrals.context';
import { GlobalState } from '@core/global-state/app.reducer';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CReferralsIcon } from '@pages/parents/modules/referrals/models/constants/referrals.rewards.constants';
import { selectRewardAmount } from '@pages/parents/modules/referrals/state/referrals.selectors';
import { ReferralsApi } from '@pages/parents/modules/referrals/referrals.api';
import { BtnModakComponent } from '@shared/components/dumb/button/button.component';
import { Observable, first, iif, map, of, switchMap, tap } from 'rxjs';
import * as referralActions from '@pages/parents/modules/referrals/state/referrals.actions';
import { TrackingManager } from 'src/app/configs/libraries/tracking-manager/tracking-manager';
import { REFERRALS_EVENTS } from '@pages/parents/modules/referrals/models/constants/referrals.events.constants';
import { IReferralReward } from '@pages/parents/modules/referrals/models/interfaces/referrals.interfaces';
import { PROJECT_TAGS } from 'src/app/configs/libraries/tracking-manager/constants/projects.constants';

@Component({
  selector: 'mdk-menu-parent-referral-button',
  templateUrl: './menu-parent-referral-button.component.html',
  styleUrls: [
    './styles/menu-parent-referral-button.component.mobile.scss',
  ],
  standalone: true,
  imports: [
    BtnModakComponent,
    TranslateModule
  ],
  providers: [ReferralsApi]
})
export class MenuParentReferralButtonComponent implements OnInit {
  
  //#region [---- [INPUTS] ----]
  @Input() public showButtonText: boolean = true
  //#endregion

  //#region [---- [PROPERTIES] ----]
  public buttonText: string
  public buttonIconSrc: string = CReferralsIcon
  //#endregion

  //#region [---- [DEPENDENCIES] ----]
  private readonly translate: TranslateService = inject(TranslateService)
  private readonly route: Router = inject(Router);
  private readonly referralsApi: ReferralsApi = inject(ReferralsApi);
  private readonly store: Store<GlobalState> = inject(Store<GlobalState>);
  private readonly trackingManager: TrackingManager = inject(TrackingManager)
  //#endregion

  //#region [---- [LIFE CYCLES] ----]
  ngOnInit(): void {
    if(this.showButtonText) {
      this.loadRewardAmount()
    }
  }

  //#endregion

  //#region [---- [LOGIC] ----]
  public buttonClick(): void {
    this.trackingManager.trackEventV2(
      REFERRALS_EVENTS.SELECT_PARENTHOME_REFERRALS,
      {
        project_tag: PROJECT_TAGS.REFERRALS
      })
    this.route.navigate([`/parents/referrals`])
  }

  public loadRewardAmount(): void {
    this.store.select(selectRewardAmount).pipe(
      first(),
      switchMap(amount => {
        return iif(() => Boolean(amount <= 0), this.loadReferralRewardAmount(), of(amount))
      }),
      tap(amount => this.setButtonText(amount))
    ).subscribe()
  }

  public loadReferralRewardAmount(): Observable<number> {
    return this.referralsApi.getReferralCode(CErrorReferralsContext.GET_REWARD).pipe(
      tap(response => this.updateState(response)),
      map(response => response?.reward)
    );
  }

  public setButtonText(reward: number): void {
    this.buttonText = `${this.translate.instant('REFERRALS.GET_MONEY')}${reward} - ${this.translate.instant('REFERRALS.REFER_FRIEND')}`
  }

  public updateState(response: IReferralReward): void {
    this.store.dispatch(referralActions.setReferrals(
      {
        code: response?.code,
        reward: response?.reward
      }
    ))
  }
  //#endregion
}
