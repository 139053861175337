import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, Location } from "@angular/common";

@Component({
  selector: 'mdk-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: [
    './styles/back-button.component.mobile.scss'
  ],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule
  ]
})
export class BackButtonComponent {

  //#region [---- PROPERTIES ----]

  public uniqueId: string;

  //#endregion

  //#region [---- [INPUTS & OUTPUTS] ----]
  @Input() enableCallback: boolean;
  @Input() showBackText: boolean = true;
  @Input() greyStyle: boolean;
  @Output() backClick: EventEmitter<void> = new EventEmitter();
  //#endregion

  //#region [---- [DEPENDENCIES] ----]
  private readonly location: Location = inject(Location);
  //#endregion

  //#region [---- LIFE CYCLES ----]

  constructor(){
    this.uniqueId = `QA_BACK_INPUT_COMPONENT`
  }

  //#endregion

  //#region [---- [EVENTS COMPONENTS] ----]
  public onClick(): void {
    this.enableCallback ? this.backClick.emit() : this.location.back()
  }
  //#endregion
}
