<!-- <ng-container *ngIf="item && item.enabled"> -->
<ng-container >
    <li class="list-item" [routerLink]="item.url" [routerLinkActive]="item.classActive && 'link-active'">

        <ng-container *ngIf="item.url">
            <a class="list-item-link" [routerLinkActive]="item.url && 'link-active'">
                <ng-container *ngIf="item.labelTranslate; else labelItem">
                    {{item.label | translate}}
                </ng-container>
                <ng-template #labelItem>
                    {{item.label}}
                </ng-template>
            </a>
        </ng-container>
    </li>
</ng-container>