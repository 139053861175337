import { NgModule } from "@angular/core";
import { MenuAlphabeticSortPipe } from "./menu-alphabetic-sort.pipe";
import { MenuParentItemComponent } from "./components/menu-parent-item/menu-parent-item.component";
import { MenuParentComponent } from "./menu-parent.component";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { ReminderDownloadAppComponent } from "@shared/components/reminder-download-app/reminder-download-app.component";
import { MenuParentBalanceComponent } from "./components/menu-parent-balance/menu-parent-balance.component";
import { MenuParentAccountComponent } from "./components/menu-parent-account/menu-parent-account.component";
import { MenuParentReferralButtonComponent } from "src/app/layouts/components/menu-parent/components/dumb/menu-parent-referral-button/menu-parent-referral-button.component";

@NgModule({
  declarations: [
    MenuParentComponent,
    MenuParentItemComponent,
    MenuAlphabeticSortPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgOptimizedImage,
    ReminderDownloadAppComponent,
    MenuParentReferralButtonComponent,
    MenuParentBalanceComponent,
    MenuParentAccountComponent,
  ],
  exports: [
    MenuParentComponent,
    MenuParentItemComponent,
    MenuAlphabeticSortPipe,
  ]
})
export class MenuParentModule {}
