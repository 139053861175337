import { Injectable, inject } from '@angular/core';
import { Observable, iif, of, BehaviorSubject, Subject, EMPTY } from 'rxjs';
import { switchMap, map, filter, tap, distinctUntilChanged, take, first, timeout, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { FCInitObject, NgxFreshChatService } from 'ngx-freshchat';

import { GlobalState } from '../../../core/global-state/app.reducer';
import { IUser } from 'src/app/pages/auth/interfaces/auth.interfaces';
import { environment } from 'src/environments/environment';
import { ChannelName, IFreshChatUser } from './fresh-chat.interfaces';
import { LINKS } from '@shared/models/constants/links.contants';
import { SECOND } from '@shared/models/constants/time.constant';
import { FRESH_CHAT_EVENTS } from 'src/app/configs/libraries/fresh-chat/fresh-chat.constants';
import { PROJECT_TAGS } from 'src/app/configs/libraries/tracking-manager/constants/projects.constants';
import { TrackingManager } from 'src/app/configs/libraries/tracking-manager/tracking-manager';
import { LogsManager } from '@core/logs/logs.manager';

@Injectable({
    providedIn: 'root',
})
export class FreshChatFunctions {

    //#region [---- [DEPENDENCIES] ----]
    private readonly store: Store<GlobalState> = inject(Store<GlobalState>)
    private readonly chatService: NgxFreshChatService = inject(NgxFreshChatService)
    private readonly trackingManager: TrackingManager = inject(TrackingManager);
    private readonly logsManager: LogsManager = inject(LogsManager);
    //#endregion

    //#region [---- PROPERTIES ----]
    public readonly isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly freshChatTimeout$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    //#endregion

    //#region [---- [PUBLIC LOGIC] ----]
    public listen(): Observable<void> {
        return this.getUser().pipe(
            distinctUntilChanged(),
            switchMap((user) => {
                return iif(() => Boolean(user?.accessToken), this.restartFreshChat(user), this.restartFreshChat())
            })
        )
    }

    public reportCustomerSupport(channelName:ChannelName = "", replyText:string = ""): void {
        if (!this.isInitialized()) {
            window.open(LINKS.MODAK_SUPPORT, "_blank")
            return;
        }

        this.isInitialized$.pipe(
            filter(Boolean),
            first(),
            take(1),
            switchMap(() => this.openChat(channelName, replyText)),
            ).subscribe()
    }

    public openChat(channelName:ChannelName = "", replyText:string = ""): Observable<void> {
        return of(this.isInitialized()).pipe(
            filter(isInitialized => isInitialized),
            filter(() => !this.chatService.isOpen()),
            switchMap(() => of(this.chatService.open({ replyText, name: channelName }))),
        )
    }
    //#endregion

    //#region [---- [PRIVATE LOGIC] ----]
    private getUser(): Observable<IUser> {
        return this.store.select('authentication').pipe(
            map(auth => auth?.user),
        )
    }

    private initChat(user?:IUser): Observable<any> {
        const timeoutSubject = new Subject<void>();
        const TIMEOUT = SECOND * 7
        return of(user).pipe(
            switchMap((user) => {
                return this.chatService.init(this.getFreshChatInitObject(user)).pipe(
                    timeout(TIMEOUT),
                    catchError(error => {
                        timeoutSubject.next();
                        this.freshChatTimeout$.next(true)
                        this.chatService.destroy()
                        this.trackingManager.trackEventV2(FRESH_CHAT_EVENTS.FRESH_CHAT_TIME_OUT,{
                            project_tag: PROJECT_TAGS.APP_ISSUES,
                        },error);
                        return EMPTY;
                    }),
                    take(1)
                )
            }),
            tap(() => {
                this.freshChatTimeout$.next(false)
                this.isInitialized$.next(true)
            }),
        )
    }

    private getFreshChatInitObject(user?: IUser): FCInitObject {
        const freshChatUser = this.getFreshChatUser(user);
        return {
            token: environment.FRESH_CHAT.TOKEN,
            host: environment.FRESH_CHAT.HOST,
            email: freshChatUser.email,
            externalId: freshChatUser.email,
            firstName: freshChatUser.name,
            phone: freshChatUser.phone,
        };
    }

    private getFreshChatUser(user?: IUser): IFreshChatUser {
        return {
            email: user?.email || "",
            name: user?.name || "",
            phone: user?.phone || ""
        };
    }

    private restartFreshChat(user?:IUser): Observable<void> {
        const destroy$ = of(null).pipe(
            map(() => this.chatService.destroy()),
            tap(() => this.isInitialized$.next(false))
        )
        return iif(() => this.isInitialized(), destroy$, of(null))
        .pipe(
            switchMap(() => {
                return this.initChat(user)
            })
        )
    }

    private isInitialized(): boolean {
        try {
            return this.chatService?.isInitialized()
        } catch {
            return false;
        }
    }
    //#endregion
}