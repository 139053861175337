export const CMenuParentAccountActions = {
    ACCOUNT_DETAILS: "account_details",
    SETTINGS: "settings",
    LOGOUT: "logout"
} as const;

export const CMenuParentAccountIcons = {
    ACCOUNT_DETAILS: "pi pi-user icon",
    SETTINGS: "pi pi-cog icon",
    LOGOUT: "pi pi-sign-out icon"
} as const;