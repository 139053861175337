import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StaticServeModule } from '@core/static-serve/static-serve.module';

@Component({
  selector: 'mdk-feature-card',
  templateUrl: './feature-card.component.html',
  styleUrls: ['./styles/feature-card.component.mobile.scss','./styles/feature-card.component.desktop.scss'],
  standalone:true,
  imports:[CommonModule,NgOptimizedImage, StaticServeModule]
})
export class FeatureCardComponent {
//#region [----INPUTS & OUTPUTS ----]
  @Input()title! :string
  @Input()image! :string
  @Input() summaryTitle: string
  @Input() summaryDescription: string
  @Output() cardClicked: EventEmitter<void> = new EventEmitter<void>();
//#endregion

//#region [---- LOGIC ----]
public onCardClicked(): void{
  this.cardClicked.emit()
}
//#endregion

}
