import { commonEnvironment } from "src/environments/environment.common";

export const environment = {
  ...commonEnvironment,
  production: true,
  DOMAIN: 'app.modakmakers.com',
  APIS:{
    MONOLITH_URL: 'https://api.modak.live/v1',
    WEBSOCKET_URL: 'wss://ws.api.modak.live',
    USERS_DOMAIN_URL: 'https://rest.api.modak.live/users',
    KYC_DOMAIN_URL: 'https://rest.api.modak.live/kyc',
    BANKING_DOMAIN_URL: 'https://rest.api.modak.live/banking',
    CHORES_DOMAIN_URL: 'https://rest.api.modak.live/chores',
  },

  RISK:{
    SCRIPT_URL:'https://risk.checkout.com/cdn/risk/1/risk.js',
    PUBLIC_KEY:'sk_fksewslw4gycnpljz3z5kxfple3',
  },

  WEB_VIEW_KEY:'Wmcr5QC66kimB',
  DISCORD_SERVER_ID: '961074073868308480',

  GOOGLE:{
    ...commonEnvironment.GOOGLE,
    GTM_ID: 'GTM-M5XFKFK',
  },

  //SEGMENT
  SEGMENT_WRITE_KEY: 'C53bqpKU8pQ3R7HXPAb6a9WOLAzGeDYy',

  //SENTRY
  SENTRY: {
    ...commonEnvironment.SENTRY,
    ENVIRONMENT: 'production',
    TRACE_SAMPLE_RATE: 0.1,
    REPLAY_SESSION_SAMPLE_RATE: 0.0005,
    REPLAY_ERROR_SAMPLE_RATE: 0.0005
  },

  // VGS
  VGS:{
    ...commonEnvironment.VGS,
    vaultId: 'tntpsikpeyn',
    environment: 'live',
  },

  firebase:{
    ...commonEnvironment.firebase,
    collectionName: 'features-prod'
  },

  // WAF-PROTECTION
  WAF: {
    REGION: 'us-west-2',
    IDENTIFIER: '531ed0153966',
    TOKEN: '21a0adbce995',
    SKIP_KEY: '',
  },

  Cognito: {
    userPoolId: 'us-west-2_PLiPgdKjQ',
    userPoolClientId: '37ihd6tvj18bvk2bjsq2rvdl46',
  }
};