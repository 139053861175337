import { SECOND } from "@shared/models/constants/time.constant";

export const CTimeoutSeconds = SECOND * 1800;

export const CActivityEvents = [
  "mousemove",
  "keydown",
  "click",
  "touchstart",
] as const;
