
<section class="header">
    <ng-container *ngIf="isMobile; else desktopBackButton">
        <section *ngIf="showBackButton" class="back-button" (click)="goToBack()">
            <i class="pi pi-chevron-left icon"></i>
        </section>
        <section *ngIf="!showBackButton" class="empty"></section>
        <section class="title-container" *ngIf="title">
            <section class="labels">
                <h2 class="title">{{title}}</h2>
                <h2 *ngIf="subtitle" class="subtitle">{{subtitle}}</h2>
            </section>
        </section>
        <section *ngIf="!showInfoButton else showInfo" class="empty"></section>
        <ng-template #showInfo>
            <section class="info-button" (click)="onClickInfo()">
                <i class="pi pi-info-circle icon"></i>
            </section>
        </ng-template>
        
    </ng-container>

    <ng-template #desktopBackButton>
        <section *ngIf="showBackButton" class="back-button-container" (click)="goToBack()">
            <i class="pi pi-arrow-left icon"></i>
            <section class="text-container">
                <span class="text">{{"SHARED.BACK" | translate}}</span>
            </section>
        </section>
        <section class="labels-action-container">
            <section class="labels-container">
                <h2 *ngIf="title" class="title">{{title}}</h2>
                <h2 *ngIf="subtitle" class="subtitle">{{subtitle}}</h2>
            </section>
            <section *ngIf="showInfoButton" class="info-button-container" (click)="onClickInfo()">
                <i class="pi pi-info-circle icon"></i>
            </section>
        </section>
    </ng-template>
</section>
