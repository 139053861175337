import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CParentPriorityType } from "../../../pages/parents/interfaces/parent.constant";

@Component({
  selector: "mdk-icon-profile",
  templateUrl: "./icon-profile.component.html",
  styleUrls: ["./styles/icon-profile.component.scss"],
})
export class IconProfileComponent {
  @Input() type: (typeof CParentPriorityType)[keyof typeof CParentPriorityType];
  @Input() name: string = "";
  @Input() imageURL: string = "";
  @Input() icon: string = "";
  @Input() isClickable: boolean = false;
  @Input() itemClass: string = "";
  @Input() removeBackground: boolean = false;
  @Input() customWidthPx?: number;
  @Input() customHeightPx?: number;
  @Input() backgroundColor: string = "";
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  onClick():void {
    if(this.isClickable) this.buttonClick.emit();
  }
}
