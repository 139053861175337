import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ErrorsUtils } from '@core/errors/errors.utils';
import { ILogData, ILogError, ILogUserNotAuthenticated } from '@core/logs/models/interface/error.interface';
import { SentryService } from 'src/app/configs/libraries/sentry/sentry.service';

@Injectable({
  providedIn: 'root'
})
export class LogsManager {

  private readonly sentryService: SentryService = inject(SentryService)
  private readonly errorsUtils: ErrorsUtils = inject(ErrorsUtils);

  public log(data: ILogData, user?: ILogUserNotAuthenticated): void {
    this.sentryService.log(data, user)
  }

  public error(error: ILogError, user?: ILogUserNotAuthenticated): void {
    if(error.error && error.error instanceof HttpErrorResponse) {
      error.apiError = this.errorsUtils.getHttpError(error.error)
    }
    this.sentryService.error(error, user)
  }

}
