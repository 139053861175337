export const TypeVerification = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
} as const;

export const RolesAccount = {
  TEEN: "TEEN",
  PARENT: 'PARENT',
  ADMIN: "ADMIN",
} as const;

export const StatusParent = {
  ON_BOARDING: 'ON_BOARDING',
  REQUEST_CARD: 'REQUEST_CARD',
} as const;
  
export const CInvitationCodeType = {
  CREATED: 'CREATED',
  COMPLETED: 'COMPLETED',
} as const;

export const CAuthSessionStorage = {
  SESSION: 'session',
  USER_NAME: 'userName',
} as const;

export const CTokenDecoded = {
  CUSTOM_USER_UUID: 'custom:user_uuid',
  CUSTOM_ROLES: 'custom:roles',
} as const;


export const CODE_LENGTH: number = 6;

export const TEENS_ALLOWED: boolean = false

