import { ActionCreator, createReducer, on } from "@ngrx/store";
import onboardingActions from "@pages/parents/modules/onboarding/state/onboarding.actions";
import { initialOnboardingState, OnboardingState } from "@pages/parents/modules/onboarding/state/onboarding.state";

const _onboardingReducer = createReducer(initialOnboardingState,
    on(onboardingActions.setFirstFunding, (state, { isFirstFunding }) => ({...state,isFirstFunding})),
    on(onboardingActions.setStateOnboarding, (state, { onboardingState }) => ({...state,state:onboardingState})),
    on(onboardingActions.setKycStatus, (state, { kycStatus }) => ({...state,kycStatus})),
    on(onboardingActions.setInReviewSubState, (state, { kycInReviewSubState }) => ({...state,kycInReviewSubState})),
    on(onboardingActions.setOnboardingVersion, (state, { onboarding_version }) => ({ ...state, onboarding_version }))
);

export function onboardingReducer(state: OnboardingState | undefined, action: ActionCreator) {
    return _onboardingReducer(state, action);
}