import { Component, Input } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { LINKS } from '@shared/models/constants/links.contants';

@Component({
  providers: [ConfirmationService],
  selector: 'mdk-tooltip-modal',
  templateUrl: './tooltip-modal.component.html',
  styleUrls: ['./tooltip-modal.component.scss']
})
export class TooltipModalComponent {

  @Input() title: string;
  @Input() description: string;

  constructor(
    private confirmationService: ConfirmationService,
  ) {}

  openDialog(): void {
    this.confirmationService.confirm({
      message: this.description,
      header: this.title,
      accept: () => {
        window.open(LINKS.MODAK_SUPPORT, "_blank")
      },
      reject: () => {}
    });
  }
  
}
