import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DynamicFontLoaderService {

  //#region [---- [PROPERTIES] ----]
  private readonly path: string = './assets/fonts';
  private readonly ext: string = '.ttf';
  private readonly fontFiles = [
    {
      family: "Poppins",
      name: 'Poppins-Light',
      weight: 300
    },
    {
      family: "Poppins",
      name: 'Poppins-Regular',
      weight: 400
    },
    {
      family: "Poppins",
      name: 'Poppins-Medium',
      weight: 500
    },
    {
      family: "Poppins",
      name: 'Poppins-SemiBold',
      weight: 600
    },
    {
      family: "Poppins",
      name: 'Poppins-Bold',
      weight: 700
    },
    {
      family: "Roboto",
      name: 'Roboto-Bold',
      weight: 700
    }
  ];
  //#endregion

  //#region [---- [LOAD FONTS] ----]
  loadFonts(): void {
    this.fontFiles.forEach((font) => {
      const fontUrl = `${this.path}/${font.name}${this.ext}`;
      const style = document.createElement("style");
      style.appendChild(
        document.createTextNode(`
        @font-face {
          font-family: ${font.family};
          src: local(${font.name}), url('${fontUrl}') format('opentype');
          font-weight: ${font.weight};
          font-display: swap;
        }
      `)
      );
      document.head.appendChild(style);
    });
  }
  //#endregion
}