export const CFormatDate = {
    HOUR_MINUTE_12: 'hh:mm a ',
    DATE_HOUR_MINUTE_12: 'MMMM dd, yyyy, hh:mm a',
    MONTH_DATE_YEAR: 'MM-dd-yyyy',
    MONTH_DATE_SHORT_YEAR: 'mm/dd/yy',
    MONTH_3_LETTERS_DATE_YEAR: 'LLL dd, yyyy',
    MONTH_3_LETTERS_DATE_YEAR_HOUR_MINUTE_12: 'LLL dd, hh:mm a',
    DATE_OF_BIRTH: 'MM/dd/yyyy',
    WEEKDAY_MONTH_DATE: 'EEEE, MMMM dd',
    MONTH_DAY_YEAR_HOUR_MINUTE_MERIDIAN: 'MMMM d, y, h:mm a',
    YEAR_MONTH_DATE: "yyyy-LL-dd",
} as const;

export const CDateSecondsDivisor = {
    HOUR: 3600,
    MINUTE: 60
} as const;

export const CDateZones = {
    AMERICA_NEW_YORK: 'America/New_York'
} as const;