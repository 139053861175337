import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'mdk-card-example',
  templateUrl: './card-example.component.html',
  styleUrls: ['./card-example.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class CardExampleComponent {

}
