<!-- Header -->
<header class="header-container">
    <section class="header-title">
      <ng-container *ngIf="sayHello">{{ "PARENT_PORTAL.HOME.HI" | translate}}, </ng-container>
      <span *ngIf="userName">{{ userName }}</span>
    </section>
    <section class="actions-container">
      <section *ngIf="seeTeenPersonalDetails" class="action-container" (click)="goToTeenPersonalDetails()">
        <i class="pi pi-id-card icon"></i>
        <p class="text" *ngIf="!isMobile">
          {{ "PARENT_PORTAL.TEEN.ACCOUNT_DETAILS" | translate }}
        </p>
      </section>
      <section class="action-container" (click)="goToHelpCenter()">
        <i class="pi pi-question-circle icon"></i>
        <p class="text" *ngIf="!isMobile">
          {{ "PARENT_PORTAL.HOME.HELP_CENTER" | translate }}
        </p>
      </section>
    </section>
    
</header>