import { FIVE_MINUTES, MINUTE, TEN_SECONDS } from "@shared/models/constants/time.constant";

export const PROVIDER_AD_LIST = ['gclid','fbclid','msclkid','twclid','Sccid','ttclid','pclid','qclid','yclid','rdclid','li_fat_id','adobe_mc','gbraid','wbraid']

export const  AD_IDS = 'AD_IDS'

export const  AD_IDS_TEMPORAL = 'AD_IDS_TEMPORAL'

export const MODAK_GOAL = 'GOAL_WITH_MODAK'
  
export const TypeVerification = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
} as const;

export const RolesAccount = {
  TEEN: "TEEN",
  PARENT: 'PARENT',
  ADMIN: "ADMIN",
} as const;

export const StatusParent = {
  ON_BOARDING: 'ON_BOARDING',
  REQUEST_CARD: 'REQUEST_CARD',
} as const;
  
export const CInvitationCodeType = {
  CREATED: 'CREATED',
  COMPLETED: 'COMPLETED',
} as const;

export const CAuthSessionStorage = {
  SESSION: 'session',
  USER_NAME: 'userName',
  INVITATION_CODE : 'invitationCode',
} as const;

export const CTokenDecoded = {
  CUSTOM_USER_UUID: 'custom:user_uuid',
  CUSTOM_ROLES: 'custom:roles',
} as const;

export const CAuthFrom = {
  FROM_SIGNUP: 'sign-up',
  FROM_SIGNIN: 'sign-in',
} as const

export const CAuthRoutes = {
  ROOT: 'authentication',
  SIGNUP: 'sign-up',
  SIGNIN: 'sign-in',
  FORGOT: 'password/forgot',
  RESET: 'password/reset',
  CHANGED_PASSWORD: 'password/changed'
} as const


export const CTimeLifeToastAuth = {
  SEND_CODE: TEN_SECONDS,
  INVALID_CREDENTIALS: TEN_SECONDS,
  SESSION_TIME_OUT: MINUTE,
  TIME_IS_UP: FIVE_MINUTES
} as const;

export const AUTH_QUERY_PARAMS = {
  SIGN_UP: 'signup',
  SIGN_IN_PARENT: 'signin',
  SIGN_IN_TEEN: 'signin-teen',
  INVITATION_CODE: 'invitation_code',
  REFERRAL_CODE: 'referral_code',
  GOAL: 'goal',
} as const;

export const CODE_LENGTH: number = 6;

export const TEENS_ALLOWED: boolean = false;

export const CSignInUserName = 'signInUserName' as const;
export const CSignUpForm = 'signUpForm' as const;