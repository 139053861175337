import { NgModule } from '@angular/core';

// Modules
import { CodeInputModule } from 'angular-code-input';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { FeatureFlagsModule } from '@core/features-flags/feature-flags.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './pipes/pipes.module';
import { PrimeNgModule } from './primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { StaticServeModule } from '@core/static-serve/static-serve.module';

const modules = [
  CodeInputModule,
  ComponentsModule,
  DirectivesModule,
  FeatureFlagsModule,
  FormsModule,
  PipesModule,
  PrimeNgModule,
  ReactiveFormsModule,
  TranslateModule,
  StaticServeModule
]

@NgModule({
  imports: [...modules],
  exports: [...modules],
})
export class SharedModule { }
