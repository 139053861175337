import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { filter } from 'rxjs';
import { GoogleTagEvents } from './constants/gtm.constants';

import { IGoogleTagEvent } from './interfaces/googleTagManager.interfaces';

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManager {

  constructor(private router: Router,private gtmService: GoogleTagManagerService) {}

  pushGTMDataLayerForEveryVisitedPage():void {
    // push GTM data layer for every visited page
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event:NavigationEnd) => {
        const gtmTag:IGoogleTagEvent = {
          event: GoogleTagEvents.PAGE,
          pageName: event.url
        };

        this.gtmService.pushTag(gtmTag);
      })   
  }
}
