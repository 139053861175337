import { ActionReducerMap } from '@ngrx/store';
import * as authenticationReducer from '../../pages/auth/state/authentication.reducer';

import * as parentReducer from '../../pages/parents/state/parent.reducer';

// Global App Reducers
import * as loaderReducer from './loader/loader.reducer';
import * as devicesReducer from './device/device.reducer';
import * as responsiveReducer from './responsive/responsive.reducer';
import * as languageReducer from './language/language.reducer';
import { OnboardingState } from '@pages/parents/modules/onboarding/state/onboarding.state';
import { onboardingReducer } from '@pages/parents/modules/onboarding/state/onboarding.reducer';
import { ReferralState } from '@pages/parents/modules/referrals/state/referrals.state';
import { referralsReducer } from '@pages/parents/modules/referrals/state/referrals.reducer';
import { RoutesState } from '@core/global-state/routes/routes.state';
import { routesReducer } from '@core/global-state/routes/routes.reducer';
import { BankingState } from 'src/app/modules/banking/state/banking.state';
import { bankingReducer } from 'src/app/modules/banking/state/banking.reducer';


export interface GlobalState {
    routes: RoutesState,
    loader: loaderReducer.State
    devices: devicesReducer.State
    responsive: responsiveReducer.ResponsiveState
    language: languageReducer.State
    authentication: authenticationReducer.AuthState
    banking: BankingState,
    parent: parentReducer.ParentState,
    onboarding: OnboardingState,
    referrals: ReferralState
}

export const appReducers: ActionReducerMap<GlobalState> = {
    routes: routesReducer,
    loader: loaderReducer.loaderReducer,
    devices: devicesReducer.devicesReducer,
    responsive: responsiveReducer.responsiveReducer,
    language: languageReducer.languageReducer,
    authentication: authenticationReducer.authenticationReducer,
    banking: bankingReducer,
    parent: parentReducer.parentReducer,
    onboarding:onboardingReducer,
    referrals:referralsReducer
}