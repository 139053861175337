<section class="amount-container">
  <section class="amount-sub-container">
    <section class="amount-input-container" [ngClass]="{ invalid: isInvalid }">
      <p-inputNumber
        (onInput)="onInput($event)"
        [(ngModel)]="amount"
        [min]="0"
        mode="currency"
        currency="USD"
        placeholder="$0.00"
        [locale]="local"
        (onFocus)="moveCursorToStart()"
        #input />

      <section class="icon-edit" *ngIf="showIcon" (click)="onEdit()">
        <img [ngSrc]="'mode.svg' | staticServe" width="24" height="24" />
      </section>
    </section>

    <section class="amount-options-container" *ngIf="options.length > 0">
      <section (click)="selectAmount(amount)" class="item-option-amount" *ngFor="let amount of options">
        <section class="text-container">
          <span class="text">{{ amount | currency : "USD" : "symbol" : "1.0-2" }}</span>
        </section>
      </section>
    </section>
  </section>
</section>
