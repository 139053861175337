<section class="menu-parent-balance-container" *ngIf="parentWallet">
  <mdk-icon-profile [name]="parentWallet.name" />

  <section class="menu-parent-balance">
    <h4 class="name">{{ parentWallet.name | capitalizeFull }}</h4>
    
    <p class="balance">
      ${{ parentWallet.balance | number : "1.2-2" }}
      {{ "MAIN.MENU_ITEMS.BALANCE" | translate }}
    </p>
  </section>

  <i class="pi pi-ellipsis-h icon accountActionIcon" (click)="clickIcon()"></i> 
</section>
