import { Injectable, inject } from '@angular/core';
import * as loaderActions from '../global-state/loader/loader.actions'
import { GlobalState } from '../global-state/app.reducer';
import { Store } from '@ngrx/store';
import { C_WEB_VIEW_MESSAGES } from '@pages/parents/modules/onboarding/components/dumb/onboarding-header/models/constants/onboarding.constants';
import { WebviewManager } from './webview-manager/webview-manager';

declare var ReactNativeWebView;

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

//#region [---- DEPENDENCIES ----]
  private readonly store: Store<GlobalState> = inject(Store)
  private readonly webViewManager: WebviewManager = inject(WebviewManager)
//#endregion


  public show() :void {
     this.webViewManager.isWebViewActive() ? this.showNativeLoader(true) : this.store.dispatch(loaderActions.isLoading())
  }

  public hide() :void {
     this.webViewManager.isWebViewActive() ? this.showNativeLoader(false) : this.store.dispatch(loaderActions.stopLoading())
    
  }

  //#region [---- LOGIC ----]


   private showNativeLoader(show:boolean) :void {
     if (typeof ReactNativeWebView !== "undefined" &&  ReactNativeWebView) {
        ReactNativeWebView.postMessage(JSON.stringify({
          status: show ? C_WEB_VIEW_MESSAGES.SHOW_LOADER_TRUE : C_WEB_VIEW_MESSAGES.SHOW_LOADER_FALSE,
        }));
      }
   }

  //#endregion

}