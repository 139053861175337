import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Observable,
  of,
  tap,
  switchMap,
  finalize,
  catchError,
  mergeMap,
  map,
} from "rxjs";
import { GlobalState } from "@core/global-state/app.reducer";
import { ParentsApi } from "../../parents.api";
import * as parentActions from "../../state/parent.actions";
import { ITeensWallets } from "./models/interfaces/teensAndWallets.interface";
import { IWalletData } from "./components/parent-wallet/interfaces/person-wallet.interfaces";
import { SendMoneyApi } from "../send-money/send-money.api";
import { IUser } from "@pages/auth/interfaces/auth.interfaces";
import { AuthUtils } from "src/app/modules/authentication/authentication.utils";
@Injectable({
  providedIn: "root",
})
// 
export class HomeFunctions {

  //#region [---- [DEPENDENCIES] ----]
  private readonly store: Store<GlobalState> = inject(Store);
  private readonly parentsApi: ParentsApi = inject(ParentsApi);
  private readonly sendMoneyApi: SendMoneyApi = inject(SendMoneyApi);
  private readonly authUtils: AuthUtils = inject(AuthUtils)
  //#endregion

  //#region [---- [METHODS] ----]
  // REFACTOR: We need to move this method to another file
  public getWallets(): Observable<ITeensWallets> {
    return of("").pipe(
      tap(() => {
        this.setStatus(true);
      }),

      switchMap(() =>
        this.parentsApi.getTeens()
      ),
      mergeMap((teens) => {
        return this.parentsApi
          .getWalletsTeens(teens)
          .pipe(map((wallets) => ({ wallets, teens })));
      }),
      finalize(() => {
        this.setStatus(false);
      }),
      catchError((err) => {
        this.setStatus(false);
        return of(err);
      })
    );
  }

  public getParentWallet(user: IUser): Observable<IWalletData> {
    let tokenUserId = null;
    if (user && user.accessToken) {
        const tokenDecoded = this.authUtils.getTokenDecodedUser(user.accessToken);
        tokenUserId = tokenDecoded.user_uuid
    }
    return this.sendMoneyApi.getParentBalance().pipe(
      map((parentBalance) => ({
        user_id: user?.id ?? tokenUserId,
        name: user?.name,
        balance: parentBalance.balance,
        isParent: true,
      })),
      catchError((err) => {
        return of(err);
      })
    );
  }

  private setStatus(isGetting: boolean): void {
    this.store.dispatch(parentActions.setIsGettingWallets({ isGetting }));
  }
  //#endregion
}
