import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { CSpinner } from './models/constants/spinner.constants';

@Component({
  selector: 'mdk-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: [
    './styles/spinner.component.mobile.scss',
    './styles/spinner.component.desktop.scss'
  ],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {

  //#region [---- [INPUTS] ----]
  @Input() interceptor: boolean = false;
  @Input() manual: boolean = false;
  @Input() withoutBackground: boolean = false;
  @Input() inputMessage: boolean = false;
  @Input() isModal: boolean = false;
  //#endregion

  lottieConfig: AnimationOptions = {
    path: CSpinner.PATH,
    autoplay: true,
    loop: true
  };
}
