import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringsTransformer {

  private readonly REGEX_SPACES: RegExp = /\s+/g;
  private readonly REGEX_NO_LETTERS_SPACES: RegExp = /[^A-Za-z\s]/g;
  private readonly REGEX_TWO_MORE_SPACES: RegExp = /\s{2,}/g;

  public capitalize(value:string):string {
    const lowercase = value?.toLocaleLowerCase()
    return  `${lowercase?.charAt(0)?.toUpperCase()}${lowercase?.slice(1)}`;
  }

  public deleteAllSpaces(value:string):string{
    return value?.replace(this.REGEX_SPACES, '')
  }

  public sanitizeNames(value:string):string {
    return value?.replace(this.REGEX_TWO_MORE_SPACES, ' ')?.replace(this.REGEX_NO_LETTERS_SPACES, '');
  }
}
