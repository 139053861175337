<section class="reminder-container" (click)="openPopup()">
    <figure class="icon-container">
        <img class="logo" [ngSrc]="'app_shortcut.webp' | staticServe" width="24" height="24" alt="">
    </figure>
    
    <section class="text-container">
        <span class="title">{{"DOWNLOAD_APP.REMINDER.TITLE" | translate}}</span>
        <span class="description">{{"DOWNLOAD_APP.REMINDER.DESCRIPTION" | translate}}</span>
    </section>
</section>

<mdk-modal
    class="modal-container"
    (hide)="isDisplayingModal = false"
    [display]="isDisplayingModal"
    header="" >

    <mdk-mobile-app-share-qr
      [linkDownloadAPP]="linkApp"
      [imagePath]="imagePath"
      [fileName]="fileName"
      [imageSrcName]="imageSrcName"
      [downloadTrackEvent]="downloadTrackEvent"
      [copyTrackEvent]="copyTrackEvent"
      [projectTag]="projectTag" />
</mdk-modal>