import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { MessageService } from 'primeng/api';
import { ErrorsManager } from './core/errors/errors.manager';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { appInitializerFactory, HttpLoaderFactory } from 'src/app/configs/libraries/ngx-translate/ngx-translate';
import { NgOptimizedImage } from '@angular/common';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { DynamicFontLoaderService } from '@core/services/dynamic-font-loader.service';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { TranslateManager } from '@core/translate/translate.manager';
import { translateManagerFactory } from '@core/translate/translate.manager.factory';
import { MenuParentModule } from './layouts/components/menu-parent/menu-parent.module';
import { BackgroundManager } from '@core/services/background-manager/background.manager.ts.service';
import { firebaseFactory } from '@core/firebase/firebase.factory';
import { FirebaseService } from '@core/firebase/firebase.service';
import { featureFactory } from '@core/features-flags/feature-flags.factory';
import { FeatureFlagsManager } from '@core/features-flags/feature-flags.manager';
import { webViewFactory } from '@core/services/webview-manager/webview-factory';
import { WebviewManager } from '@core/services/webview-manager/webview-manager';
import { Amplify } from 'aws-amplify';
import { awsAmplifyConfiguration as AWSConfig } from './configs/libraries/aws-amplify/aws-exports';
import { CookieService } from 'ngx-cookie-service';
import { WafProtectionService } from '@core/services/waf-protection/waf-protection.service';
import { initializeWaf } from '@core/services/waf-protection/waf-protection.factory';

Amplify.configure(AWSConfig);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    BrowserAnimationsModule,
    SharedModule,
    MenuParentModule,
    GoogleTagManagerModule.forRoot({
      id: environment.GOOGLE.GTM_ID
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    NgOptimizedImage,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
  ],
  providers: [
    Title,
    Meta,
    ErrorsManager,
    MessageService,
    DynamicFontLoaderService,
    BackgroundManager,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService,BackgroundManager],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory:webViewFactory,
      deps: [WebviewManager],
      multi:true
    },
    //#region [SENTRY]
    //Automatically Send Errors with ErrorHandler
    //The Angular SDK exports a function to instantiate an ErrorHandler provider that will automatically send JavaScript errors captured by Angular's error handler.
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, 
    //Register TraceService
    //For performance monitoring, register TraceService as a provider with a Router as its dependency:
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    //Then, either require the TraceService from inside AppModule or use APP_INITIALIZER to force instantiate Tracing.
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory:firebaseFactory,
      deps: [FirebaseService],
      multi:true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: translateManagerFactory,
      deps: [TranslateManager],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory:featureFactory,
      deps: [FeatureFlagsManager],
      multi:true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeWaf,
      deps: [WafProtectionService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi:true
    },
    //#endregion
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
