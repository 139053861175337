import { Injectable, inject } from "@angular/core";
import { IWalletData } from "@pages/parents/pages/home-parent/components/parent-wallet/interfaces/person-wallet.interfaces";
import { SEND_MONEY_EVENTS } from "@pages/parents/pages/send-money/models/constants/send-money.events.constants";
import { ActivatedRoute, Router } from '@angular/router';
import { PROJECT_TAGS } from "src/app/configs/libraries/tracking-manager/constants/projects.constants";
import { TrackingManager } from "src/app/configs/libraries/tracking-manager/tracking-manager";
import { IWalletTeen } from "@pages/parents/interfaces/parent.interfaces";
import { CSharedValues } from "@pages/parents/shared/constants/shared.constants";

@Injectable({
    providedIn: 'root',
})
export class WalletFunctions {

    //#region [---- [DEPENDENCIES] ----]
    private readonly trackingManager: TrackingManager = inject(TrackingManager);
    private readonly route: Router = inject(Router);
    private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    //#endregion

    //#region [---- [PROPERTIES] ----]
    private readonly sessionItemKey: string = CSharedValues.SEND_MONEY_WALLET;
    private readonly sendMoneyPath: string = CSharedValues.SEND_MONEY_PATH;
    //#endregion
    
    //REFACTOR: Use another solution instead of sessionStorage (MDKW-366)
    public setWalletAndGoToSendMoney(walletData: IWalletData, from: string, reload: boolean = false): void {
        this.setWallet(walletData)
        this.trackEvent(from, walletData)
        const currentUrl = this.activatedRoute.snapshot['_routerState']?.url;
        if (!currentUrl.includes(this.sendMoneyPath) && !reload) this.navigateToSendMoney();
        else  window.location.reload();
    }

    public getWallet(): IWalletData | null {
        const walletDataString = sessionStorage.getItem(this.sessionItemKey);
        if (walletDataString && walletDataString !== 'undefined') {
            const walletData: IWalletData = JSON.parse(walletDataString);
            return walletData
        }
        return null;
    }

    public goToSendMoney(from: string): void {
        this.trackEvent(from)
        this.navigateToSendMoney();
    }

    public setWallet(walletData: IWalletData): void {
        sessionStorage.setItem(this.sessionItemKey, JSON.stringify(walletData));
    }

    public setWalletTeen(walletTeen: IWalletTeen): void {
        this.setWallet(
            {
                account_id: walletTeen.account_id,
                balance: walletTeen.balance,
                isParent: false,
                name: walletTeen.name,
                user_id: walletTeen.user_id
            }
        )
    }

    private navigateToSendMoney(): void {
        this.route.navigate([`/parents/send-money`]);
    }

    private trackEvent(from: string, walletData?: IWalletData): void {
        this.trackingManager.trackEvent(
            SEND_MONEY_EVENTS.SELECT_PARENT_START_SEND_MONEY, 
            PROJECT_TAGS.BANKING, 
            {
                from: from, 
                wallet: walletData, 
            }
        )
    }
}