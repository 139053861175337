import { CFeaturesFlagConfigTypes } from "src/app/configs/features-flags/models/constants/features.configs.constants";

export const commonEnvironment = {
    MODAK_URL: 'https://modakmakers.com',

    FRESH_CHAT:{
        HOST: 'https://modak.freshchat.com',
        TOKEN: '6ba152e4-efc5-47a9-9569-24f65705aece'
    },

    GOOGLE:{
        PLACES_KEY: 'AIzaSyCObZ2TX7bsR5Qo2fcIXX19iAza51VlT_s'
    },

    SENTRY: {
        DSN: 'https://5f827a7c932b56d42c5c34641dadb1b9@o4504719307309056.ingest.sentry.io/4505625376063488',
    },

    VGS:{
        organizationId: 'ACci3u9aEmutHr7zpAeVY2Zc',
        version: '2.19.0'
    },
    firebase: {
        userApp:'tech.web+users@modak.live',
        token:'Thz1$xJW5GSKlPQq*N3K7Reh*AtxXLJnS4&f*',
        apiKey: "AIzaSyB72iuKTSQrLY5Zy3qViE6v3XyXYKX-A9E",
        authDomain: "modak-web-features-flags.firebaseapp.com",
        projectId: "modak-web-features-flags",
        storageBucket: "modak-web-features-flags.appspot.com",
        messagingSenderId: "749226775895",
        appId: "1:749226775895:web:6073681d3698d83a431a12"
    },
    FEATURES_FLAG_TYPE_CONFIGURATION: CFeaturesFlagConfigTypes.FILES_CONFIG
}