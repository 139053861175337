import { Injectable, inject } from "@angular/core";
import {
  CCardType,
  CParentTransactionProcessStatus,
  CWalletNames,
} from "./interfaces/parent.constant";
import {
  CTransferStatus,
  ILastTransactionParent,
  IParentGroupTransaction,
  IWalletTeen,
} from "./interfaces/parent.interfaces";
import {
  IAccountTeenResponse,
  IMemberData,
  IMemberFullData,
  IParentTransactionResponse,
} from "./interfaces/parent.responses";
import { DateTime } from "luxon";
import { CFormatDate } from "src/app/shared/utils/dates/date.constants";
import { Store } from "@ngrx/store";
import { GlobalState } from "@core/global-state/app.reducer";
import { selectUser } from "@pages/auth/state/authentication.selectors";
import { Observable, distinct, filter, first, map, tap } from "rxjs";
import { IUser } from "@pages/auth/interfaces/auth.interfaces";
import { TranslateService } from "@ngx-translate/core";
import * as parentActions from "../parents/state/parent.actions";
import { IResponseCreateTeen } from "@pages/parents/pages/add-teen/interfaces/add-teen.api.interfaces";
import { selectTeenWallets, selectTeens } from "@pages/parents/state/parent.selectors";
import { CTransactionReceiverKeyword, CTransactionTranslates } from "./pages/savings/models/constants/saving.contants";
import { CParentLastTransactionIcon } from "./models/constants/parents.transactions.constants";

@Injectable({
  providedIn: "root",
})
export class ParentsFunctions {

  //#region [---- [DEPENDENCIES] ----]
  private store: Store<GlobalState> = inject(Store);
  private translate: TranslateService = inject(TranslateService);
  //#endregion

  //#region [---- [LOGIC] ----]
  public transformMembersData(response: IMemberData[]): IMemberFullData[] {
    const dataTransform = response?.map((item) => {
      return this.transformMemberData(item);
    });
    return dataTransform;
  }

  private transformMemberData(item: IMemberData): IMemberFullData {
    const firstName = this.getFirstName(item.name);
    return {
      uuid: item.uuid,
      email: item.email,
      name: firstName,
      full_name: item.name,
      phone: item.phone
    };
  }

  public transformMemberFullData(item: IMemberFullData): IMemberFullData {
    const firstName = this.getFirstName(item.name);
    item.full_name = item.name;
    item.name = firstName;
    return item;
  }

  public transformWalletTeens(response: IAccountTeenResponse[]): IWalletTeen[] {
    const dataTransform = response?.map((item) => {
      return this.transformWalletTeen(item);
    });
    return dataTransform;
  }

  public transformWalletTeen(item: IAccountTeenResponse): IWalletTeen {
    return {
      user_id: item.user_id,
      name: "",
      full_name: "",
      account_id: item.account_id,
      balance: item.balance,
      hasPhysicalCard: item.cards?.some(
        (card) => card.type === CCardType.PHYSICAL
      ) || false,
      cards: item.cards
    };
  }

  public transformTransaction(
    transaction: IParentTransactionResponse
  ): ILastTransactionParent {
    return {
      id: transaction.id,
      transaction_type: transaction.transaction_type,
      priority_type: transaction.priority_type,
      bank_entity: transaction.bank_entity,
      debit_pull_card_last4: transaction.debit_pull_card_last4,
      sender: transaction.sender,
      toName: this.getReceiver(transaction) || CWalletNames.WALLET,
      transaction_date_formatted: DateTime.fromISO(
        transaction.created_at
      ).toFormat(CFormatDate.MONTH_3_LETTERS_DATE_YEAR_HOUR_MINUTE_12),
      transaction_hour: DateTime.fromISO(transaction.created_at).toFormat(
        CFormatDate.HOUR_MINUTE_12
      ),
      transaction_date: DateTime.fromISO(transaction.transaction_date).toFormat(
        CFormatDate.DATE_HOUR_MINUTE_12
      ),
      transaction_initiated: DateTime.fromISO(transaction.created_at).toFormat(
        CFormatDate.DATE_HOUR_MINUTE_12
      ),
      created_at: transaction.created_at,
      amount: transaction.amount,
      fee: transaction.fee,
      status: this.getTransferStatus(transaction.process_status),
      icon: this.getIcon(transaction)
    };
  }

  private getReceiver(transaction: IParentTransactionResponse): string {
    if (this.isSavingGoalTransaction(transaction)) {
      return this.translate.instant(CTransactionTranslates.SAVING_GOAL_NAME_TRANSACTION, { goalName: transaction.description })
    }

    return transaction.receiver
  }

  private getIcon(transaction: IParentTransactionResponse): string {
    return this.isSavingGoalTransaction(transaction) ? CParentLastTransactionIcon.SHOPPING_BAG : CParentLastTransactionIcon.NONE
  }

  private isSavingGoalTransaction(transaction: IParentTransactionResponse): boolean {
    return transaction.receiver?.toLowerCase()?.includes(CTransactionReceiverKeyword.SAVING)
  }

  public getTransferStatus(
    status: (typeof CParentTransactionProcessStatus)[keyof typeof CParentTransactionProcessStatus]
  ): (typeof CTransferStatus)[keyof typeof CTransferStatus] {
    if (status === CParentTransactionProcessStatus.CANCELED)
      return CTransferStatus.CANCELED;

    if (status === CParentTransactionProcessStatus.COMPLETED)
      return CTransferStatus.COMPLETE;

    return CTransferStatus.PROCESSING;
  }

  public agroupTransactionByDate(
    transactions: IParentTransactionResponse[]
  ): IParentGroupTransaction[] {
    const data = transactions.reduce((accumulator, current) => {
      const currentDay = DateTime.fromISO(current.transaction_date).toFormat(
        CFormatDate.MONTH_DATE_YEAR
      );

      const existsDay = accumulator.findIndex((transactionsAgroup) => {
        const dayTransaction = DateTime.fromISO(
          transactionsAgroup[0].transaction_date
        ).toFormat(CFormatDate.MONTH_DATE_YEAR);
        return dayTransaction === currentDay;
      });

      existsDay >= 0
        ? (accumulator[existsDay] = [...accumulator[existsDay], current])
        : (accumulator = [...accumulator, [current]]);
      return accumulator;
    }, []);

    return this.transformTransactions(data as [IParentTransactionResponse[]]);
  }

  private transformTitleDate(date: string): string {
    return DateTime.fromISO(date).toFormat(
      CFormatDate.MONTH_3_LETTERS_DATE_YEAR
    );
  }

  private transformTransactions(
    data: [IParentTransactionResponse[]]
  ): IParentGroupTransaction[] {
    const dataTransform = data.map((agroupTransaction) => {
      const transactions = agroupTransaction.map((transaction) => {
        return this.transformTransaction(transaction);
      });

      const titleDate = this.transformTitleDate(
        agroupTransaction[0].transaction_date
      );

      const groupTransactions: IParentGroupTransaction = {
        transactions,
        titleDate,
      };

      return groupTransactions;
    });
    return dataTransform;
  }

  public getFirstName(fullName: string): string {
    let name = fullName?.split(" ")?.[0] || "";
    name = name.length > 2
        ? name.charAt(0).toUpperCase() + name.substring(1).toLowerCase()
        : name;
    return name;
  }
  
  public getLastName(fullName: string): string {
    const parts = fullName?.split(" ");
    let lastName = parts?.[parts.length - 1] || "";
        lastName = lastName.length > 2
        ? lastName.charAt(0).toUpperCase() + lastName.substring(1).toLowerCase()
        : lastName;

    return lastName;
  }
  public getUserData(): Observable<IUser> {
    return this.store
      .select(selectUser)
      .pipe(first(), filter((userLogged) => userLogged?.hasOwnProperty("name")));
  }

  public getTeens(): Observable<IMemberFullData[]> {
    return this.store.select(selectTeens).pipe(
      first(),
      map((teens) => teens)
    )
  }

  public getTeen(teenId: string): Observable<IMemberFullData | null> {
    return this.getTeens().pipe(
      map(teens => {
        if (!teens) return null
        const teen = teens.find(teen => teen.uuid === teenId)
        return teen ? teen : null
      })
    )
  }

  public getFirstTeen(): Observable<IMemberFullData> {
    return this.getTeens().pipe(
      first(),
      map(teens => teens[0])
    )
  }

  public getTeenWallets(): Observable<IWalletTeen[]> {
    return this.store.select(selectTeenWallets).pipe(
      first(),
      map((wallets) => wallets)
    )
  }

  public getTeenWallet(teenId:string): Observable<IWalletTeen> {
    return this.store.select(selectTeenWallets).pipe(
      first(),
      filter(wallets=>wallets.length>0),
      map((wallets) => wallets.filter(wallet=>wallet?.user_id === teenId)[0])
    )
  }

  public addTeen(teen: IResponseCreateTeen): void {
    const newTeen: IMemberFullData = {
      uuid: teen.uuid,
      full_name: teen.name,
      email: teen.email,
      phone: teen.phone,
      name: this.getFirstName(teen.name)
    }
    this.getTeens().pipe(
      distinct(teens => teens.map(t => t.uuid)),
      map(teens => {
        const isTeenAlreadyAdded = teens.find(t => t.uuid === newTeen.uuid);
        if (!isTeenAlreadyAdded) {
          return [...teens, newTeen];
        }
        return [...teens];
      }),
      tap(teens => this.store.dispatch(parentActions.setTeens({ teens })))
    ).subscribe();
  }
  
  //#endregion
}
