import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalState } from '@core/global-state/app.reducer';
import { Store } from '@ngrx/store';
import { SizesDevice } from '../../models/constants/global.constants';
import { ResponsiveState } from '@core/global-state/responsive/responsive.reducer';

@Component({
  selector: 'mdk-back-button-banner',
  templateUrl: './back-button-banner.component.html',
  styleUrls: [
    './styles/back-button-banner.component.mobile.scss',
    './styles/back-button-banner.component.desktop.scss'
  ]
})
export class BackButtonBannerComponent implements OnInit, OnDestroy {

  // Size Detection (Responsive)
  isMobile: boolean = false
  responsiveSubscription: Subscription;

  @Input() showBackButton: boolean = true
  @Input() title?: string;
  @Input() subtitle: string;
  @Input() showInfoButton: boolean = false
  @Output() clickBack: EventEmitter<void> = new EventEmitter();
  @Output() clickInfo: EventEmitter<void> = new EventEmitter();

  constructor(private store: Store<GlobalState>){}

  ngOnInit(): void {
    this.checkResponsive()
  }

  ngOnDestroy(): void {
    if(this.responsiveSubscription) this.responsiveSubscription.unsubscribe()
  }

  private checkResponsive(): void {
    this.responsiveSubscription = this.store.select((state: GlobalState) => state.responsive)
      .subscribe((responsiveState: ResponsiveState) => {
        this.isMobile = responsiveState?.sizeDevice === SizesDevice.MOBILE;
    });
  }

  public goToBack(): void {
    this.clickBack.emit()
  }

  public onClickInfo(): void {
    this.clickInfo.emit()
  }
}
