import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CHANNEL_NAMES } from 'src/app/configs/libraries/fresh-chat/fresh-chat.constants';
import { FreshChatFunctions } from 'src/app/configs/libraries/fresh-chat/fresh-chat.functions';
import { GlobalState } from '@core/global-state/app.reducer';
import { SizesDevice } from '../../models/constants/global.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingManager } from 'src/app/configs/libraries/tracking-manager/tracking-manager';
import { SEND_MONEY_EVENTS } from '@pages/parents/pages/send-money/models/constants/send-money.events.constants';
import { PROJECT_TAGS } from 'src/app/configs/libraries/tracking-manager/constants/projects.constants';

@Component({
  selector: 'mdk-user-title-parent-portal',
  templateUrl: './user-title-parent-portal.component.html',
  styleUrls: ['./user-title-parent-portal.component.scss']
})
export class UserTitleParentPortalComponent implements OnInit, OnDestroy {

  //#region [---- DEPENDENCIES ----]

  private readonly freshChatFunctions: FreshChatFunctions = inject(FreshChatFunctions)
  private readonly store: Store<GlobalState> = inject(Store<GlobalState>)
  private readonly route: Router = inject(Router)
  private readonly activateRoute: ActivatedRoute = inject(ActivatedRoute)
  private readonly trackingManager: TrackingManager = inject(TrackingManager)

  //#endregion

  @Input() userName: string;
  @Input() sayHello: boolean;
  @Input() seeTeenPersonalDetails: boolean = false;

  // Size Detection (Responsive)
  isMobile: boolean = false
  responsiveSubscription: Subscription;

  ngOnInit(): void {
    this.checkResponsive();
  }

  ngOnDestroy(): void {
    if(this.responsiveSubscription) this.responsiveSubscription.unsubscribe();
  }

  goToHelpCenter(): void {
    this.showErrorScreen()
  }

  goToTeenPersonalDetails(): void {
    const teenId = this.activateRoute.snapshot.paramMap.get("teenId");
    this.trackingManager.trackEvent(SEND_MONEY_EVENTS.SELECT_TEEN_DASHBOARD_ACCOUNT_DETAILS,PROJECT_TAGS.BANKING,{teen:{user_id:teenId}})
    this.route.navigate([`parents/teen-dashboard/${teenId}/personal-details`])
  }

  private showErrorScreen(message: string = ""): void {
    this.freshChatFunctions.reportCustomerSupport(CHANNEL_NAMES.HELP_CENTER, message)
  }

  private checkResponsive(): void {
    this.responsiveSubscription = this.store
      .select("responsive")
      .subscribe(
        (state) =>
          (this.isMobile =
            state?.sizeDevice === SizesDevice.MOBILE ? true : false)
      );
  }
  
}
