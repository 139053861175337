import { createAction,props } from '@ngrx/store';
import { IMobucksState } from 'src/app/modules/banking/models/interfaces/state.interface';


 const setMobucksAccount = createAction('[Finance] setMobucksAccount',props<{mobucks:IMobucksState}>());
 const unSetMobucksAccount = createAction('[Finance] unSetMobucksAccount')
 const setMobucksBalance = createAction('[Finance] setMobucksBalance',props<{balance:number}>());
 const setCardThemes = createAction('[Banking] setCardThemes',props<{themes:string[]}>());

const bankingActions = {
    setMobucksAccount,
    unSetMobucksAccount,
    setMobucksBalance,
    setCardThemes
}

export default bankingActions

