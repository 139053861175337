import { Injectable, inject } from "@angular/core";
import { TRACK_DATA_CONSTANTS } from "./constants/tracking.constants";
import {  IUserTrack } from "./interfaces/tracking-manager.interfaces";
import { IMoEngageUserEvent, IUserEventDataDefinition, IUserEventSchema } from "./interfaces/user.event.data.interfaces";
import { WebviewManager } from "@core/services/webview-manager/webview-manager";

@Injectable({
    providedIn: 'root'
  })
  export class TrackingUtils {
  //#region [---- DEPENDENCIES ----]
  private readonly webViewManager : WebviewManager = inject(WebviewManager)
  //#endregion
  
    public transformDeviceInfoToDeviceSchema(browser:string){
        return {
            Device_info:{
                source: this.webViewManager.isWebViewActive() ? TRACK_DATA_CONSTANTS.MOBILE : TRACK_DATA_CONSTANTS.SOURCE,
                browser,
            }
        }
    }

    public transformUserTrackToUserUserSchema(userTrack: IUserTrack): IUserEventSchema {
        return {
            user: {
                id: userTrack?.uuid ?? '',
                email: userTrack?.email ?? TRACK_DATA_CONSTANTS.DEFAULT_EMAIL,
                phone: userTrack?.phone,
                role: userTrack?.type
            }
        }
    }

    public transformUserToUserForMoEngageUser(user:IUserEventDataDefinition):IMoEngageUserEvent{
        return {
            user_uuid: user?.id ?? '',
            user_type: user?.role ?? '',
            user_phone: user?.phone ?? '',
            user_email: user?.email ?? ''
        }
    }
  }