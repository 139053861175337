export const CBankingErrorCodes = {
    BANKING_LINK_BANK_ACCOUNT_MISMATCH: "BankingPlaidDetailsMismatch",
    BANKING_INSUFFICIENT_FUNDS: 'BankingInsufficientFunds',
    BANKING_DEBIT_PULL_LIMIT_ERROR: 'BankingThrottling',
    BANKING_DAILY_RECEIVE_LIMIT: 'BankingDailyReceiveLimit',
    BANKING_USER_ERROR: 'UserError',
    BANKING_DB_ERROR: 'BankingDbError',
    BANKING_NETWORK_FAILURE: 'BankingNetworkFailure',
    BANKING_INSTITUTION_NOT_RESPONSE: 'BankingInstitutionNotResponse',
    BANKING_PROVIDER_ERROR: 'ProviderError',
    //KYC
    BANKING_BANK_DECLINED_KYC: 'BankingBankDeclinedInKyc',
    BANKING_KYC_DECLINED: 'BankingKYCDeclined',
    //REQUEST_MONEY
    BANKING_REQUEST_MONEY_LINK_NOT_FOUND: 'BankingGetRequestMoneyLinkNotFound',
    BANKING_ITEM_NOT_FOUND: 'BankingItemNotFound',
    BANKING_PARTNER_ERROR: 'Partner'
} as const;