import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {
  
  private maxLength: number = 36;
  transform(value: string, maxLength?: number): string {
    return value.length <= this.maxLength ? value : `${value.substr(0, maxLength ? maxLength : this.maxLength)}...`
  }

}
