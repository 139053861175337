<!-- Transactions -->
<section class="transactions-container">
    <div *ngIf="!teenData; else showTeenTransactions" class="header-container">
        <p class="label-text">
        {{ "PARENT_PORTAL.TRANSACTIONS.LATEST" | translate }}
        </p>
        <button (click)="viewParentTransactions()" class="button-element">
        {{ "PARENT_PORTAL.TRANSACTIONS.VIEW_ALL" | translate }}
        </button>
    </div>

    <ng-template #showTeenTransactions>
        <div class="header-container">
            <p class="label-text">
            {{teenData.name}}{{ "PARENT_PORTAL.TRANSACTIONS.TEEN_LATEST" | translate }}
            </p>
            <button (click)="viewTeenTransactions()" class="button-element">
            {{ "PARENT_PORTAL.TRANSACTIONS.VIEW_ALL" | translate }}
            </button>
        </div>
    </ng-template>
    
    <section  class="transactions-items-container" *ngIf="lastTransactions && lastTransactions.length; else noTransactions" >
        <div *ngIf="!teenData; else showTeenLastTransactions">
            <mdk-parent-transaction-item  (click)="viewDetails(transaction)" *ngFor="let transaction of lastTransactions" [transaction]="transaction"></mdk-parent-transaction-item>
        </div>
        <ng-template #showTeenLastTransactions>
            <mdk-teen-transaction-item
            (click)="viewDetails(transaction)"
            *ngFor="let transaction of lastTransactions"
            [transaction]="transaction"
            >
            </mdk-teen-transaction-item>
        </ng-template>
        
    </section>

    <ng-template #noTransactions>
        <ng-container>
            <div class="no-data-container">
            <figure class="image-container">
                <img
                class="image-element"
                [ngSrc]="'last-transaction-none.svg' | staticServe"
                width="50" height="50"
                alt=""
                />
            </figure>
            <p class="data-text">
                {{
                "PARENT_PORTAL.TRANSACTIONS.NO_TRANSACTIONS_DESCRIPTIONS"
                    | translate
                }}
            </p>
            </div>
            <button *ngIf="false" class="button-container">
            {{ "PARENT_PORTAL.HOME.SEND_BUTTON" | translate }}
            </button>
        </ng-container>
    </ng-template>
</section>