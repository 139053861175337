
<section 
    *ngIf="showModal"
    (click)="checkCloseModal($event)"  
    id="modalBackground"
    class="modal-container">
    <section class="card-container">
        <div class="icon-close-container">
            <i class="pi pi-times icon checkIcon" (click)="modalEmit(false)"></i>
        </div>
        <div class="icon-check-container">
            <i class="pi pi-info-circle icon checkIcon"></i>
        </div>
        <h4 class="title">{{title || 'ADMIN.USERS.MODAL.TITLE' | translate}}</h4>

        <div class="message-container" *ngIf="message">
            <p class="message">{{message}}</p>
        </div>


        <div class="buttons-container">
            <button (click)="modalEmit(true)" type="button" class="btn-modak button yes">
                <span class="text">{{acceptText || 'ADMIN.USERS.MODAL.BUTTON_YES' | translate}}</span>
                <div class="btn-overlay-default"></div>
            </button>
            <button 
                (click)="modalEmit(false)"
                type="button" 
                class="btn-modak button btn-green-secondary">
                <span class="text">{{cancelText || 'ADMIN.USERS.MODAL.BUTTON_NO' | translate}}</span>
                <div class="btn-overlay-default"></div>
            </button>
        </div>
    </section>
</section>