import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, catchError, map, tap, throwError } from "rxjs";
import { IAccountBalance } from "./models/interfaces/teen.interface";
import { ISendTransaction, ISendTransactionResponse } from "./models/interfaces/transfer.interface";
import { ILastTransactionParent } from "../../interfaces/parent.interfaces";
import { SendMoneyFunctions } from "./send-money.functions";
import { CTypeContacts, typeContactsLinked } from "./models/constants/linked.constants";
import { LogsManager } from "@core/logs/logs.manager";
import { CSendMoneyErrorTitles } from "@pages/parents/pages/send-money/models/constants/send-money-errors.constants";
import { TrackingManager } from "src/app/configs/libraries/tracking-manager/tracking-manager";
import { PROJECT_TAGS } from "src/app/configs/libraries/tracking-manager/constants/projects.constants";
import { SEND_MONEY_EVENTS } from "./models/constants/send-money.events.constants";

@Injectable({
  providedIn: "root",
})
export class SendMoneyApi {
  //#region [---- [DEPENDENCIES] ----]
  private readonly http: HttpClient = inject(HttpClient);
  private readonly sendMoneyFunctions: SendMoneyFunctions = inject(SendMoneyFunctions);
  private readonly logsManager: LogsManager = inject(LogsManager);
  private readonly trackingManager: TrackingManager = inject(TrackingManager);
  //#endregion

  //#region [---- [PROPERTIES] ----]
  private BANKING_DOMAIN_URL: string = environment.APIS.BANKING_DOMAIN_URL;
  //#endregion

  //#region [---- [METHODS] ----]
  public getParentBalance(): Observable<IAccountBalance> {
    const url = `${this.BANKING_DOMAIN_URL}/account/v1/balance`;
    return this.http.get<IAccountBalance>(url).pipe(
      catchError((error: HttpErrorResponse) => {
        this.logsManager.error({
          title: CSendMoneyErrorTitles.PARENT_BALANCE,
          error
        })
        return throwError(() => error);
      })
    );
  }

  public sendTransaction(newTransaction: ISendTransaction): Observable<ILastTransactionParent> {
    return this.http.post<ISendTransactionResponse>(
      this.buildFundUrl(newTransaction?.senderAccount?.typeContactLinked),
      this.sendMoneyFunctions.transformNewTransaction(newTransaction))
    .pipe(
      map((response) => {
        return this.sendMoneyFunctions.transformSendTransactionResponse(
          response,
          newTransaction
        );
      }),
      tap((result) => {
        this.trackingManager.trackEventV2(
          SEND_MONEY_EVENTS.API_FUNDING_SUCCESS,
          {
            project_tag: PROJECT_TAGS.BANKING,
            event: {
              action: SEND_MONEY_EVENTS.API_FUNDING_SUCCESS.toLowerCase()
            },
            Banking: {
              transaction: {
                amount: result?.amount.toString(),
                fee: result.fee && result.fee.toString(),
                id: result?.id,
                method: result?.transaction_type,
              }
            }
          }
        );
      }),
      catchError((error: HttpErrorResponse) => {
        this.logsManager.error({
          title: CSendMoneyErrorTitles.PARENT_FUNDING,
          error
        });
        this.trackingManager.trackEventV2(
          SEND_MONEY_EVENTS.API_FUNDING_ERROR,
          {
            project_tag: PROJECT_TAGS.BANKING,
            event: {
              action: SEND_MONEY_EVENTS.API_FUNDING_ERROR.toLowerCase()
            }
          }
        );
        return throwError(() => error);
      }
    ));
  }

  private buildFundUrl(type: typeContactsLinked): string {
    const url = `${this.BANKING_DOMAIN_URL}/account/v1/fund`;
    switch (type) {
      case CTypeContacts.ACCOUNT_BANK:
        return `${url}/ach`;
      case CTypeContacts.DEBIT_CARD:
        return `${url}/debit`;
      case CTypeContacts.INTRABANK:
        return `${url}/intrabank`;
      default:
        return url;
    }
  }
  //#endregion
}
