<section [ngClass]="{'sticky-button-container': mobileStickyStyle}">
  <button
    [id]="uniqueId"
    type="button"
    (click)="onClick()"
    (touchstart)="onTouchStart()"
    (touchmove)="onTouchMove()"
    (touchend)="onTouchEnd()"
    class="button"
    [disabled]="disabled || buttonTimerOnClickDisabled"
    [ngStyle]="{ padding: customPadding, width: customWidth }"
    [ngClass]="[type, autoWidth ? 'auto-width' : '', removePadding ? 'remove-padding' : '', withBorder ? 'border-container' : '']">

    <label *ngIf="textPosition !== textButtonRight" class="button-text">
      {{ buttonText }}
    </label>

    <i *ngIf="buttonIcon" [class]="buttonIcon"></i>

    <img
      *ngIf="buttonSrc"
      [ngSrc]="buttonSrc | staticServe"
      width="18"
      height="18"
    />

    <label *ngIf="textPosition === textButtonRight" class="button-text">
      {{ buttonText }}
    </label>

    <div class="btn-overlay" *ngIf="overlay"></div>
  </button>
</section>
