import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  CTransformTypeMdkInput,
  CTypesMdkInput,
  INPUT_MASK_REGEX,
} from "@shared/components/dumb/input/constants/input-modak.constants";
import {
  onChangeAndTouchFn,
  TransformTypeMdkInput,
  TypeMdkInput,
} from "@shared/components/dumb/input/interfaces/input-modak.types";
import { StringsTransformer } from "@shared/utils/strings.transformer";
import { debounceTime, Subject, Subscription } from "rxjs";
import { NgxMaskService } from "ngx-mask";

@Component({
  selector: "mdk-input",
  templateUrl: "./input.component.html",
  styleUrls: [
    "./styles/input.component.mobile.scss",
    "./styles/input.component.desktop.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputModakComponent),
      multi: true,
    },
    NgxMaskService,
  ],
})
export class InputModakComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  //#region [---- DEPENDENCIES ----]
  private stringsTransformer: StringsTransformer = inject(StringsTransformer);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  //#endregion

  //#region [---- INPUTS & OUTPUTS ----]
  @Input() type: TypeMdkInput = CTypesMdkInput.TEXT;
  @Input() label: string;
  @Input() helperText: string;
  @Input() labelError: string;
  @Input() labelSuccess: string;
  @Input() isInvalid: boolean;
  @Input() acceptOnlyLetters: boolean;
  @Input() acceptOnlyNumbers: boolean;
  @Input() capitalizeFirstLetters: boolean;
  @Input() disabled: boolean;
  @Input() debounceDelay: number = 300;
  @Input() transformType: TransformTypeMdkInput = CTransformTypeMdkInput.NONE;
  @Input() maxCharacterAllowed: number;
  @Input() showCharacterAllowed: boolean = true;
  @Input() maskToApply: string = "";
  @Input() placeholder: string = "";
  @Input() pattern: string = "";
  @Output() blurEvent: EventEmitter<void> = new EventEmitter<void>();

  //BUTTON
  @Input() buttonCustomWidth: string;
  @Input() buttonText: string;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
  //#endregion

  //#region [---- PROPERTIES ----]
  public uniqueId: string;

  private debouncer: Subject<string> = new Subject<string>();
  private debouncerSubscription: Subscription;

  public inputValue: string = "";
  public isDisabled: boolean;

  private _onChangeFn: onChangeAndTouchFn = (_value) => {};
  private _onTouchFn: onChangeAndTouchFn = (_value) => {};
  //#endregion

  //#region [---- LIFE CYCLES ----]

  constructor() {
    this.uniqueId = `QA_INPUT_COMPONENT`;
  }
  ngOnInit(): void {
    this.initDebouncer();
  }

  ngOnDestroy(): void {
    this.debouncerSubscription.unsubscribe();
  }
  //#endregion

  //#region [---- LOGIC ----]
  private initDebouncer(): void {
    this.debouncerSubscription = this.debouncer
      .pipe(debounceTime(this.debounceDelay))
      .subscribe((inputValue) => {
        this._onChangeFn(inputValue);
      });
  }
  //#endregion

  //#region [---- CVA METHODS ----]
  writeValue(value: string): void {
    if (typeof value === "string") this.inputValue = value;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: onChangeAndTouchFn): void {
    this._onChangeFn = fn;
  }

  registerOnTouched(fn: onChangeAndTouchFn): void {
    this._onTouchFn = fn;
  }

  setDisabledState(state: boolean): void {
    this.isDisabled = state;
    this.cdr.detectChanges();
  }
  //#endregion

  //#region [---- EVENTS ----]
  public onInput(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target instanceof HTMLInputElement) {
      this.validateMaxCharactersAllowed(target);
      const inputValueWithoutSpaces = this.deletingSpaces(target.value);
      const sanitizeInputValue = this.sanitizeNames(inputValueWithoutSpaces);
      const capitalizedValue = this.capitalizeNames(sanitizeInputValue);
      const transformedValue = this.transformValue(capitalizedValue);
      this.inputValue = transformedValue;
      this.debouncer.next(transformedValue);
    }
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (this.type === CTypesMdkInput.EMAIL && event.key === " ") {
      event.preventDefault();
    }
  }

  public onBlur() {
    this._onChangeFn(this.inputValue);
    this._onTouchFn(this.inputValue);
    this.blurEvent.emit();
  }

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
  //#endregion

  //#region [---- TRANSFORMS ----]
  private validateMaxCharactersAllowed(target: HTMLInputElement) {
    if (this.maxCharacterAllowed && target.value && target.value.length > this.maxCharacterAllowed) {
      target.value = target.value.slice(0, this.maxCharacterAllowed);
    }
  }
  private deletingSpaces(value: string): string {
    return this.type === CTypesMdkInput.EMAIL
      ? this.stringsTransformer.deleteAllSpaces(value)
      : value.trim();
  }

  private sanitizeNames(value: string): string {
    return this.acceptOnlyLetters
      ? this.stringsTransformer.sanitizeNames(value)
      : value;
  }

  private cleanMask(value: string): string {
    const maskChars = this.maskToApply.replace(INPUT_MASK_REGEX, "");
    for (const char of maskChars) {
      value = value.replace(char, "");
    }
    return value;
  }

  private transformValue(value: string): string {
    const valueUnmasked = this.maskToApply ? this.cleanMask(value) : value;
    switch (this.transformType) {
      case CTransformTypeMdkInput.LOWERCASE:
        return valueUnmasked.toLowerCase();
      case CTransformTypeMdkInput.UPPERCASE:
        return valueUnmasked.toUpperCase();
      default:
        return valueUnmasked;
    }
  }

  private capitalizeNames(value: string) {
    if (!this.capitalizeFirstLetters) return value;

    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  //#endregion
}
