import { inject, Injectable } from '@angular/core';
import { CLangsAllowed } from '@core/translate/models/constants/langs.constants';
import { TranslateUtils } from '@core/translate/translate.utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateManager {

  //#region [---- DEPENDENCIES ----]

  private translate: TranslateService = inject(TranslateService);
  private translateUtils: TranslateUtils = inject(TranslateUtils);

  //#endregion

  //#region [---- PROPERTIES ----]

  currentTranslations: { [lang: string]: object } = {};

  //#endregion

  //#region [---- LOGIC ----]

  public init():void{
    this.checkTransactionForLang()
  }

  private checkTransactionForLang():void{
    Object.values(CLangsAllowed).forEach(lang => {
      this.saveCurrentTranslations(lang);
    });
  }

  private saveCurrentTranslations(lang: string): void {
    this.translate.getTranslation(lang).subscribe(existingTranslations => {
      this.currentTranslations[lang] = existingTranslations;
    });
  }

  public addTranslations(allTranslations: Object): void {
    const formattedTranslations = this.translateUtils.formatForTranslate(allTranslations)

    for (const lang of Object.keys(formattedTranslations)) {

      const currentLangTranslations = this.getCurrentTranslations(lang);

      const mainKey = Object.keys(formattedTranslations[lang])[0];

      if (!(mainKey in currentLangTranslations)) {
        const mergedTranslations = { ...currentLangTranslations, ...formattedTranslations[lang] };
        this.currentTranslations[lang] = mergedTranslations
        this.translate.setTranslation(lang, mergedTranslations);
      }
    }
  }

  private getCurrentTranslations(lang: string): object {
    return this.currentTranslations[lang] || {};
  }


  //#endregion

}
