import { Component, Input } from '@angular/core';

@Component({
  selector: 'mdk-transaction-detail-property',
  templateUrl: './transaction-detail-property.component.html',
  styleUrls: ['./transaction-detail-property.component.scss'],
  standalone: true
})
export class TransactionDetailPropertyComponent {

  @Input() label: string
  @Input() data: string
}
