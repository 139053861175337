import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ITokensUser } from '@pages/auth/interfaces/auth.interfaces';
import { AuthState } from '@pages/auth/state/authentication.reducer';
import { LOCAL_STORAGE_REDUCERS } from 'src/app/configs/libraries/ngrx-redux/ngrx';

export const selectAuthState = createFeatureSelector<AuthState>(LOCAL_STORAGE_REDUCERS.AUTH);
export const selectUser = createSelector(selectAuthState,(state: AuthState) => state?.user);
export const selectUserId = createSelector(selectAuthState,(state: AuthState) => state?.user?.id);
export const selectRole = createSelector(selectAuthState,(state: AuthState) => state?.user?.role);
export const selectToken = createSelector(selectAuthState,(state: AuthState) => state?.user?.accessToken);
export const selectRefreshToken = createSelector(selectAuthState,(state: AuthState) => state?.user?.refreshToken);
export const selectEmail = createSelector(selectAuthState,(state: AuthState) => state?.user?.email);
export const selectAllTokens = createSelector(selectAuthState,(state: AuthState) => {
    const tokens:ITokensUser = {
        accessToken : state?.user?.accessToken || '',
        refreshToken : state?.user?.refreshToken || ''
    }
    return tokens
});
export const selectMetadataParent = createSelector(selectAuthState,(state: AuthState) => state?.user?.metadata);
