import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { CConfettiAnimation } from './models/constants/confetti-animation.constants';

@Component({
  selector: 'mdk-confetti-animation',
  templateUrl: './confetti-animation.component.html',
  styleUrls: ['./styles/confetti-animation.component.mobile.scss']
})
export class ConfettiAnimationComponent {
  //#region [---- [PROPERTIES] ----]
  public lottieConfig: AnimationOptions = {
    path: CConfettiAnimation,
    autoplay: true,
    loop: false
  };
  //#endregion
}
