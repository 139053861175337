import { CBankingErrorCodes } from "@core/errors/models/constants/codes/banking.codes";
import { CErrorContext } from "@core/errors/models/constants/context.constants";
import { CErrorSendMoneyContext } from "@core/errors/models/constants/contexts/send-money.context";

export const CBankingErrorMessages = {
    [CBankingErrorCodes.BANKING_LINK_BANK_ACCOUNT_MISMATCH]: {
        [CErrorSendMoneyContext.LINK_BANK_ACCOUNT]: "We've noticed that the information provided for your Modak account (name, phone, email, or address) doesn't match with your Plaid account. Please double-check and ensure the details are accurate to proceed. If you need any assistance, feel free to reach out to our support team. Thank you!"
    },
    [CBankingErrorCodes.BANKING_INSUFFICIENT_FUNDS]: {
        [CErrorSendMoneyContext.FUND]: "No worries, it happens to the best of us! It appears there are insufficient funds in your account to complete this transaction. Please review your balance or consider an alternative payment method. If you have any questions or need further assistance, feel free to get in touch with our support team. We're here to help you find a solution!"
    },
    [CBankingErrorCodes.BANKING_DEBIT_PULL_LIMIT_ERROR]: {
        [CErrorSendMoneyContext.FUND]: 'We apologize, we have an unprecedented volume of debit funding. Please try using Link Bank Account or Direct Transfer, as a thank you we will give you $10 when you complete first funding'
    },
    [CBankingErrorCodes.BANKING_DAILY_RECEIVE_LIMIT]: {
        [CErrorSendMoneyContext.FUND]: 'You have reached the daily limit to receive money in your account with debit pull. Please try again tomorrow or using Link Bank Account or Direct Transfer'
    },
    [CBankingErrorCodes.BANKING_USER_ERROR]: {
        [CErrorSendMoneyContext.FUND]: "Something went wrong. Please review the data entered and try again or use another method for funding. If you're still having trouble, don't hesitate to reach out to our support team for assistance. We're here to help!"
    },
    [CBankingErrorCodes.BANKING_DB_ERROR]: {
        [CErrorSendMoneyContext.FUND]: "We apologize for the inconvenience, but it seems we're experiencing a technical issue with our banking partner's system. Please try your again a bit later. If the problem persists, don't hesitate to reach out to our support team for further assistance. Thank you for your patience!"
    },
    [CBankingErrorCodes.BANKING_NETWORK_FAILURE]: {
        [CErrorSendMoneyContext.FUND]: "We're sorry for the inconvenience, but it appears there's a network issue with our banking partner at the moment. This might be causing a delay in processing your request. Please give it a little time and try again later. If the problem persists, feel free to contact our support team for further assistance. We appreciate your patience and understanding!"
    },
    [CBankingErrorCodes.BANKING_INSTITUTION_NOT_RESPONSE]: {
        [CErrorSendMoneyContext.FUND]: "We're sorry, but at the moment, your financial institution is unable to respond to requests. Please try your again a bit later. We understand this may be inconvenient and appreciate your patience!"
    },
    [CBankingErrorCodes.BANKING_PROVIDER_ERROR]: {
        [CErrorSendMoneyContext.FUND]: "We're sorry, but it seems that an unexpected error has occurred with our provider. Please try your again a bit later. We appreciate your understanding and patience."
    },
    [CBankingErrorCodes.BANKING_PARTNER_ERROR]: {
        [CErrorSendMoneyContext.FUND]: "Oops! It seems like there's a hiccup with Plaid right now. 🛠️ Don't worry, we're on it! Please try again in a moment, or reach out to our support team for assistance. Thanks for your patience! 🙏"
    },
    //KYC
    [CBankingErrorCodes.BANKING_BANK_DECLINED_KYC]: {
        [CErrorContext.KYC]: "Information mismatch. We will contact you via email"
    },
    [CBankingErrorCodes.BANKING_KYC_DECLINED]: {
        [CErrorContext.KYC]: "Information mismatch. We will contact you via email"
    },
} as const;