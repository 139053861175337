import { Component, Input } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { PipesModule } from "@shared/pipes/pipes.module";
import { IMAGE_FOLDERS } from "@shared/pipes/constants/static-serve.constants";
import { ITimeLineWelcome } from "@pages/parents/pages/onboarding/pages/welcome/components/dumb/timeline-item/models/interfaces/timeline-item.interfaces";
import { StaticServeModule } from "@core/static-serve/static-serve.module";
import { SharedModule } from "@shared/shared.module";

@Component({
  selector: "mdk-timeline-item",
  templateUrl: "./timeline-item.component.html",
  styleUrls: ["./styles/timeline-item.component.mobile.scss", "./styles/timeline-item.component.desktop.scss"],
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, PipesModule, StaticServeModule, SharedModule]
})
export class TimelineItemComponent {
  //#region [---- PROPERTIES ----]
  public isDisplayingModal: boolean = false;
  public folderPath: string = IMAGE_FOLDERS.ICONS;
  //#endregion

  //#region [---- [INPUTS] ----]
  @Input() item: ITimeLineWelcome;
  //#endregion
}
