import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, inject, ElementRef } from '@angular/core';
import { Observable, Subscription, filter, switchMap} from 'rxjs';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../core/global-state/app.reducer';
import { IMenuItem } from './models/interfaces/menu.interfaces';
import { SizesDevice } from '../../../shared/models/constants/global.constants';
import { AuthFunctions } from '../../../pages/auth/auth.functions';
import { MenuService } from './menu.service';
import { TrackingManager } from 'src/app/configs/libraries/tracking-manager/tracking-manager';
import { AUTH_EVENTS } from '@pages/auth/auth-parent/models/constants/auth-parent.events.constants';
import { PROJECT_TAGS } from 'src/app/configs/libraries/tracking-manager/constants/projects.constants';
import { selectToken, selectUser } from '@pages/auth/state/authentication.selectors';
import { HomeFunctions } from '@pages/parents/pages/home-parent/home-parent.functions';
import { IUser } from '@pages/auth/interfaces/auth.interfaces';
import { IWalletData } from '@pages/parents/pages/home-parent/components/parent-wallet/interfaces/person-wallet.interfaces';
import { MenuItem } from 'primeng/api';
import { WalletFunctions } from '@pages/parents/shared/functions/wallet.functions';
import { CFeatures } from '@core/features-flags/models/constants/features-flags.constants';

@Component({
  selector: 'mdk-menu-parent',
  templateUrl: './menu-parent.component.html',
  styleUrls: [
    './styles/menu-parent.component.mobile.scss',
    './styles/menu-parent.component.desktop.scss'
  ]
})
export class MenuParentComponent implements OnInit, OnDestroy {
  //#region [---- INPUT OUTPUTS ----]
  @Input() showMenu:boolean = false
  @Output() closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion

  //#region [---- [PROPERTIES] ----]
  // Links
  public items: IMenuItem[] = [];
  public links$: Observable<IMenuItem[]>;
  public parentWallet: IWalletData;
  public showPanelMenu: boolean = false;
  public showAccountMenu: boolean = false;
  private readonly allSubscription: Subscription = new Subscription();

  // Size Detection (Responsive)
  public isMobile: boolean = false

  public referralsFeatureFlag: string = CFeatures.REFERRALS;
  
  //#endregion

  //#region [---- [DEPENDENCIES] ----]
  private readonly store: Store<GlobalState> = inject(Store<GlobalState>);
  private readonly authFunctions: AuthFunctions = inject(AuthFunctions);
  private readonly menuService: MenuService = inject(MenuService);
  private readonly trackingManager: TrackingManager = inject(TrackingManager);
  private readonly homeFunctions: HomeFunctions = inject(HomeFunctions);
  private readonly walletFunctions: WalletFunctions = inject(WalletFunctions);
  private elementRef: ElementRef = inject(ElementRef);
  //#endregion

  itemsMenu: MenuItem[] | undefined;

  //#region [---- LIFE CYCLES ----]
  ngOnInit(): void {
    this.getLinksMenu();
    this.checkResponsive();
    this.getParentData();
  }

  ngOnDestroy(): void {
    this.allSubscription?.unsubscribe()
  }
  //#endregion

  //#region [---- LOGIC ----]
  getLinksMenu(): void {
    this.links$ = this.menuService.getLinks()
  }

  checkResponsive(): void {
    this.allSubscription.add(this.store.select((state: GlobalState) => state.responsive)
    .subscribe(state => {
      const sizeDeviceEvent = state?.sizeDevice
      if(sizeDeviceEvent === SizesDevice.MOBILE){
        this.isMobile = true
        this.showPanelMenu = false
        this.showAccountMenu = false;
      }

      if(sizeDeviceEvent === SizesDevice.DESKTOP){
        this.isMobile = false;
        this.showPanelMenu = true;
        this.showAccountMenu = false;
      }
    }));
  }

  logout():void {
    this.trackingManager.trackEventV2(AUTH_EVENTS.SELECT_PARENT_LOGOUT_SUCCESS, {
      project_tag: PROJECT_TAGS.ACCOUNT_ACCESS
    })
    this.menuService.removeLinks()
    this.authFunctions.logout(false,true)
  }

  private getParentData(): void {
    this.allSubscription.add(this.store.select(selectUser).subscribe({
      next: (user) => this.getParentWallet(user),
    }));
  }

  private getParentWallet(user: IUser): void {
    this.store.select(selectToken).pipe(
      filter(token  => Boolean(token)),
      switchMap(() => this.homeFunctions.getParentWallet(user))
    )
    .subscribe({
      next: (wallet) => {
        this.parentWallet = { ...wallet };
      },
    });
  }

  public addFunds(): void {
    this.walletFunctions.setWalletAndGoToSendMoney(this.parentWallet, 'parent-wallet');
  }

  handleContainerClick(event) {
    const target = event.target as HTMLElement;
    
    const isAccountIcon = target.classList.contains('accountActionIcon');
    if(this.showAccountMenu && !isAccountIcon) {
      const menuAccountContainer = this.elementRef.nativeElement.querySelector('.menu-account-container');
      this.showAccountMenu = menuAccountContainer.contains(event.target);
      return; 
    }

    const isIcon = target.classList.contains('actionIcon');
    if(this.showPanelMenu && !isIcon) {
      const menuContainer = this.elementRef.nativeElement.querySelector('.menu-container');
      this.showPanelMenu = menuContainer.contains(event.target);
    }
  }
  //#endregion
}
