<ng-container *ngIf="cardTheme">
    <section class="card-theme-container">
        <section class="card-theme-title-container" *ngIf="title">
            <h4 class="card-theme-title">{{ title }}</h4>
        </section>

        <section class="card-theme-image-container">
            <section class="card-theme-image">
                <img  [src]="'MoCardFront-'+cardTheme+'.svg' | staticServe" width="240" height="151" alt="Mo Card" >
                <label *ngIf="cardName" class="card-name"> {{ cardName }} </label>
            </section>
            <section class="card-theme-action-container">
                <div (click)="changedTheme()" class="card-theme-action-title">{{'COMPONENTS.CHANGE_MO_CARD_THEME.CHANGE_CARD_THEME' | translate}}</div>
            </section>
        </section>
    </section>
</ng-container>