<section class="feature-card-container" (click)="onCardClicked()">
  <section class="feature-card-heading">
    <div class="feature-card-title" *ngIf="title">{{ title }}</div>
    <i class="pi pi-chevron-right"></i>
  </section>
  <ng-container *ngIf="!summaryTitle && !summaryDescription; else showSummary">
    <picture class="feature-card-image-without-summary">
      <img class="image-element" [ngSrc]="image  | staticServe" width="85" height="104" />
    </picture>
  </ng-container>
  <ng-template #showSummary>
    <picture class="feature-card-image-with-summary">
      <img class="image-element" [ngSrc]="image  | staticServe" width="38" height="47" />
    </picture>
    <section class="summary-container">
      <label class="summary-title" *ngIf="summaryTitle">{{summaryTitle}}</label>
      <label class="summary-description" *ngIf="summaryDescription">{{summaryDescription}}</label>
    </section>
  </ng-template>
</section>
