import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'mdk-amount',
  templateUrl: './amount.component.html',
  styleUrls: [
    './styles/amount.component.mobile.scss', 
  ]
})
export class AmountComponent {
  @ViewChild("input") elm;
  
  //#region [---- [INPUTS] ----]
  @Input() public options:number[] = []
  @Input() public amount:number = 0;
  @Input() public showIcon: boolean = false;
  @Input() public local:string = 'en-US'
  @Input() public isInvalid: boolean = false;
  //#endregion
  
  //#region [---- [OUTPUTS] ----]
  @Output() public readonly amountEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() public readonly selectAmountTabEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion

  //#region [---- [LOGIC] ----]
  public onInput(event: any): void {
    this.amountEmitter.emit(event.value);
    this.selectAmountTabEmitter.emit(false);
  }
 
  public selectAmount(amount: number): void {
    this.amount = amount;
    this.amountEmitter.emit(amount);
    this.selectAmountTabEmitter.emit(true);
  }

  public onEdit(): void {
    const inputElement = this.getElement();
    inputElement.focus();
  }

  public moveCursorToStart(): void {
    const inputElement = this.getElement()
    const value = inputElement?.value
    const indexOfDecimal = value?.indexOf('.')
    inputElement.setSelectionRange(indexOfDecimal, indexOfDecimal);
  }

  private getElement(): any {
    return this.elm.input.nativeElement;
  }
  
  //#endregion
}
