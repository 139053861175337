export const C_PUBLIC_ROUTES_ALLOWED = {
    BANKING: [
        "/payment-link",
        "/parents/banking/funds/payment-link",
        "/payment-link-outcome",
        "/parents/banking/funds/payment-link-outcome",
    ],
    USERS: [],
} as const;

