<ng-container *ngIf="teen$ | async as teen">
  <section class="text-container mobile-app-share-container" *ngIf="!isOnboarding; else onboardingText">
    <section class="content-container">
      <section class="title-container">
        <label class="title">{{'DOWNLOAD_APP.TITLE' | translate: {name: teen.name} }}</label>
        <label class="subtitle">{{'DOWNLOAD_APP.SUBTITLE' | translate: {name: teen.name} }}</label>
      </section>
      <section class="copy-container" [ngClass]="isOnboarding ? '' : 'border-copy-container'">
        <span class="link">{{ linkDownloadAPP }}</span>
        <mdk-btn
          [buttonText]="'DOWNLOAD_APP.BUTTON_COPY' | translate"
          (buttonClick)="copy()" />
      </section>
      <section class="qr-container">
        <label class="description">{{'DOWNLOAD_APP.SCAN_QR' | translate: {name: teen.name} }}</label>
        <figure class="image-container">
          <img
            [ngSrc]="imageSrcName | staticServe"
            width="134"
            height="134"
            alt="download_app_qr" />
        </figure>
      </section>
      <mdk-btn
        *ngIf="textButton"
        class="button"
        [type]="secondaryButton"
        [buttonIcon]="'pi pi-arrow-right'"
        [buttonText]="textButton"
        [textPosition]="textButtonPosition"
        (buttonClick)="onClickButton()" />
    </section>
  </section>

  <ng-template #onboardingText>
    <ng-container class="container">
      <section *ngIf="showTitle" class="onboarding-text-container">
        <h3 class="title"> 
          <span class="span-text">
            {{ "PARENT_PORTAL.ONBOARDING.TEEN_ACCOUNT_CREATED.CONGRATULATIONS" | translate:{name:teen.name} }}
          </span>
        </h3>
      </section>
      
      <mdk-timeline [items]="timeLineItems"></mdk-timeline>
    </ng-container>
  </ng-template>

</ng-container>  
