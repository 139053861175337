import { Component, inject } from '@angular/core';
import { REMINDER_DOWNLOAD_APP_EVENTS } from '@shared/components/reminder-download-app/constants/reminder-download-app.events.constants';
import { PROJECT_TAGS } from 'src/app/configs/libraries/tracking-manager/constants/projects.constants';
import { TrackingManager } from 'src/app/configs/libraries/tracking-manager/tracking-manager';
import { NgOptimizedImage } from '@angular/common';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { BtnModakComponent } from '@shared/components/dumb/button/button.component';
import { CopyButtonComponent } from '@shared/components/copy-button/copy-button.component';
import { MobileAppShareQrComponent } from '../smart/mobile-app-share-qr/mobile-app-share-qr.component';
import { StaticServeModule } from '@core/static-serve/static-serve.module';
import { CReminderDownloadAPP } from './constants/reminder-download-app.constants';
import { ProjectTag } from 'src/app/configs/libraries/tracking-manager/interfaces/tracking-manager.interfaces';
@Component({
  selector: 'mdk-reminder-download-app',
  templateUrl: './reminder-download-app.component.html',
  styleUrls: ['./styles/reminder-download-app.component.mobile.scss'],
  standalone: true,
  imports: [
    NgOptimizedImage,
    ModalComponent,
    TranslateModule,
    BtnModakComponent,
    CopyButtonComponent,
    MobileAppShareQrComponent,
    StaticServeModule
  ]
})
export class ReminderDownloadAppComponent {
  
  //#region [---- [DEPENDENCIES] ----]
  private readonly trackingManager:TrackingManager = inject(TrackingManager);
  //#endregion

  //#region [---- [PROPERTIES] ----]
  public isDisplayingModal: boolean = false;
  public readonly linkApp: string = CReminderDownloadAPP.LINK_QR_MOBILE_APP;
  public readonly imagePath: string = CReminderDownloadAPP.PATH_QR_IMAGE;
  public readonly fileName: string = CReminderDownloadAPP.FILE_NAME;
  public readonly imageSrcName: string = CReminderDownloadAPP.SRC_IMAGE;
  public readonly downloadTrackEvent: string = REMINDER_DOWNLOAD_APP_EVENTS.SELECT_REMINDER_DOWNLOAD_APP_QR;
  public readonly copyTrackEvent: string = REMINDER_DOWNLOAD_APP_EVENTS.SELECT_REMINDER_DOWNLOAD_APP_COPY_LINK;
  public readonly projectTag: ProjectTag = PROJECT_TAGS.BANKING;
  //#endregion

  //#region [---- [LOGIC] ----]
  public openPopup(): void {
    this.isDisplayingModal = true;
    this.trackingManager.trackEventV2(REMINDER_DOWNLOAD_APP_EVENTS.SELECT_REMINDER_DOWNLOAD_APP_OPEN_POPUP, {
      project_tag: this.projectTag
    });
  }
  //#endregion
}
