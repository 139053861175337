import { ChangeDetectionStrategy } from '@angular/core';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'mdk-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class InfoComponent {

  @Input() title:string;
  @Input() messages:string[];
  @Input() fullWidth:boolean;
  @Input() iconClass:string = "pi-info-circle";
  @Input() colorPastelClass:string = "pastelD";
  @Input() colorClass:string = "accentD"
  constructor() { }


}
