// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { ResourcesConfig } from 'aws-amplify'
import { environment } from '../../../../environments/environment'

export const awsAmplifyConfiguration: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolId: environment.Cognito.userPoolId,
            userPoolClientId: environment.Cognito.userPoolClientId,
        }
    }
}