export const CAwsAmplifyAuthChallenges = {
    CONFIRM_SIGN_IN_WITH_SMS_CODE: 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
    CONFIRM_SIGN_UP: 'CONFIRM_SIGN_UP',
    COMPLETE_AUTO_SIGN_IN: 'COMPLETE_AUTO_SIGN_IN',
    CONFIRM_RESET_PASSWORD_WITH_CODE: "CONFIRM_RESET_PASSWORD_WITH_CODE",
    DONE: 'DONE',
} as const;

export const AuthErrorNames = {
    NotAuthorizedException: 'NotAuthorizedException',
    CodeMismatchException: 'CodeMismatchException',
    UserNotConfirmedException: 'UserNotConfirmedException',
    LimitExceededException: 'LimitExceededException',
    ExpiredCodeException: 'ExpiredCodeException',
    UserAlreadyAuthenticatedException: 'UserAlreadyAuthenticatedException',
    UserLambdaValidationException: 'UserLambdaValidationException',
    //User needs to sign in first before confirmation
    UserSignInException: 'SignInException',
    //User try to sign up with an existing phone or email
    AliasExistsException: 'AliasExistsException',
    InvalidPasswordException: 'InvalidPasswordException',
    PasswordHistoryPolicyViolationException: 'PasswordHistoryPolicyViolationException',
    TooManyFailedAttemptsException: 'TooManyFailedAttemptsException',
    TooManyRequestsException: 'TooManyRequestsException',
    UserNotFoundException: 'UserNotFoundException',


} as const

export const AuthTypesFlow = {
    USER_SRP_AUTH: 'USER_SRP_AUTH',
    USER_PASSWORD_AUTH: 'USER_PASSWORD_AUTH',
}  as const

export const MFAPreference = {
    PREFERRED: 'PREFERRED',
    ENABLED: 'ENABLED',
} as const

export const CAwsAmplifyErrorTitles = {
   BASE: `AWS Amplify Service`,
   HANDLE_SIGN_IN: 'Pre Sign In',
   HANDLE_SIGN_IN_CONFIRM: 'Confirm Sign In',
   HANDLE_SIGN_UP: 'Pre Sign Up',
   HANDLE_SIGN_UP_CONFIRM: 'Confirm Sign Up',
   AUTO_SIGN_IN: 'Auto Sign In',
   HANDLE_RESEND_CODE_SIGN_UP_CONFIRM: 'Resend Code Confirm Sign Up',
   HANDLE_CHANGE_PASSWORD: 'Change Password',
   HANDLE_FORGOT_PASSWORD: 'Forgot Password',
   HANDLE_CONFIRM_FORGOT_PASSWORD: 'Confirm Forgot Password',
   UPDATE_MFA: 'Update MFA',
   HANDLE_SIGN_OUT: 'Sign Out',
   GET_CURRENT_SESSION: 'Fetch Auth Session',
   GET_CURRENT_USER: 'Get Current User'
} as const;