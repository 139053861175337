import { Injectable } from '@angular/core';
import { ITranslateFormat } from '@core/translate/models/interfaces/translate.manager.interfaces';

@Injectable({
  providedIn: 'root'
})
export class TranslateUtils {


  public formatForTranslate(allTranslations:Object):ITranslateFormat{
    const translations = Object.keys(allTranslations).reduce((acc, key) => {
      const lang = key.slice(-2).toLowerCase();

      acc[lang] = allTranslations[key];

      return acc;
    }, {});

    return translations
  }
}
