import { Directive, OnDestroy, AfterViewInit, ChangeDetectorRef, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { FreshChatFunctions } from './fresh-chat.functions';
import { LogsManager } from '@core/logs/logs.manager';
import { FRESH_CHAT_DIRECTIVE_TITLE_ERROR } from 'src/app/configs/libraries/fresh-chat/fresh-chat.constants';

@Directive({
    selector: '[mdkFreshChat]'
})
export class FreshChatDirective implements OnDestroy,AfterViewInit {

    private readonly allSubscriptions: Subscription = new Subscription();

    private readonly chatService: FreshChatFunctions = inject(FreshChatFunctions)
    private readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef)
    private readonly logsManager: LogsManager = inject(LogsManager);

    ngAfterViewInit(): void {
        const chatSubscription = this.chatService.listen().subscribe({
            error: (error => {
                this.logsManager.error({
                    title: FRESH_CHAT_DIRECTIVE_TITLE_ERROR,
                    error: error
                })
            })
        });
        this.allSubscriptions.add(chatSubscription);
        this.cdr.detectChanges()
    }

    ngOnDestroy(): void {
        this.allSubscriptions.unsubscribe();
    }
}