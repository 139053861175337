import { inject, Pipe, PipeTransform } from '@angular/core';
import { CStaticResourceType } from '@core/static-serve/models/constants/static-serve.constants';
import { StaticFilesManager } from '@core/static-serve/static-serve.manager';


@Pipe({
  name: 'staticServe',
})
export class StaticServePipe implements PipeTransform {

  private readonly staticFilesManager:StaticFilesManager = inject(StaticFilesManager)

  transform(value: string,folder?:string): string {
    const staticServeUrl = this.staticFilesManager.getUrlStaticServer(CStaticResourceType.IMAGES)
    return folder ? `${staticServeUrl}/${folder}/${value}` : `${staticServeUrl}/${value}`
  }

}
