<p-dialog
    [styleClass]="styleClass"
    [appendTo]="'body'" 
    [header]="header !== 'false' ? header : ''" 
    [(visible)]="display"
    [blockScroll]="true" 
    [draggable]="false" 
    [modal]="true" 
    (onHide)="onHideModal()" 
    [closable]="closable"
    [dismissableMask]="closeWhenClickOutside"
>
    <ng-content></ng-content>
    <ng-template pTemplate="footer">
        <ng-content select="[controls]"></ng-content>
    </ng-template>
</p-dialog>