export const PROJECT_TAGS = {
    ACCOUNT_ACCESS: 'account_access',
    BANKING: 'banking',
    CHORES: 'chores',
    ONBOARDING: "onboarding",
    PASSWORD: 'password',
    CHECKOUT: 'checkout',
    APP_ISSUES: 'app_issues',
    REFERRALS: 'referrals',
    PAYMENT_LINK: 'payment_link',
    SAVINGS: 'savings',
} as const;