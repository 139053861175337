import { Injectable, inject } from "@angular/core";
import { DateTime } from "luxon";
import { CFormatDate } from "src/app/shared/utils/dates/date.constants";
import { CParentTransactionType } from "../../interfaces/parent.constant";
import { ILastTransactionParent } from "../../interfaces/parent.interfaces";
import { ParentsFunctions } from "../../parents.functions";
import {
  ISendTransaction,
  ISendTransactionRequest,
  ISendTransactionResponse,
} from "./models/interfaces/transfer.interface";
import { CTypeContacts } from "./models/constants/linked.constants";

@Injectable({
  providedIn: "root",
})
export class SendMoneyFunctions {
  //#region [---- [DEPENDENCIES] ----]
  private parentsFunctions: ParentsFunctions = inject(ParentsFunctions);
  //#endregion

  //#region [---- [METHODS] ----]
  transformSendTransactionResponse(item: ISendTransactionResponse, newTransaction: ISendTransaction): ILastTransactionParent {
    return {
      amount: newTransaction.amount,
      id: item.id,
      bank_entity: newTransaction.senderAccount.name,
      debit_pull_card_last4: newTransaction.senderAccount.name,
      sender: newTransaction.senderAccount.name,
      toName: newTransaction.walletData.name,
      status: this.parentsFunctions.getTransferStatus(item.status),
      created_at: item.created_at,
      transaction_date: DateTime.fromISO(item.created_at).toFormat(
        CFormatDate.DATE_HOUR_MINUTE_12
      ),
      transaction_initiated: DateTime.fromISO(item.created_at).toFormat(
        CFormatDate.DATE_HOUR_MINUTE_12
      ),
      priority_type: newTransaction.transferOption,
      fee: newTransaction.fee,
      transaction_type: CParentTransactionType.DEBIT,
    };
  }

  transformNewTransaction(newTransfer: ISendTransaction): ISendTransactionRequest {
    return {
      source_contact_id: newTransfer.senderAccount?.typeContactLinked !== CTypeContacts.INTRABANK ? newTransfer.senderAccount.id : undefined,
      amount: newTransfer.amount,
      description: newTransfer.description,
      priority_type: newTransfer.transferOption,
      receiver_user_id: !newTransfer.walletData.isParent ? newTransfer.walletData.user_id : undefined
    }
  }
  //#endregion
}
