import { Directive, ElementRef, EventEmitter, HostListener, Output, inject } from "@angular/core";

@Directive({
    selector: '[mdkIntoViewport]',
})
export class ViewportDirective {
    private isTestDivScrolledIntoView: boolean = false;
    private readonly el: ElementRef = inject(ElementRef)

    @Output() intoViewport: EventEmitter<boolean> = new EventEmitter<boolean>(false)

    @HostListener('window:scroll', ['$event'])
    isScrolledIntoView() {
        const el = this.el.nativeElement as HTMLElement
        const rect = el?.getBoundingClientRect();
        const topShown = rect.top >= 0;
        const bottomShown = rect.bottom <= window.innerHeight;
        const isTestDivScrolledIntoView = topShown && bottomShown;
        if (this.isTestDivScrolledIntoView === isTestDivScrolledIntoView) {
            return
        }

        this.isTestDivScrolledIntoView = isTestDivScrolledIntoView
        this.intoViewport.emit(this.isTestDivScrolledIntoView)
    }
}