
// try to keep the alphabetic order
export const CFeatures = {
  AVS_CHECK: 'avs_check',
  BANK_ACCOUNT: 'bank_account',
  CARDS: 'cards',
  CHECKOUT: 'checkout',
  CHECKOUT_CARDS: 'checkout_cards',
  CHECKOUT_FRAUD: 'checkout_fraud',
  CHORES: 'chores',
  DIRECT_TRANSFER: 'direct_transfer',
  FUNDING_CAMPAIGN: 'funding_campaign',
  ONBOARDING: 'onboarding',
  PARENT_BALANCE: 'parent_balance',
  PARENT_SETTINGS: 'parent_settings',
  PASSWORD: 'password',
  PAYMENT_LINK: 'payment_link',
  REFERRALS: 'referrals',
  REQUEST_CARD: 'request_card',
  SAVINGS:'savings',
  SEND_MONEY: 'send_money',
  SIGN_UP: 'sign_up',
  SUPPORT_CHAT: 'support_chat',
  VGS_DEBIT_PULL: 'vgs',
  WAF_PROTECTION: 'waf_protection',
  NEW_AUTHENTICATION:'new_authentication', // delete this when it's on prod
  AUTHENTICATION_V3: 'auth_v3',
} as const;

export type CFeaturesType = typeof CFeatures
export type CFeaturesKeyType = typeof CFeatures[keyof typeof CFeatures]
