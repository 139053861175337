import { CBACKGROUND_COLORS, CBACKGROUND_PROPERTIES } from './models/constants/background.constants';
import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';

@Injectable()
export class BackgroundManager {
//#region [---- [DEPENDENCIES] ----]
  private readonly rendererFactory = inject(RendererFactory2)
//#endregion

//#region [---- [PROPERTIES] ----]
  private readonly renderer: Renderer2;
//#endregion

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.setBackground()
  }

//#region [---- [LOGIC] ----]
  public setBackground(color: string = CBACKGROUND_COLORS.DEFAULT): void {
    this.renderer.setStyle(document.body, CBACKGROUND_PROPERTIES.BACKGROUND_COLOR, color);
  }
  //#endregion
}
