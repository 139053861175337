import { Pipe, PipeTransform } from '@angular/core';
import { IListColor } from '@shared/styles/storybook/list-colors/interfaces/list-colors.interfaces';

@Pipe({
  name: 'searchColor'
})
export class SearchColorPipe implements PipeTransform {

  transform(listColors: IListColor[], keySearch: string): IListColor[] {
    if (!keySearch) return listColors;

    keySearch = keySearch.toUpperCase();

    return listColors.filter(color =>
      color.name.toUpperCase().includes(keySearch) ||
      color.value.toUpperCase().includes(keySearch)
    );
  }

}
