import { CommonModule, NgOptimizedImage } from "@angular/common";
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SecurityContext,
  inject,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { SharedModule } from "@shared/shared.module";
import { TrackingManager } from "src/app/configs/libraries/tracking-manager/tracking-manager";
import { saveAs } from "file-saver";
import { TranslateService } from "@ngx-translate/core";
import { CTypesButtonOption } from "@shared/components/dumb/button/constants/btn-modak.constants";
import { ProjectTag } from "src/app/configs/libraries/tracking-manager/interfaces/tracking-manager.interfaces";
import { Observable, map, of, tap } from "rxjs";
import { GlobalState } from "@core/global-state/app.reducer";
import { Store } from "@ngrx/store";
import { selectTeens } from "@pages/parents/state/parent.selectors";
import { ITimeLineWelcome } from "@pages/parents/pages/onboarding/pages/welcome/components/dumb/timeline-item/models/interfaces/timeline-item.interfaces";
import { TimelineComponent } from "@pages/parents/pages/onboarding/pages/welcome/components/dumb/timeline/timeline.component";
import { IMemberFullData } from "@pages/parents/interfaces/parent.responses";
import { IconPath } from "./constants/icon-path.constants";
import { CStaticResourceType } from "@core/static-serve/models/constants/static-serve.constants";
import { StaticFilesManager } from "@core/static-serve/static-serve.manager";
import { BtnModakComponent } from "@shared/components/dumb/button/button.component";
import { ClipboardService } from "ngx-clipboard";
import { MessageService } from "primeng/api";
import { TOAST_SEVERITY_TYPES } from "@shared/primeng/constants/toast.constants";
import { IWalletData } from "@pages/parents/pages/home-parent/components/parent-wallet/interfaces/person-wallet.interfaces";
import { CSharedValues } from "@pages/parents/shared/constants/shared.constants";

@Component({
  selector: "mdk-mobile-app-share-qr",
  templateUrl: "./mobile-app-share-qr.component.html",
  styleUrls: [
    "./styles/mobile-app-share-qr.component.mobile.scss",
    "./styles/mobile-app-share-qr.component.desktop.scss",
  ],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgOptimizedImage,
    TimelineComponent,
    BtnModakComponent,
  ],
})
export class MobileAppShareQrComponent implements OnInit, AfterContentInit {
  //#region [---- [DEPENDENCIES] ----]
  private readonly trackingManager: TrackingManager = inject(TrackingManager);
  private readonly sanitizer: DomSanitizer = inject(DomSanitizer);
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly store: Store<GlobalState> = inject(Store<GlobalState>);
  private readonly staticFilesManager: StaticFilesManager =
    inject(StaticFilesManager);
  private readonly clipboardService: ClipboardService =
    inject(ClipboardService);
  private readonly messageService: MessageService = inject(MessageService);
  //#endregion

  //#region [---- [PROPERTIES] ----]
  private imageSrc: SafeResourceUrl;
  public benefitsList: string[];
  public readonly secondaryButton: string = CTypesButtonOption.SECONDARY;
  public teen$: Observable<IMemberFullData>;
  public timeLineItems: ITimeLineWelcome[];
  //#endregion

  //#region [---- [INPUT] ----]
  @Input() showTitle: boolean = true;
  @Input() linkDownloadAPP: string;
  @Input() imagePath: string;
  @Input() fileName: string;
  @Input() imageSrcName: string;
  @Input() downloadTrackEvent: string;
  @Input() copyTrackEvent: string;
  @Input() projectTag: ProjectTag;
  @Input() isOnboarding: boolean = false;
  @Input() textButton: string;
  @Input() iconButton: string;
  @Input() textButtonPosition: string;
  @Output() clickButton: EventEmitter<void> = new EventEmitter<void>();
  //#endregion

  //#region [---- [HOOKS] ----]
  ngOnInit(): void {
    this.getTeenData();
    this.loadImageSrc();
  }

  ngAfterContentInit(): void {
    this.benefitsList = [
      this.translate.instant("DOWNLOAD_APP.BENEFITS.BENEFIT_1"),
      this.translate.instant("DOWNLOAD_APP.BENEFITS.BENEFIT_2"),
      this.translate.instant("DOWNLOAD_APP.BENEFITS.BENEFIT_3"),
      this.translate.instant("DOWNLOAD_APP.BENEFITS.BENEFIT_4"),
    ];
  }
  //#endregion

  //#region [---- [LOGIC] ----]
  public downloadQR(): void {
    const url = this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.imageSrc
    );
    const filename = this.fileName;
    saveAs(url, filename);

    this.trackingManager.trackEventV2(this.downloadTrackEvent, {
      project_tag: this.projectTag
    });
  }

  public copy(): void {
    this.clipboardService.copyFromContent(this.linkDownloadAPP);
    this.messageService.add({
      severity: TOAST_SEVERITY_TYPES.SUCCESS,
      summary: `${this.translate.instant("DOWNLOAD_APP.COPIED")}`,
      life: 3000,
    });
    this.trackingManager.trackEventV2(this.copyTrackEvent, {
      project_tag: this.projectTag
    });
  }

  public onClickButton(): void {
    this.clickButton.emit();
  }

  private getTeenData(): void {
    const sendMoneyValue = sessionStorage.getItem(CSharedValues.SEND_MONEY_WALLET);
    if (sendMoneyValue && sendMoneyValue !== 'undefined') {
      const walletData: IWalletData = JSON.parse(sendMoneyValue);
      const teen: IMemberFullData = {
        name: walletData.name,
        full_name: "",
        uuid: "",
        email: "",
        phone: "",
      }
      this.teen$ = of(teen);
      this.loadTimeLineItems(teen)
      return;
    }

    this.teen$ = this.store.select(selectTeens).pipe(
      map((teens) => {
        if (teens && teens.length) return teens[0];
        return {
          name: this.translate.instant("SHARED.YOUR_TEEN"),
          phone: "",
        };
      }),
      tap((teen: IMemberFullData) => this.loadTimeLineItems(teen))
    );
  }

  private loadImageSrc(): void {
    const path = this.imagePath;
    const imagePathQr = `${this.staticFilesManager.getUrlStaticServer(
      CStaticResourceType.IMAGES
    )}/${path}`;
    this.imageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(imagePathQr);
  }

  private loadTimeLineItems(teen: IMemberFullData): void {
    this.timeLineItems = [
      {
        iconPath: IconPath.ACCOUNT_BALANCE_WALLET,
        title: this.translate.instant(
          "PARENT_PORTAL.ONBOARDING.TEEN_ACCOUNT_CREATED.TIMELINE_ITEMS.ITEM_1.TITLE",
          { name: teen.name }
        ),
        shareLink: {
          url: this.linkDownloadAPP,
          description: this.translate.instant(
            "PARENT_PORTAL.ONBOARDING.TEEN_ACCOUNT_CREATED.INVITE_QR",
            { name: teen.name }
          ),
        },
      },
      {
        iconPath: IconPath.ADD_CARD,
        title: this.translate.instant(
          "PARENT_PORTAL.ONBOARDING.TEEN_ACCOUNT_CREATED.TIMELINE_ITEMS.ITEM_2.TITLE",
          { name: teen.name }
        ),
        description: this.translate.instant(
          "PARENT_PORTAL.ONBOARDING.TEEN_ACCOUNT_CREATED.TIMELINE_ITEMS.ITEM_2.DESCRIPTION",
          { name: teen.name, phone: teen.phone }
        ),
      },
      {
        iconPath: IconPath.AGREED,
        title: `${this.translate.instant(
          "PARENT_PORTAL.ONBOARDING.TEEN_ACCOUNT_CREATED.TIMELINE_ITEMS.ITEM_END.TITLE_PART_1"
        )}`,
        description: this.translate.instant(
          "PARENT_PORTAL.ONBOARDING.TEEN_ACCOUNT_CREATED.TIMELINE_ITEMS.ITEM_END.DESCRIPTION",
          { name: teen.name }
        ),
        endsTime: true,
      },
    ];
  }
  //#endregion
}
