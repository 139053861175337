import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { StaticServeModule } from '@core/static-serve/static-serve.module';
import { SECOND } from '@shared/models/constants/time.constant';

@Component({
  selector: 'mdk-tooltip',
  standalone: true,
  imports: [CommonModule,NgOptimizedImage,StaticServeModule],
  templateUrl: './tooltip.component.html',
  styleUrls: ['./styles/tooltip.component.mobile.scss','./styles/tooltip.component.desktop.scss']
})
export class TooltipComponent implements OnInit {

  @Input() active:boolean = false
  @Input() autoClosing:boolean = true
  @Input() description:string;
  @Input() timeForAutoClosing:number = 5

  ngOnInit(): void {
    if(this.autoClosing){
      setTimeout(() => {
        this.active = false
      }, SECOND * this.timeForAutoClosing);
    }
  }

  close():void{
    this.active = false
  }

}
