import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IWalletData } from "@pages/parents/pages/home-parent/components/parent-wallet/interfaces/person-wallet.interfaces";
import { SharedModule } from "@shared/shared.module";

@Component({
  selector: "mdk-menu-parent-balance",
  templateUrl: "./menu-parent-balance.component.html",
  styleUrls: ["./styles/menu-parent-balance.component.mobile.scss"],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class MenuParentBalanceComponent {

  //#region [---- [INPUTS] ----]
  @Input() parentWallet: IWalletData;
  @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();
  //#endregion

  //#region [---- [LOGIC] ----]
  public clickIcon(): void {
    this.clickEvent.emit();
  }
  //#endregion
}
