export const CTypesMdkInput = {
    TEXT: "text",
    EMAIL: "email",
    NUMBER: "number"
} as const

export const CTransformTypeMdkInput = {
    NONE: 'none',
    LOWERCASE: 'lowercase',
    UPPERCASE: 'uppercase',
} as const

export const INPUT_MASK_REGEX: RegExp = /\(|\)|\d/g;
