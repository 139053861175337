import { Pipe, PipeTransform } from '@angular/core';
import { IMenuItem } from './models/interfaces/menu.interfaces';

@Pipe({
  name: 'menuAlphabeticSort'
})
export class MenuAlphabeticSortPipe implements PipeTransform {

  // If the list has 0 or 1 element, no sorting is required
  transform(items: IMenuItem[]): IMenuItem[] {
    if (!items) return []
    
    if (items.length <= 1) {
      return items;
    }

    const firstItem = items[0];
    const sortedItems = items.slice(1).sort((a, b) => (a.label || '').localeCompare(b.label || '')); // Sorts items starting from second
    return [firstItem, ...sortedItems];
  }

}
