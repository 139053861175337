import { ChangeDetectorRef, Directive, inject, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { FeatureFlagsManager } from "@core/features-flags/feature-flags.manager";
import { Subscription } from "rxjs";
import { CFeaturesFlagConfigTypes } from "src/app/configs/features-flags/models/constants/features.configs.constants";
import { FeaturesFlagsConfigType } from "src/app/configs/features-flags/models/types/features.config.types";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[featureFlags]",
})
export class FeatureFlagsDirective implements OnInit, OnDestroy {

  //#region [---- DEPENDENCIES ----]

  private templateRef: TemplateRef<any> = inject(TemplateRef);
  private viewContainer: ViewContainerRef = inject(ViewContainerRef);
  private featureFlagsManager: FeatureFlagsManager = inject(FeatureFlagsManager);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  //#endregion

  //#region [---- PROPERTIES ----]

  private _flag: string;
  private subscription: Subscription;
  private configFeatureType:FeaturesFlagsConfigType;


  //#endregion

  //#region [---- LIFE CYCLES ----]

  ngOnInit() {
    this.configFeatureType = this.featureFlagsManager.getConfigType
    if(this.configFeatureType === CFeaturesFlagConfigTypes.FIREBASE){
      this.subscription = this.featureFlagsManager.featuresSubject.subscribe(() => {
        this.updateView();
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }
  //#endregion

  //#region [---- LOGIC ----]

  @Input() set featureFlags(flag: string) {
    this._flag = flag;
    this.updateView()
  }

  private updateView() {
    this.viewContainer.clear();
    if (!this._flag || this.featureFlagsManager.isFeatureEnabled(this._flag)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }

    if(this.configFeatureType) this.cdr.detectChanges();
  }

  //#endregion

}


