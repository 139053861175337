import { StatusShippingCardType } from "@pages/parents/pages/request-card/models/types/card.types";
import {
  CParentPriorityType,
  CParentTransactionType,
  CCardType,
} from "./parent.constant";

export interface IParentRegisterPersonalDataCheckInvalidInput {
  birthDateInput: boolean;
  idTypeInput: boolean;
  idNumber: boolean;
}

export interface IParentRegisterAddressDataCheckInvalidInput {
  addressLineInput: boolean;
  cityInput: boolean;
  stateInput: boolean;
  postalCodeInput: boolean;
}

export interface AddressState {
  name: string;
  code: string;
}

export interface ITeenInformation {
  firstName: string;
  lastName: string;
}

export interface ITeenInformationCheckValidInput {
  firstNameInput: boolean;
  lastNameInput: boolean;
}

export interface ILastTransactionParent {
  id: string;
  transaction_type: (typeof CParentTransactionType)[keyof typeof CParentTransactionType];
  priority_type: (typeof CParentPriorityType)[keyof typeof CParentPriorityType];
  bank_entity: string;
  debit_pull_card_last4: string;
  sender: string;
  toName: string;
  transaction_date_formatted?: string;
  transaction_hour?: string;
  transaction_date: string;
  transaction_initiated: string;
  created_at: string
  amount: number;
  fee?: number;
  status: (typeof CTransferStatus)[keyof typeof CTransferStatus];
  teenName?: string;
  date?: string
  icon?: string
}

export interface IParentGroupTransaction {
  transactions: ILastTransactionParent[];
  titleDate: string;
}

export const CTransferStatus = {
  NONE: "NONE",
  COMPLETE: "COMPLETE",
  PROCESSING: "PROCESSING",
  CANCELED: "CANCELED",
};

export interface IWalletTeen {
  /** Se usa para enviar una transaccion */
  user_id: string;
  name: string;
  /** Se usa para consultar el balance */
  account_id: string;
  balance: number;
  hasPhysicalCard: boolean;
  full_name: string;
  cards: ICardsTeen[];
}

export { CParentTransactionType };

export interface IWalletInfo {
  user_id: string;
  account_id: string;
  balance: number;
  cards: ICardsTeen[];
}

export interface ICardsTeen {
  id: string;
  type: (typeof CCardType)[keyof typeof CCardType];
  status: string;
  last_4: string;
  expiry_month: string;
  expiry_year: string;
  shipping_info?: IWalletCardShipping;
}

export interface IWalletCardShipping {
  id: string;
  address: IWalletCardAddress;
  eta: Date;
  delivery_partner: string;
  tracking_number: string;
  tracking_url: string;
  delivery_status: StatusShippingCardType;
}

export interface IWalletCardAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
}
