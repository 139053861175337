import { ICardsState, IMobucksState } from "src/app/modules/banking/models/interfaces/state.interface";

export interface BankingState {
    mobucks: IMobucksState | null;
    cards: ICardsState | null;
}

export const initialBankingState: BankingState = {
     mobucks: null,
     cards: null
}

