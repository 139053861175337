import { inject, Injectable } from '@angular/core';
import { CanMatch, Params, Route, Router, UrlTree } from '@angular/router';
import { FeatureFlagsManager } from '@core/features-flags/feature-flags.manager';
import { map, Observable, of } from 'rxjs';
import { CFeaturesFlagConfigTypes } from 'src/app/configs/features-flags/models/constants/features.configs.constants';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsGuard implements CanMatch {

  //#region [---- DEPENDENCIES ----]
  private readonly featureFlagsManager:FeatureFlagsManager = inject(FeatureFlagsManager)
  private readonly router:Router = inject(Router)
  //#endregion

  //#region [---- GUARD METHODS ----]
  canMatch(route: Route): Observable<boolean | UrlTree> {
    const { data: { path = '/', shouldBe = true } = {} } = route;
    return this.getFeatureFlag(route).pipe(
      map((isEnabled) => {
        const queryParams = this.getQueryParams()
        return (isEnabled === shouldBe) || this.router.createUrlTree([path], { queryParams })
      }),
    );
  }

  private getFeatureFlag(route: Route): Observable<boolean>{
    const { data: { feature } = {} } = route;
    if (this.featureFlagsManager.getConfigType === CFeaturesFlagConfigTypes.FILES_CONFIG) {
      const isEnabled = this.featureFlagsManager.isFeatureEnabled(feature)
      return of(isEnabled)
    }

    if (this.featureFlagsManager.getConfigType === CFeaturesFlagConfigTypes.FIREBASE) {
      this.featureFlagsManager.featureActive = feature || '';
    }

    return this.featureFlagsManager.observeFeatureEnabled(feature)
  }

  private getQueryParams(): Params {
    return this.router.getCurrentNavigation()?.initialUrl?.queryParams || {}
  }
  //#endregion
}
