import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Pipes
import { CapitalizePipe } from './capitalize.pipe';
import { ByteToMegaBytePipe } from './byte-to-megaByte.pipe';
import { CapitalizeFullPipe } from './capitalize-full.pipe';
import { TruncateTextPipe } from './truncate-text.pipe';
import { SearchColorPipe } from '@shared/styles/storybook/list-colors/search-color.pipe';
import { SplitPipe } from './split.pipe';
import { ReplacementPipe } from '@pages/parents/pages/send-money/components/money-contacts/pages/checkout/pipes/replacement.pipe';
import { FormatMoCardTextPipe } from './format-mo-card-text.pipe';


const pipes = [
  ByteToMegaBytePipe,
  CapitalizeFullPipe,
  CapitalizePipe,
  SearchColorPipe,
  TruncateTextPipe,
  SplitPipe,
  ReplacementPipe,
  FormatMoCardTextPipe
]

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes, ],
  exports:[...pipes]
})
export class PipesModule { }
