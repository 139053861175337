<div class="container">
    <p-dropdown 
        [(ngModel)]="selectedCountry"
        [ngModelOptions]="{standalone: true}"
        (onChange)="validatePhoneForE164()"
        [options]="countries"
        [filter]="true"
        [disabled]="onlyFirstCountry || isDisabled"
        class="autocomplete-component"
        [ngClass]="{'background-disabled': backgroundDisabled}"
        optionLabel="name"
        filterBy="name,dial_code"
        [placeholder]="placeholderCountry"
        mdkNoTranslate
        >

        <ng-template pTemplate="selectedItem">
            <div class="country-item country-item-value" *ngIf="selectedCountry">
                <picture class="flag-container">
                    <img width="30" height="20"  *ngIf="selectedCountry.code.toLowerCase() !== 'us'" class="flag-element" src="assets/showcase/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry.code.toLowerCase()" />
                    <img  width="30" height="20"  *ngIf="selectedCountry.code.toLowerCase() === 'us'" class="flag-element" [class]="'flag'" src="./assets/i18n/images/usa.svg" alt="usaFlag">
                </picture>
                <div class="country-number-selected">{{selectedCountry.dial_code}}</div>
            </div>
        </ng-template>

        <ng-template let-country pTemplate="item">
            <div class="country-item" >
                <picture class="flag-container">
                    <img *ngIf="country.code.toLowerCase() !== 'us'" class="flag-element" src="assets/showcase/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" />
                    <img *ngIf="country.code.toLowerCase() === 'us'" class="flag-element" [class]="'flag'" src="./assets/i18n/images/usa.svg" alt="usaFlag">
                </picture>
                <div class="country-number">{{country.name}} ({{country.dial_code}})</div>
            </div>
        </ng-template>
    </p-dropdown>

    <!-- Input -->
    <div class="input-container"
         [ngClass]="{'no-height': !heightActive}">
        <span class="p-float-label">
            <input 
                type="text"
                pInputText
                min="0"
                step="1"
                #phoneInput
                id="phoneInput"
                class="input-element"
                [value]="phoneNumberValue?.length > 0 ? (phoneNumberValue | mask: phoneNumberMask) : ''"
                (input)="onInput($event)"
                [ngClass]="{'invalid-input': (phoneIsInvalid || customErrorLabel), 'background-disabled': backgroundDisabled}"
                [disabled]="isDisabled"
                (keypress)="validateInput($event)"
                (keydown)="copyPasteValidate($event)"
                (keydown)="backSpaceValidate($event)"
                (paste)="paste($event)"
                (keyup)="validatePhoneForE164()"
                (blur)="validatePhoneForE164()"
                maxlength="14"
            >
            <label
                #labelPhone class="label-text"
                for="phoneInput"
                [ngClass]="{'label-error': (phoneIsInvalid || customErrorLabel), 'top-label': phoneNumberValue}">{{placeholderPhone}}
            </label>
            <i *ngIf="(phoneIsInvalid || customErrorLabel)" class="pi pi-exclamation-circle icon-warning label-error"></i>
            <span
                *ngIf="phoneIsInvalid"
                class="label-error label-message">
                {{errorPhoneLabel}}
            </span>
            <span
                *ngIf="customErrorLabel"
                class="label-error label-message">
                {{customErrorLabel}}
            </span>
        </span>

    </div>
</div>





   