import { Action, createReducer, on } from '@ngrx/store';
import { AllowedLanguages } from '../../../configs/libraries/ngx-translate/constants/languages.constants';
import { setLanguage } from './language.actions';


export interface State {
    active: null | typeof AllowedLanguages[keyof typeof AllowedLanguages];
}

export const initialState: State = {
    active: null,
}

const _languageReducer = createReducer(initialState,
    on(setLanguage,  (state,action) => ({ ...state, active: action.language})),
);

export function languageReducer(state: State | undefined, action: Action) {
    return _languageReducer(state, action);
}

