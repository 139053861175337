import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';
import { CStaticResourceType } from '@core/static-serve/models/constants/static-serve.constants';
import { StaticFilesManager } from '@core/static-serve/static-serve.manager';
@Directive({
  selector: '[mdkChangeTextWithColorAndImage]'
})
export class ChangeColorSelectedDirective implements OnInit {

  @Input('mdkChangeTextWithColorAndImage') text: string;

  private readonly el:ElementRef = inject(ElementRef)
  private readonly staticFilesManager:StaticFilesManager = inject(StaticFilesManager)


  ngOnInit(): void {
    if (this.text) {
      const staticServeUrl = this.staticFilesManager.getUrlStaticServer(CStaticResourceType.IMAGES)

      // Color Logic -> <#FF0000;Text>
      const colorRegex = /<#([a-fA-F0-9]{6});(.*?)>/g;
      const coloredText = this.text.replace(colorRegex, (_match, color, content) => {
        return `<span style="color: #${color}">${content}</span>`;
      });

      // Image Logic -> [image.jpg,100,50]
      const imageRegex = /\[([^\]]+),(\d+),(\d+)\]/g;
      const textWithImages = coloredText.replace(imageRegex, (_match, fileName, width, height) => {
        return `<img class="card-element" src="${staticServeUrl}/${fileName}" width="${width}" height="${height}" >`;
      });

      // Link Logic -> {https://example.com,Click here}
      const linkRegex = /{([^,]+),([^}]+)}/g; // Matches {url,text}
      const finalText = textWithImages.replace(linkRegex, (_match, url, linkText) => {
        return `<a href="${url}" target="_blank">${linkText}</a>`;
      });

      this.el.nativeElement.innerHTML = finalText;
    }
  }

}
