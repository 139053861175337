import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core"

import { TransactionBadgeStateType } from "./models/types/transaction-badge-states.types"
import { CTransactionBadgeStates } from "./constants/badge-states.constants";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: 'mdk-transaction-badge-state',
    templateUrl: 'transaction-badge-state.component.html',
    styleUrls: [
        './styles/transaction-badge-state.mobile.scss',
    ],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
    ],
})
export class TransactionBadgeStateComponent {
    public readonly ALL_STATES = CTransactionBadgeStates;

    @Input() state: TransactionBadgeStateType = this.ALL_STATES.PROCESSING
}