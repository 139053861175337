import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map, Observable } from 'rxjs';
import { GlobalState } from '@core/global-state/app.reducer';
import { selectToken } from '@pages/auth/state/authentication.selectors';
import { RedirectionManager } from '@core/redirection/redirection.manager';
import { C_PUBLIC_ROUTES_ALLOWED } from '@core/guards/public.routes';
import routesActions from '@core/global-state/routes/routes.actions';
import { AUTH_PARENT_FULL_ROUTES } from '@pages/auth/auth-parent/models/constants/auth-parent.routes.constants';
import { AuthUtils } from 'src/app/modules/authentication/authentication.utils';


@Injectable({
  providedIn: 'root',
})
export class LoggedGuard implements CanActivate, CanLoad {

  //#region [---- DEPENDENCIES ----]

  private readonly router:Router = inject(Router)
  private store: Store<GlobalState> = inject(Store<GlobalState>)
  private redirectionManager: RedirectionManager = inject(RedirectionManager)
  private authUtils : AuthUtils = inject(AuthUtils)
  //#endregion

  //#region [---- PROPERTIES ----]

  token: string = "";

  //#endregion

  //#region [---- METHODS GUARDS ----]

  canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.isLogged(null)
  }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const urlToAccess:string = state.url
    if(this.isUrlPublic(urlToAccess)) return true
    const queryParams = _route.queryParams
    this.redirectionManager.manageRedirection(urlToAccess,queryParams)
    return this.isLogged(state)
  }

  //#endregion

  //#region [---- LOGIC ----]

  isLogged(state?: RouterStateSnapshot):Observable<boolean | UrlTree> {
    return this.store.select(selectToken).pipe(
      first(),
      map(token => {
        return token ? true : this.router.createUrlTree([this.getAuthUrlByRole(state)])
      })
    );
  }

  private getAuthUrlByRole(state?: RouterStateSnapshot): string {
    const isParentPath = state?.url?.startsWith("/parents") || state?.[0]?.["path"]?.startsWith("/parents");
    return isParentPath ? AUTH_PARENT_FULL_ROUTES.SIGN_IN_PARENT : AUTH_PARENT_FULL_ROUTES.SIGN_IN_TEEN;
  }

  private isUrlPublic(url:string):boolean {
    const allowedRoutes = Object.values(C_PUBLIC_ROUTES_ALLOWED);
    const flattenedRoutes = allowedRoutes.reduce((accumulator, routeArray) => accumulator.concat(routeArray), [] as string[]);

    const isUrlPublic = flattenedRoutes.some(route => url.startsWith(route));

    this.store.dispatch(routesActions.setUrlIsPublic({ isUrlPublic }));
    return isUrlPublic;
  }

  //#endregion

}
