import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mdk-tip-finantial',
  templateUrl: './tip-finantial.component.html',
  standalone: true,
  imports: [
    TranslateModule
  ],
  styleUrls: [
    './styles/tip-finantial.component.mobile.scss',
  ],
})
export class TipFinantialComponent {
}
