import { inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppFunctions {

  //#region [---- [INJECTION DEPENDENCIES] ----]

  private readonly loc:Location = inject(Location)
  private readonly localhost: string = 'localhost'
  private readonly localhostDns: string = 'local.dev.modakmakers.com'

  //#endregion

  //#region [---- LOGIC ----]

  public getHostName():string{
    const angularRoute = this.loc.path();
    const url = window.location.href;
    const domainAndApp = url?.replace(angularRoute, '');
    return domainAndApp
  }

  public isLocalhost(): boolean {
    return this.getHostName().includes(this.localhost) || this.getHostName().includes(this.localhostDns)
  }

  //#endregion
}