import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ILastTransactionParent, IWalletTeen } from 'src/app/pages/parents/interfaces/parent.interfaces';

@Component({
  selector: 'mdk-latest-transactions',
  templateUrl: './latest-transactions.component.html',
  styleUrls: ['./latest-transactions.component.scss']
})
export class LatestTransactionsComponent {

  //#region [---- DEPENDENCIES & PROPERTIES ----]

  private route: Router = inject(Router)

  @Input() teenData: IWalletTeen;
  @Input() lastTransactions: ILastTransactionParent[];
  @Output() showTransactions: EventEmitter<void> = new EventEmitter<void>();

  //#endregion

  //#region [---- LOGIC ----]

  viewParentTransactions(): void {
    // TODO USAR CONSTANTE DE RUTA
    this.route.navigate(["/parents/transactions"]);
  }

  viewTeenTransactions(): void {
    this.showTransactions.emit();
  }

  viewDetails(transaction: ILastTransactionParent): void {
    if(this.teenData) {
      transaction.teenName = this.teenData.name;
    }
    sessionStorage.setItem('transactionDetail',JSON.stringify(transaction))
    this.route.navigate(['/parents/transactions/detail'])
  }

  //#endregion


}
