import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ErrorCustomMessages } from './models/constants/custom-messages.constants';
import { TOAST_SEVERITY_TYPES } from 'src/app/shared/primeng/constants/toast.constants';
import { CErrorMessages } from '@core/errors/models/constants/product-messages.constants';
import { errorContextType, ErrorPlatform, modakErrorType } from '@core/errors/models/types/errors.types';
import { NewErrorBodyModak, NewErrorModak } from '@core/errors/models/interface/error-modak';
import { SECOND } from '@shared/models/constants/time.constant';
import { IErrorBodyModak, IErrorModakCodeType } from '@core/errors/models/interface/errors.interface';
import { CErrorModakTypes } from '@core/errors/models/constants/modak-errors.constants';
import { ErrorOldCodesMessages } from '@core/errors/models/constants/old.codes.messages';
import { ErrorsUtils } from '@core/errors/errors.utils';
import { LogsManager } from '@core/logs/logs.manager';
import { ILogAdditionalError, ILogError } from '@core/logs/models/interface/error.interface';
import { CHttpStatusCodes } from '@core/errors/models/constants/http-status-code.constants';
import { CErrorSummaries } from '@core/errors/models/constants/summary.constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorsManager {

  private readonly messageService: MessageService = inject(MessageService)
  private readonly errorsUtils: ErrorsUtils = inject(ErrorsUtils)
  private readonly logsManager: LogsManager = inject(LogsManager)
  
  public manageErrors(error: ErrorPlatform,context?:errorContextType, log?: ILogAdditionalError): void {

    // Delegate error for log
    if(log) this.logError(error,log)

    // IS HTTP ERROR
    if(error instanceof HttpErrorResponse) {

      if (error.status >= HttpStatusCode.InternalServerError) {
        this.showErrorByToast(ErrorCustomMessages.DEFAULT_5XX)
        return
      }

      if (error.status === CHttpStatusCodes.NETWORK_ERROR) {
        this.showErrorByToast(ErrorCustomMessages.DEFAULT_NETWORK_ERROR)
        return
      }

      if (error.status === HttpStatusCode.Forbidden && error.headers?.get('x-modak-waf') === 'true') {
        this.showErrorByToast(ErrorCustomMessages.ACCESS_RESTRICTED);
      }

      if (error.status === HttpStatusCode.TooManyRequests) {
        this.showErrorByToast(ErrorCustomMessages.TOO_MANY_REQUESTS);
      }

      this.manageErrorByType(error.error,context)
    }

    // IS MODAK ERROR
    if(error instanceof NewErrorModak){
      const modakErrors = error.errors
      const errorMessage = this.getMessage(modakErrors,context)
      const summary = modakErrors[0]?.summary
      this.showErrorByToast(errorMessage,summary)
    }
  }

  //#region [---- MESSAGES LOGIC ----]

  private getMessage(modakErrors:NewErrorBodyModak[],context:errorContextType):string{
    // get last error from array
    const lastError = modakErrors[modakErrors.length - 1];

    const productMessage = this.getProductMessage(lastError?.code,context)
    return productMessage ? productMessage : `${lastError?.message}`
  }

  private getProductMessage(errorCode: string, context: errorContextType): string {
    if (!CErrorMessages.hasOwnProperty(errorCode)) {
        return '';
    }
    return CErrorMessages[errorCode].hasOwnProperty(context) ? CErrorMessages[errorCode][context] : '';
  }

  //#endregion

  //#region [---- MODAK ERROR TYPES  ----]

  private manageErrorByType(error:any,context:errorContextType):void{
    const modakTypeError:modakErrorType = this.errorsUtils.determinateModakErrorType(error)

      switch (modakTypeError) {
        case CErrorModakTypes.NEW:
          this.manageNewErrorModak(error,context)
          break;
        case CErrorModakTypes.CAUSE:
          this.manageErrorModakWithCause(error)
          break;
        case CErrorModakTypes.CODE:
          this.manageErrorModakWithCode(error)
          break;

        default:
          this.showErrorByToast(ErrorCustomMessages.DEFAULT_NOT_5XX);
      }
  }

  private manageNewErrorModak(error:NewErrorModak,context:errorContextType):void{
    const modakErrors = error.errors
    const errorMessage = this.getMessage(modakErrors,context)
    this.showErrorByToast(errorMessage)
    return;
  }

  private manageErrorModakWithCause(error:IErrorBodyModak):void {
    const { cause } = error
    const errorMessage = cause ? cause : ErrorCustomMessages.DEFAULT_NOT_5XX
    this.showErrorByToast(errorMessage)
    return;
  }
  private manageErrorModakWithCode(error:IErrorModakCodeType):void {
    const { code } = error
    const errorMessage = code ? ErrorOldCodesMessages[`E${code}`] : ErrorCustomMessages.DEFAULT_NOT_5XX
    this.showErrorByToast(errorMessage)
    return;
  }

  //#endregion

  //#region [---- ACTION WITH ERROR ----]

  private showErrorByToast(message:string,summary?:string){
    this.messageService.add({
      life: SECOND * 6,
      severity: TOAST_SEVERITY_TYPES.ERROR,
      summary: summary  || CErrorSummaries.DEFAULT,
      detail: message
    });
  }

  private logError(error:ErrorPlatform,log:ILogAdditionalError) {    
    const logError:ILogError = {
      title: log.title,
      error,
      additionalTags: log.additionalTags
    }
    this.logsManager.error(logError, log.user)
  }

  public isCodeError(error:ErrorPlatform,code:string): boolean {
    if (error && error instanceof HttpErrorResponse) {
      const errorHttp: HttpErrorResponse = error as HttpErrorResponse
      return this.errorsUtils.httpErrorContainCode(errorHttp, code)
    }
    
    return false
  }

  //#endregion
}
