import { inject, Injectable } from '@angular/core';
import { staticLocalServerUrl } from '@core/static-serve/models/constants/static-serve.constants';
import { ResourcesStaticType } from '@core/static-serve/models/types/static-serve.types';
import { AppFunctions } from '@shared/utils/app.functions';

@Injectable()
export class StaticFilesManager {

  //#region [---- DEPENDENCIES ----]

  private appFunctions: AppFunctions = inject(AppFunctions);

  //#endregion

  //#region [---- PROPERTIES ----]

  private hostName:string;
  private urlByIp:string;

  //#endregion

  //#region [---- LOGIC ----]
  public getUrlStaticServer(resourceType: ResourcesStaticType):string {
    this.hostName = this.appFunctions.getHostName()
    if(this.isIpDirection(this.hostName)) this.setUrlByIp(this.hostName)

    const staticServerUrl = this.getUrl()
    return `${staticServerUrl}/static/${resourceType.toLowerCase()}`
  }
  private setUrlByIp(hostName:string):void{
    const protocol = (hostName.match(/^https?:\/\/(www\.)?/))[0]
    if(protocol){
      const urlWithOutProtocol = hostName.replace(protocol, '');
      const port = urlWithOutProtocol.slice(urlWithOutProtocol.indexOf(":") + 1, urlWithOutProtocol.length);
      const dynamicRegex = new RegExp(`(.*?${port})`);
      const onlyIp = urlWithOutProtocol.match(dynamicRegex);
      this.urlByIp = `${protocol}${onlyIp[1].replace(/4200/, '4300')}`
    }
  }
  private getUrl(){
    if(this.urlByIp) return this.urlByIp
    if(this.hostName.includes('localhost') || this.hostName.includes('local.dev.modakmakers.com')) return staticLocalServerUrl

    return this.hostName
  }
  private isIpDirection(url: string): boolean {

    const urlWithOutProtocol = url.replace(/^(https?:\/\/)?/, '');
    const urlWithBeforePort = urlWithOutProtocol.slice(0, urlWithOutProtocol.indexOf(":"))

    const regex = /^(([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3}))(?::([0-9]{1,5}))?$/;
    const matches = regex.exec(urlWithBeforePort);
    if (matches === null) {
      return false;
    }

    for (const octeto of matches[1].split(".")) {
      if (Number(octeto) < 0 || Number(octeto) > 255) {
        return false;
      }
    }

    return true;
  }
   //#endregion
}
