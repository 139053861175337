<section class="item">

    <!-- Image -->
    <mdk-icon-profile
        [type]="transaction.transaction_type"
        [name]="transaction.toName"
        [imageURL]="(transaction.icon ? (transaction.icon | staticServe) : '')"
    >
    </mdk-icon-profile>

    <!-- Data -->
    <div class="transaction-info-container transaction-status-{{transaction.status}}">
        <div class="data-transaction">
            <h4 class="label label-{{transaction.status}}" *ngIf="transaction.transaction_type === feeTransaction">{{"PARENT_PORTAL.TRANSACTIONS.TRANSFER_FEE" | translate}}</h4>
            <h4 class="label label-{{transaction.status}}" *ngIf="transaction.transaction_type === creditTransaction">{{"PARENT_PORTAL.TRANSACTIONS.RECEIVED_ON_MY_ACCOUNT" | translate}}</h4>
            <h4 class="label label-{{transaction.status}}" *ngIf="transaction.transaction_type === debitTransaction">{{"PARENT_PORTAL.TRANSACTIONS.SEND_TO" | translate}} {{transaction.toName}}</h4>
            <span *ngIf="transaction.status === statusComplete" class="date">{{transactionDate}}</span>
            <span *ngIf="transaction.status !== statusComplete" class="date">{{transactionDate}} - {{transaction.status | capitalize}}</span>
        </div>
        <span class="amount amount-{{transaction.status}}">
            <span class="credit" *ngIf="transaction.transaction_type === creditTransaction; else isDebit">+${{transaction.amount | number:'1.2-2' }}</span>
            <ng-template #isDebit>
                <span>-${{transaction.amount | number:'1.2-2' }}</span>
            </ng-template>
        </span>
    </div>
</section>