import { Injectable, inject } from "@angular/core";
import { GlobalState } from "@core/global-state/app.reducer";
import { Store } from "@ngrx/store";
import { IUser } from "@pages/auth/interfaces/auth.interfaces";
import { AuthUtils } from "src/app/modules/authentication/authentication.utils";
import * as userActions from "src/app/pages/auth/state/authentication.actions";
import * as devicesActions from "src/app/core/global-state/device/device.actions";
import { WEB_VIEW_PROPERTIES } from "./constants/webview-manager.constants";
import { SentryService } from "src/app/configs/libraries/sentry/sentry.service";
import { FeatureFlagsManager } from "@core/features-flags/feature-flags.manager";
import { CFeatures } from "@core/features-flags/models/constants/features-flags.constants";

@Injectable({
  providedIn: "root",
})
export class WebviewManager {
  //#region [---- PROPERTIES ----] 

  private readonly webview_token = localStorage.getItem(
    WEB_VIEW_PROPERTIES.AUTHENTICATION
  );
  private readonly webview_refreshToken = localStorage.getItem(
    WEB_VIEW_PROPERTIES.REFRESH_TOKEN
  );

  private readonly webview_return_url =
    localStorage.getItem(WEB_VIEW_PROPERTIES.RETURN_URL);
  
  private isWebView :boolean = false 

  //#endregion

  //#region [---- DEPENDENCIES ----]

  private readonly authUtils: AuthUtils = inject(AuthUtils);
  private readonly store: Store<GlobalState> = inject(Store);
  private readonly sentryService : SentryService = inject(SentryService)
  private readonly featureFlagManager: FeatureFlagsManager = inject(FeatureFlagsManager)
  //#endregion

  //#region [---- LOGIC ----]

  public initWebView(): void {
    if (this.featureFlagManager.isFeatureEnabled(CFeatures.AUTHENTICATION_V3) && this.webview_token) {
      this.setAuthentication();
      this.setWebView();
      return
    }

    if (this.webview_token && this.webview_refreshToken) {
      this.setAuthentication();
      this.setWebView();
    }
  }

  private transformWebViewTokenInUserWeb(): IUser {
    if(!this.webview_token) return null
    const tokenDecoded = this.authUtils.getTokenDecodedUser(this.webview_token);

    const user: IUser = {
      id: tokenDecoded?.user_uuid,
      name: tokenDecoded?.name,
      email: tokenDecoded?.email,
      phone: tokenDecoded?.phone_number,
      role: tokenDecoded?.role,
      accessToken: this.webview_token,
      refreshToken: this.webview_refreshToken,
    };

    return user;
  }

  public setWebView(): void {
    this.isWebView = true
    this.store.dispatch(devicesActions.setWebView());
  }

  public unsetWebView(): void {
    this.isWebView = false
    this.store.dispatch(devicesActions.unSetWebView());
  }

  private setAuthentication(): void {
    const authentication: IUser = this.transformWebViewTokenInUserWeb();
    this.store.dispatch(userActions.setUser({ authentication }));
    this.sentryService.setUser(authentication)
  }

  public getReturnUrl(): string {
    return this.webview_return_url;
  }
  public isWebViewActive() : boolean {
    return this.isWebView
  }
  //#endregion
}
