import { Injectable, NgZone } from '@angular/core';
import { CActivityEvents, CTimeoutSeconds } from '@core/services/timeout/timeout.constants';
import { Observable, Subject, fromEvent, merge, timer } from 'rxjs';
import { startWith, switchMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimeOutService {
  private inactivity$ = new Subject<boolean>();

  constructor(private ngZone: NgZone) {
    this.monitorUserActivity();
  }

  private monitorUserActivity(): void {
    const activityEvents$ = merge(
      ...CActivityEvents.map(event => fromEvent(document, event))
    );

    this.ngZone.runOutsideAngular(() => {
      activityEvents$
        .pipe(
          startWith(0), 
          switchMap(() => timer(CTimeoutSeconds)), 
          map(() => true) 
        )
        .subscribe(() => this.inactivity$.next(true));
    });
  }

  getInactivityObservable(): Observable<boolean> {
    return this.inactivity$.asObservable();
  }
}
