import { Injectable, inject } from '@angular/core';
import { IMenuItem } from './models/interfaces/menu.interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  //#region [---- [DEPENDENCIES] ----]
  private readonly translate : TranslateService = inject(TranslateService)
  //#endregion

  //#region [---- [PROPERTIES] ----]
  private defaultLinks = {
    url: "/parents/home",
    icon: "pi pi-home",
    img: "",
    enabled: true,
    label: this.translate.instant("MAIN.MENU_ITEMS.HOME"),
    labelTranslate: true,
    classActive: "active",
  }

  private links: BehaviorSubject<IMenuItem[]> = new BehaviorSubject<IMenuItem[]>([this.defaultLinks]);
  //#endregion

  //#region [---- [LOGIC] ----]
  public getLinks(): Observable<IMenuItem[]> {
    return this.links.asObservable();
  }

  public setLink(menuLink: IMenuItem): void {
    const linkExists = this.links.getValue().some(existingLink => existingLink.url === menuLink.url);
    if (!linkExists) {
      this.links.next([...this.links.getValue(), menuLink]);
    }
  }
  
  public setLinks(menuLinks: IMenuItem[]): void {
    const uniqueLinks = menuLinks.filter(link => {
      return !this.links.getValue().some(existingLink => existingLink.url === link.url);
    });
    this.links.next([...this.links.getValue(), ...uniqueLinks]);
  }
  
  public removeLinks():void {
    this.links.next([this.defaultLinks])
  }
  //#endregion
}

