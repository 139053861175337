import { Injectable, inject } from '@angular/core'
import { 
    signIn, 
    SignInInput, 
    SignInOutput, 
    confirmSignIn,
    ConfirmSignInOutput,
    signUp,
    SignUpInput,
    SignUpOutput,
    confirmSignUp,
    ConfirmSignUpInput,
    ConfirmSignUpOutput,
    resendSignUpCode,
    updatePassword,
    UpdatePasswordInput,
    resetPassword,
    ResetPasswordInput,
    ResetPasswordOutput,
    confirmResetPassword,
    ConfirmResetPasswordInput,
    signOut,
    fetchAuthSession,
    autoSignIn,
    AuthError,
    getCurrentUser,
    AuthUser,
    updateMFAPreference,
} from 'aws-amplify/auth';
import { Observable, catchError, from, map, of, retry, tap, throwError } from 'rxjs';
import { 
    SignInInput as ISignInInput, 
    SignUpInput as ISignUpInput, 
    ConfirmSignUpInput as IConfirmSignUpInput,
    ChangePasswordInput as IChangePasswordInput,
    ForgotPasswordInput as IForgotPasswordInput,
    ForgotPasswordConfirmInput as IForgotPasswordConfirmInput,
    ClientMetadata,
    GetTokenInput,
    GetTokenPayload,
    IAmplifyService,
    IUserError
} from './models/interfaces/aws-amplify.interfaces';
import { CAwsAmplifyErrorTitles } from './models/constants/aws-amplify.constants';
import { RolesAccount } from '@pages/auth/interfaces/auth.constant';
import { AuthFlowType, MFAPreferenceType } from './models/interfaces/aws-amplify.types';
import { LogsManager } from '@core/logs/logs.manager';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SECOND } from '@shared/models/constants/time.constant';
import { HIDE_SPINNER } from '@core/interceptors/constants/interceptors.constants';
import { TrackingManager } from '../tracking-manager/tracking-manager';
import { PROJECT_TAGS } from '../tracking-manager/constants/projects.constants';
import { PASSWORD_EVENTS } from 'src/app/modules/authentication/modules/password/models/events/password.events';
import { GlobalState } from '@core/global-state/app.reducer';
import { Store } from '@ngrx/store';
import { logoutAction } from '@core/global-state/clear/clearState.actions';
import * as responsiveActions from "@core/global-state/responsive/responsive.actions";


@Injectable({
    providedIn:'root'
})
export class AuthAwsAmplifyService implements IAmplifyService {

    private readonly USERS_DOMAIN_URL = environment.APIS.USERS_DOMAIN_URL;

    private readonly logsManager: LogsManager = inject(LogsManager);
    private readonly httpClient: HttpClient = inject(HttpClient);
    private readonly trackingManager: TrackingManager = inject(TrackingManager);
    private readonly store: Store<GlobalState> = inject(Store<GlobalState>);

    public handleSignIn(input: ISignInInput, authFlowType: AuthFlowType, clientMetadata?: ClientMetadata): Observable<SignInOutput> {
        const params: SignInInput = {
            username: input.username,
            password: input.password,
            options: {
                authFlowType: authFlowType,
                clientMetadata,
            }
        }

        const obs$ = from(signIn(params))

        return this.transformError(obs$, CAwsAmplifyErrorTitles.HANDLE_SIGN_IN, { phone: input.username })
    }

    public handleSignInConfirmation(SMSCode: string, username:string): Observable<ConfirmSignInOutput> {
        const obs$ = from(confirmSignIn({ challengeResponse: SMSCode }))
        return this.transformError(obs$, CAwsAmplifyErrorTitles.HANDLE_SIGN_IN_CONFIRM, { phone: username })
    }

    public handleSignUp(input: ISignUpInput): Observable<SignUpOutput> {
        const params: SignUpInput = {
            username: input.username,
            password: input.password,
            options: {
                userAttributes: {
                    name: input.name,
                    email: input.email,
                    phone_number: input.phoneNumber, // E.164 number convention
                },
                clientMetadata: {
                    shared_key: input.shared_key
                }
            },
        }

        const obs$ = from(signUp(params))

        return this.transformError(obs$, CAwsAmplifyErrorTitles.HANDLE_SIGN_UP, { phone:input.username, email:input.email })
    }

    public handleSignUpConfirmation(input: IConfirmSignUpInput): Observable<ConfirmSignUpOutput> {
        const params: ConfirmSignUpInput = {
            confirmationCode: input.confirmationCode,
            username: input.username,
            options: {
                clientMetadata: {
                    first_name: input.first_name,
                    last_name: input.last_name,
                    referral_code: input?.referral_code,
                    invitation_code: input?.invitation_code,
                    role: RolesAccount.PARENT,
                    shared_key: input?.shared_key || ''
                },
            }
        }

        const obs$ = from(confirmSignUp(params))

        return this.transformError(obs$, CAwsAmplifyErrorTitles.HANDLE_SIGN_UP_CONFIRM, { phone: input.username })
    }

    public autoSignIn(): Observable<SignInOutput> {
        return this.transformError(from(autoSignIn()), CAwsAmplifyErrorTitles.AUTO_SIGN_IN)
    }

    public handleResendCodeSignUpConfirmation(username: string): Observable<void> {
        return this.transformError(from(resendSignUpCode({ username: username })), 
            CAwsAmplifyErrorTitles.HANDLE_RESEND_CODE_SIGN_UP_CONFIRM, { phone: username }).pipe(
            map(()=> {})
        )
    }

    public handleChangePassword(input: IChangePasswordInput) {
        const params: UpdatePasswordInput = {
            newPassword: input.newPassword,
            oldPassword: input.oldPassword,
        }
        const obs$ = from(updatePassword(params))
        return this.transformError(obs$, CAwsAmplifyErrorTitles.HANDLE_CHANGE_PASSWORD)
    }

    public handleForgotPassword(input: IForgotPasswordInput, clientMetadata?: ClientMetadata): Observable<ResetPasswordOutput> {
        const params: ResetPasswordInput = {
            username: input.username, 
            options: {
                clientMetadata
            }
        } 
        const obs$ = from(resetPassword(params))
        return this.transformError(obs$, CAwsAmplifyErrorTitles.HANDLE_FORGOT_PASSWORD, { phone: input.username })
    }

    public handleConfirmForgotPassword(input: IForgotPasswordConfirmInput): Observable<void> {
        const params: ConfirmResetPasswordInput = {
            confirmationCode: input.confirmationCode,
            newPassword: input.password,
            username: input.username,
        }
        const obs$ = from(confirmResetPassword(params)).pipe(
            tap(() => {
                this.trackingManager.trackEventV2(PASSWORD_EVENTS.API_RESET_PASSWORD_SUCCESS, {
                    project_tag: PROJECT_TAGS.PASSWORD
                })
            }),
            catchError(err => {
                this.trackingManager.trackEventV2(PASSWORD_EVENTS.API_RESET_PASSWORD_ERROR, {
                    project_tag: PROJECT_TAGS.PASSWORD,
                    error: {
                        code: err?.code,
                        message: err?.message
                    }
                }, err)

                return throwError(() => err)
            })
        )
        return this.transformError(obs$, CAwsAmplifyErrorTitles.HANDLE_CONFIRM_FORGOT_PASSWORD, { phone:input.username })
    }

    public updateMFA(sms: MFAPreferenceType): Observable<void> {
        const obs$ = from(updateMFAPreference({
            sms: sms,
        }))

        return this.transformError(obs$, CAwsAmplifyErrorTitles.UPDATE_MFA)
    }

    public handleSignOut(): Observable<void> {
        return this.transformError(from(signOut()), CAwsAmplifyErrorTitles.HANDLE_SIGN_OUT).pipe(
            tap(() => this.clearData())
        )
    }

    public getCurrentSession(forceRefresh:boolean = false): Observable<string> {
        const obs$ = from(fetchAuthSession({ forceRefresh })).pipe(
            map(auth => auth?.tokens?.idToken.toString())
        )

        return this.transformError(obs$, CAwsAmplifyErrorTitles.GET_CURRENT_SESSION)
    }

    public getCurrentUser(): Observable<AuthUser> {
        return this.transformError(from(getCurrentUser()), CAwsAmplifyErrorTitles.GET_CURRENT_USER)
    }

    public hasSession(): Observable<boolean> {
        return from(this.getCurrentUser()).pipe(
            map(userAuth => Boolean(userAuth?.userId)),
            catchError(() => of(false))
        )
    }

    public getToken(input: GetTokenInput): Observable<string> {
        const options = {
            context: new HttpContext().set(HIDE_SPINNER, true)
        }
        const body: GetTokenPayload = { target: input.target, shared_key: input.shared_key }
        const GET_TOKEN_URL = `${this.USERS_DOMAIN_URL}/auth/get_token`
        return this.httpClient.post<{ code: string }>(GET_TOKEN_URL, body, options).pipe(
            retry({ count: 5, delay: SECOND }),
            map(res => res?.code)
        )
    }

    private transformError<T>(obs$: Observable<T>, methodName: string, user?:IUserError): Observable<T> {
        return obs$.pipe(
            catchError((err: AuthError) => {
                this.logsManager.error({
                    title: `${CAwsAmplifyErrorTitles.BASE} - ${methodName}`,
                    error: err,
                }, { username: user?.phone, email: user?.email, id: user?.id })
                return throwError(() => err.name)
            })
        )
    }

    public clearData(): void {
        this.store.dispatch(logoutAction());
        this.store.dispatch(
            window.innerWidth < 960
                ? responsiveActions.isMobile()
                : responsiveActions.isDesktop()
        );

        localStorage.clear();
        sessionStorage.clear();
    }
}