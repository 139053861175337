export const HttpTranslateUrl = './assets/i18n/'

export const AllowedLanguages = {
    SPANISH: 'SPANISH',
    ENGLISH: 'ENGLISH'
} as const;

export const LanguagesForTranslate = {
    SPANISH: 'es',
    ENGLISH: 'en'
} as const;

export const LanguagesTranslateForState = {
    es: 'SPANISH',
    en: 'ENGLISH'
}

export const LanguagesForShow = {
    SPANISH: 'Español',
    ENGLISH: 'English'
}
