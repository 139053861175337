<section
  (click)="onClick()"
  class="image-container{{ itemClass ? '--' + itemClass : '' }} {{backgroundColor}}"
  [ngClass]="{'is-clickable': isClickable, 'remove-background': removeBackground}">

  <img class="image-element{{customHeightPx || customWidthPx ? '--custom-size' : ''}}"
    *ngIf="imageURL"
    [ngSrc]="imageURL"
    [width]="customWidthPx ?? 24"
    [height]="customHeightPx ?? 24"
    alt="" />

  <div *ngIf="!imageURL && !icon" class="no-image-element">
    <span *ngIf="type !== 'FEE'!!!; type" class="letter accountActionIcon">
      {{ name?.charAt(0) }}
    </span>
    <i *ngIf="type === 'FEE'" class="pi pi-angle-double-right icon"></i>
  </div>

  <i *ngIf="icon" class="pi {{ icon }} icon-element"></i>
</section>
