import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ErrorsManager } from '@core/errors/errors.manager';
import { errorContextType } from '@core/errors/models/types/errors.types';
import { NO_INTERCEPTORS } from '@core/interceptors/constants/interceptors.constants';
import { REFERRALS_EVENTS } from '@pages/parents/modules/referrals/models/constants/referrals.events.constants';
import { IReferralsCodeResponse } from '@pages/parents/modules/referrals/models/interfaces/referrals.api.responses';
import { IReferralReward } from '@pages/parents/modules/referrals/models/interfaces/referrals.interfaces';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { PROJECT_TAGS } from 'src/app/configs/libraries/tracking-manager/constants/projects.constants';
import { TrackingManager } from 'src/app/configs/libraries/tracking-manager/tracking-manager';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReferralsApi {
    //#region [---- [DEPENDENCIES] ----]
    private readonly http: HttpClient = inject(HttpClient);
    private readonly errorsManager: ErrorsManager = inject(ErrorsManager);
    private readonly trackingManager: TrackingManager = inject(TrackingManager)
    //#endregion

    //#region [---- PROPERTIES ----]
    private USERS_DOMAIN_URL = environment.APIS.USERS_DOMAIN_URL
    //#endregion

    //#region [---- [API CALLS] ----]
    public getReferralCode(ctx:errorContextType): Observable<IReferralReward> {
        const url = `${this.USERS_DOMAIN_URL}/user/v1/referral/code`
        return this.http.get<IReferralsCodeResponse>(url).pipe(
            tap(() => this.trackingManager.trackEventV2(
                REFERRALS_EVENTS.API_PARENTHOME_REFERRALS_SUCCESS,
                {
                    project_tag: PROJECT_TAGS.REFERRALS
                }
            )),
            map(response => {
                return {
                    code: response?.code,
                    totalAmountEarned: response?.total_amount_earned,
                    reward: response?.reward.reward_config.amount_code_creator
                }
            }),
            catchError((error: HttpErrorResponse) => {
                this.trackingManager.trackEventV2(
                    REFERRALS_EVENTS.API_PARENTHOME_REFERRALS_ERROR,
                    {
                        project_tag: PROJECT_TAGS.REFERRALS,
                    },
                    error
                )
                this.errorsManager.manageErrors(error,ctx, {
                    title: `Referrals API - Get Referral Info`
                })
                return throwError(() => error);
            })
        )
    }

    public getReferralByCode(code: string): Observable<IReferralsCodeResponse> {
        const url = `${this.USERS_DOMAIN_URL}/public/user/v1/referral/code/${code}`

        const options = {
            context: new HttpContext().set(NO_INTERCEPTORS, true)
        }

        return this.http.get<IReferralsCodeResponse>(url, options)
    }
    //#endregion
}
