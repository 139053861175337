import { Action, createReducer, on } from '@ngrx/store';
import { TypesDevices } from './constants/types.constants';
import * as devicesActions from './device.actions';

export interface State {
    deviceType: null | typeof TypesDevices[keyof typeof TypesDevices]; 
    isWebView:boolean;
}

export const initialState: State = {
    deviceType: null,
    isWebView:false
}

const _devicesReducer = createReducer(initialState,

    on(devicesActions.isMobileDevice,   state => ({ ...state, deviceType: TypesDevices.MOBILE})),
    on(devicesActions.isDesktopDevice,  state => ({ ...state, deviceType: TypesDevices.DESKTOP})),
    on(devicesActions.setWebView,  state => ({ ...state,isWebView:true})),
    on(devicesActions.unSetWebView,  state => ({ ...state, isWebView: false})),
);

export function devicesReducer(state: State | undefined, action: Action) {
    return _devicesReducer(state, action);
}