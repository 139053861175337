<section class="container">
    <div class="container-check">
        <p-checkbox #checkbox [ngClass]="{'error': error}" [disabled]="disabled" [binary]="true" [(ngModel)]="isChecked" [label]="label" (onChange)="changeCheck()">
        </p-checkbox>
        <mdk-tooltip [active]="activeTooltip" [description]="descriptionTooltip"></mdk-tooltip>
    </div>

    <a *ngIf="textLink && link" class="label link" target="_blank" href="{{link}}">{{textLink}}</a>
    <a *ngIf="secondTextLink && secondLink" class="label link" target="_blank" href="{{secondLink}}">{{secondTextLink}}</a>
    <span class="label" [ngClass]="{'label-pointer': canCheckOnLabel}" *ngIf="secondLabel" (click)="checkLabel()" [innerHTML]="secondLabel"></span>
</section>


