import { Component, Input, ChangeDetectionStrategy, inject, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { SECOND } from '@shared/models/constants/time.constant';
import { ClipboardService } from 'ngx-clipboard';
import { timer } from 'rxjs';

@Component({
  selector: 'mdk-copy-clipboard',
  templateUrl: './copy-clipboard.component.html',
  styleUrls: ['./copy-clipboard.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CopyClipboardComponent {

  //#region [---- [DEPENDENCIES] ----]
  private readonly cd = inject(ChangeDetectorRef)
  private readonly clipboardService = inject(ClipboardService)
  //#endregion

  //#region [---- [PROPERTIES] ----]
  @Input() label:string;
  @Input() messageToCopy:string;
  @Input() style: string;

  public showCopyMessage:boolean = false
  //#endregion

  //#region [---- [LOGIC] ----]
  public copy(id: string):void{
    this.clipboardService.copyFromContent(id);
    this.showCopyMessage = true
    timer(SECOND * 2).subscribe({
      next: () => {
        this.showCopyMessage = false
        this.cd.detectChanges()
      }
    })
  }
  //#endregion
}
