import { inject, Injectable } from '@angular/core';
import { collection, collectionData, Firestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { IFeature } from '@core/features-flags/models/interfaces/feature-flags.interfaces';
import { keyFeaturesType } from '@core/features-flags/models/types/features-flags-types';

import {  map, Observable, of, ReplaySubject } from 'rxjs';
import { CFeaturesFlagConfigTypes } from 'src/app/configs/features-flags/models/constants/features.configs.constants';
import { featuresDevelopment } from 'src/app/configs/features-flags/features-flag.development.config';
import { featuresProduction } from 'src/app/configs/features-flags/features-flag.production.config';
import { FeaturesFlagsConfigType } from 'src/app/configs/features-flags/models/types/features.config.types';
import { environment } from 'src/environments/environment';
import { CFieldsCollections } from '@core/firebase/constants/firebase.constants';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsManager {

  private _typeConfigEnabled:FeaturesFlagsConfigType = environment.FEATURES_FLAG_TYPE_CONFIGURATION

  //#region [---- DEPENDENCIES ----]
  private readonly firestore:Firestore = inject(Firestore)
  private readonly router: Router = inject(Router)
  //#endregion

  //#region [---- PROPERTIES ----]
  private isProduction:boolean = environment.production
  public features:IFeature[]


  // For Firebase
  private collection = environment.firebase.collectionName
  public featureActive:keyFeaturesType
  public featuresSubject: ReplaySubject<IFeature[]> = new ReplaySubject<IFeature[]>();
  public featuresSubject$ :Observable<IFeature[]> = this.featuresSubject.asObservable()
  //#endregion

  //#region [---- GETTERs & SETTERs ----]

  get getConfigType(): FeaturesFlagsConfigType {
    return this._typeConfigEnabled;
  }

  //#endregion

  //#region [---- LOGIC ----]
  public loadConfig(){
    switch (this._typeConfigEnabled) {
      case CFeaturesFlagConfigTypes.FIREBASE:
        this.getFeaturesConfigByFirebase()
        break;
      case CFeaturesFlagConfigTypes.FILES_CONFIG:
        this.getFeaturesConfigByFile()
        break;

      default:
        break;
    }
  }

  public isFeatureEnabled(key:string):boolean {
    if(!key) return false
    return this.features?.find(feature => feature.key_feature === key)?.isEnabled || false
  }

  public observeFeatureEnabled(key:string): Observable<boolean> {
    if (this._typeConfigEnabled === CFeaturesFlagConfigTypes.FILES_CONFIG)
      return of(this.isFeatureEnabled(key))

    if(!key) return of(false)

    return this.featuresSubject$.pipe(
      map((features:IFeature[])=>{
        const featureFinded = features.find(feature => feature.key_feature === key)
        return featureFinded ? featureFinded.isEnabled : false
      })
    )
  }
  //#endregion

  //#region [---- FIREBASE LOGIC ----]
  private getFeaturesConfigByFirebase():void{
    const featureRef = collection(this.firestore,this.collection)
    const data = collectionData(featureRef,{idField:CFieldsCollections.ID},) as Observable<IFeature[]>

    const subscriptionFirebase = data.subscribe({
      next:(response) => {
        this.features = [...response]
        this.featuresSubject.next(response);
        this.reEvaluationUrl()
       },
       error: () => {
        this.getFeaturesConfigByFile()
        this.reEvaluationUrl()
        subscriptionFirebase.unsubscribe();
       }
    })
  }

  private reEvaluationUrl(): void {
    if (this.featureActive && !this.isFeatureEnabled(this.featureActive)) {
      this.router.navigateByUrl('/parents/home');
    }
  }

  //#endregion

  //#region [---- LOGIC WITH FILES ----]
  private getFeaturesConfigByFile():void {
      this.features = this.isProduction ? featuresProduction : featuresDevelopment
      this.featuresSubject.next(this.features);
  }
  //#endregion

}
