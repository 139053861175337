<!-- Layout principal for users-->
<section [class.layout-parent]="layoutParent$ | async" [class.layout-container]="containerClass$ | async">
  <section class="grid-container separator-container" >
    <!-- menu -->
    <mdk-menu-parent
      *ngIf="menuParents$ | async"
      [showMenu]="true"
      (closeMenu)="showPanelMenuParent = !showPanelMenuParent">
    </mdk-menu-parent>

    <!-- Router -->
    <section class="container-router" [class.container-router-parent]="layoutParent$ | async">
      <router-outlet></router-outlet>
    </section>
  </section>
</section>

<!-- Toast global -->
<!-- REFACTOR: position dynamic with key -->
<p-toast position="top-center" [breakpoints]="{ '920px': { width: '80%' } }"></p-toast>
<p-toast position="bottom-center" [breakpoints]="{ '920px': { width: '80%' } }" [key]="TOAST_KEYS.BOTTOM_CENTER"></p-toast>

<!-- Interceptor -->
<mdk-spinner [interceptor]="inProgress"></mdk-spinner>

<ng-container *ngIf="isWebView$ | async isWebView" >
  <ng-container *ngIf="!isWebView">
    <ng-container *ngIf="showButtonChat$ | async">
      <div mdkFreshChat *ngIf="freshChatEnabled && (isFreshChatTimeOut$ | async) === false; else externalSupport"></div>
      <!-- IOS -->
      <ng-template #externalSupport>
        <div class="chatAlternative" (click)="goExternalSupport()">
          <i class="pi pi-comments icon"></i>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>

