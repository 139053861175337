export const CHANNEL_NAMES = {
    HELP_CENTER: "Help Center ⚙",
    ACHIEVEMENT_CENTER: "App &amp; Achievements 📲",
    CHAT_WITH_US: "Chat with us",
    VOID: "",
} as const

export const FRESH_CHAT_EVENTS = {
    FRESH_CHAT_TIME_OUT : 'fresh_chat_time_out',
} as const;

export const FRESH_CHAT_TIME_OUT_ERROR_MESSAGE = 'Freshchat has taken more than 7 seconds to load.'

export const FRESH_CHAT_DIRECTIVE_TITLE_ERROR = 'Fresh Chat Directive - Chat Service Listen'