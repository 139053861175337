import { Action, createReducer, on } from '@ngrx/store';
import bankingActions from 'src/app/modules/banking/state/banking.actions';
import { BankingState, initialBankingState } from 'src/app/modules/banking/state/banking.state';



const _bankingReducer = createReducer(initialBankingState,
    // Mobucks
    on(bankingActions.setMobucksAccount, (state, {mobucks}) => ({ ...state, mobucks: {...mobucks}})),
    on(bankingActions.unSetMobucksAccount, state => ({ ...state, mobucks: null})),
    on(bankingActions.setMobucksBalance, (state,action) => ({ ...state, mobucks: {...state.mobucks,balance : action.balance}})),

    // Cards
    on(bankingActions.setCardThemes, (state,action) => ({ ...state, cards: {...state.cards,availableThemes : action.themes}}))

);

export function bankingReducer(state: BankingState | undefined, action: Action) {
    return _bankingReducer(state, action);
}