import { CUsersErrorCodes } from "@core/errors/models/constants/codes/users.codes"
import { CErrorContext } from "@core/errors/models/constants/context.constants"
import { CErrorAuthContext } from "@core/errors/models/constants/contexts/auth.context";

export const CUsersErrorMessages = {
    [CUsersErrorCodes.TOKEN_EXPIRED]: {
        [CErrorContext.ADD_TEEN]: ''
    },
    [CUsersErrorCodes.EMAIL_EXISTS]: {
        [CErrorContext.ADD_TEEN]: '',
        [CErrorAuthContext.REGISTER]: "Oops! It seems like there's already an account with this email address. Would you like to try registering with a different email or logging in?"
    },
    [CUsersErrorCodes.PHONE_EXISTS]: {
        [CErrorAuthContext.REGISTER]: "Oops! It looks like there's already an account associated with this phone number. If you'd like, you can try signing up with a different phone number or log in with your existing account."
    },
    [CUsersErrorCodes.REFERRAL_CODE_NOT_FOUND]: {
        [CErrorAuthContext.REGISTER]: "Oops! It appears that the referral code entered is not valid. Please double-check and try again, or continue with the registration process."
    },
    [CUsersErrorCodes.REFERRAL_CODE_DISABLED]: {
        [CErrorAuthContext.REGISTER]: "Referral code disabled. Try with a new code or continue without a code"
    },
    [CUsersErrorCodes.REFERRAL_CODE_EXPIRED]: {
        [CErrorAuthContext.REGISTER]: "Referral code expired. Try with a new code or continue without a code"
    },
    [CUsersErrorCodes.INVALID_CREDENTIALS]: {
        [CErrorAuthContext.VERIFY]: "Oops! Incorrect password. Double-check and try again, or use 'Forgot Password' to reset it"
    },
    [CUsersErrorCodes.INVITATION_CODE_NOT_FOUND]: {
        [CErrorAuthContext.INVITATION]: "Oops! It seems that the invitation could not be located. Please double-check the code and try again, or proceed with the registration process."
    },
    [CUsersErrorCodes.USERS_FAMILY_EMAIL_PARENT]: {
        [CErrorContext.ADD_TEEN]: "You're using a phone and email already registered in a Parent. Try again with your kid's correct info",
    },
    [CUsersErrorCodes.USERS_FAMILY_TEEN_ALREADY_IN_FAMILY]: {
        [CErrorContext.ADD_TEEN]: "Oops! This email and phone number belongs to a Kid with an existing family. Try again with your kid's correct info",
    },
    [CUsersErrorCodes.USERS_FAMILY_EMAIL_PHONE_DONT_MATCH]: {
        [CErrorContext.ADD_TEEN]: "Oops! The email or phone number are already registered. If your Kid already has an account, please double-check and make sure you've entered the correct information",
    },
    [CUsersErrorCodes.USERS_ITEM_NOT_FOUND]: {
        [CErrorContext.PROFILE]: "There was an issue loading your profile. Please contact our support team for assistance. Thank you for your patience!",
    },
} as const;