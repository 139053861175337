import { Action, createReducer, on } from "@ngrx/store";
import * as parentActions from "./parent.actions";
import { IWalletTeen } from "../interfaces/parent.interfaces";
import { IMemberFullData } from "../interfaces/parent.responses";

export interface ParentState {
  isGettingWallets: boolean;
  wallets: IWalletTeen[];
  teens: IMemberFullData[]
}

export const initialState: ParentState = {
  isGettingWallets: false,
  wallets: [],
  teens: []
};

const _parentReducer = createReducer(
  initialState,
  on(parentActions.setIsGettingWallets, (state, { isGetting }) => ({ ...state, isGettingWallets: isGetting })),
  on(parentActions.setTeens, (state, { teens = [] }) => ({ ...state, teens })),
  on(parentActions.setTeenWallets, (state, { wallets = [] }) => ({...state, wallets }))
);

export function parentReducer(state: ParentState | undefined, action: Action) {
  return _parentReducer(state, action);
}
