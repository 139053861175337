import routesActions from '@core/global-state/routes/routes.actions';
import { initialRoutesState, RoutesState } from '@core/global-state/routes/routes.state';
import { Action, createReducer, on } from '@ngrx/store';


const _routesReducer = createReducer(initialRoutesState,
    on(routesActions.setUrlIsPublic, (state, { isUrlPublic }) => ({...state,isUrlPublic})),
);

export function routesReducer(state: RoutesState | undefined, action: Action) {
    return _routesReducer(state, action);
}