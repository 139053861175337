import { Component, Input, OnInit } from '@angular/core';
import { CParentTransactionType, CTransferStatus, ILastTransactionParent } from '../../../pages/parents/interfaces/parent.interfaces';

@Component({
  selector: 'mdk-parent-transaction-item',
  templateUrl: './parent-transaction-item.component.html',
  styleUrls: ['./parent-transaction-item.component.scss']
})
export class ParentTransactionItemComponent implements OnInit {
  @Input() transaction: ILastTransactionParent;
  @Input() showDate: boolean = true;

  feeTransaction: string = CParentTransactionType.FEE;
  creditTransaction: string = CParentTransactionType.CREDIT;
  debitTransaction: string = CParentTransactionType.DEBIT;
  statusComplete: string = CTransferStatus.COMPLETE;

  transactionDate: string;

  ngOnInit(): void {
    this.transactionDate = this.showDate ? this.transaction?.transaction_date_formatted : this.transaction?.transaction_hour;
  }
}
