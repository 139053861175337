import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription, timer } from 'rxjs';
import { SECOND } from 'src/app/shared/models/constants/time.constant';
import { CTypesButtonOption } from '@shared/components/dumb/button/constants/btn-modak.constants';
import { TypeMdkButton } from '@shared/components/dumb/button/interfaces/button.types';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mdk-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class CopyButtonComponent implements OnDestroy {
    @Input() type: TypeMdkButton = CTypesButtonOption.PRIMARY;
    @Input() buttonText: string = "";
    @Input() asCopy: string;
    @Output() clickCopy: EventEmitter<void> = new EventEmitter<void>();

    showCopyMessage:boolean = false;
    private readonly subscription: Subscription = new Subscription();

    constructor(private readonly clipboardService: ClipboardService){}
    
    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }

    copy():void{
      this.clipboardService.copyFromContent(this.asCopy);
      this.showCopyMessage = true;
      const TOTAL_TIME = SECOND * 2
      const sub = timer(TOTAL_TIME).subscribe({
        next: () => {
          this.showCopyMessage = false
        },
      })
      this.subscription.add(sub);
      this.clickCopy.emit();
    }
}
