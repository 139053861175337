import { Directive, inject, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { selectCurrentSize } from '@core/global-state/responsive/responsive.selectors';
import { Store } from '@ngrx/store';
import { responsiveType, SizesDevice } from '@shared/models/constants/global.constants';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[mdkResponsive]'
})
export class ResponsiveDirective implements OnInit,OnDestroy {

  //#region [---- DEPENDENCIES ----]

  private templateRef:TemplateRef<any> = inject(TemplateRef<any>)
  private viewContainer:ViewContainerRef = inject(ViewContainerRef)
  private store:Store = inject(Store)

  //#endregion

  //#region [---- INPUTs & OUTPUTs ----]

  @Input() set mdkResponsive(desiredValue: responsiveType) {
    this.desiredValue = desiredValue || SizesDevice.MOBILE;
    this.updateView();
  }

  //#endregion

  //#region [---- PROPERTIES ----]

  private responsiveSubscription:Subscription
  public desiredValue:responsiveType = SizesDevice.MOBILE
  private stateValue: string;

  //#endregion

  //#region [---- LIFE CYCLES ----]

  ngOnInit(): void {
    this.checkResponsiveState()
  }
  ngOnDestroy(): void {
    if(this.responsiveSubscription) this.responsiveSubscription.unsubscribe()
  }

  //#endregion


  private checkResponsiveState():void{
    this.responsiveSubscription = this.store.select(selectCurrentSize)
    .subscribe((state: responsiveType) => {
      this.stateValue = state;
      this.updateView();
    });
  }

  private updateView() {
    this.stateValue === this.desiredValue ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }

}
