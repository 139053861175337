import { CommonModule } from "@angular/common";
import { AfterContentInit, Component, inject } from "@angular/core";
import { AUTH_EVENTS } from "@pages/auth/auth-parent/models/constants/auth-parent.events.constants";
import { SharedModule } from "@shared/shared.module";
import { PROJECT_TAGS } from "src/app/configs/libraries/tracking-manager/constants/projects.constants";
import { MenuService } from "../../menu.service";
import { AuthFunctions } from "@pages/auth/auth.functions";
import { TrackingManager } from "src/app/configs/libraries/tracking-manager/tracking-manager";
import { Router } from "@angular/router";
import { IMenuParentAccount } from "./models/interfaces/menu-parent-account.interfaces";
import {
  CMenuParentAccountActions,
  CMenuParentAccountIcons,
} from "./models/constants/menu-parent-account.constants";
import { TranslateService } from "@ngx-translate/core";
import { CMenuParentAccountAction } from "./models/types/menu-parent-account.types";

@Component({
  selector: "mdk-menu-parent-account",
  templateUrl: "./menu-parent-account.component.html",
  styleUrls: ["./styles/menu-parent-account.component.mobile.scss"],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class MenuParentAccountComponent implements AfterContentInit {

  //#region [---- [DEPENDENCIES] ----]
  private readonly authFunctions: AuthFunctions = inject(AuthFunctions);
  private readonly menuService: MenuService = inject(MenuService);
  private readonly trackingManager: TrackingManager = inject(TrackingManager);
  private readonly route: Router = inject(Router);
  private readonly translate: TranslateService = inject(TranslateService);
  //#endregion

  //#region [---- [PROPERTIES] ----]
  public menuList: IMenuParentAccount[];
  //#endregion

  //#region [---- [HOOKS] ----]
  ngAfterContentInit(): void {
    this.menuList = [
      {
        name: this.translate.instant("PARENT_PORTAL.SETTINGS.SECTION.ITEMS.TITLES.ACCOUNT_DETAILS"),
        icon: CMenuParentAccountIcons.ACCOUNT_DETAILS,
        action: CMenuParentAccountActions.ACCOUNT_DETAILS,
      },
      {
        name: this.translate.instant("PARENT_PORTAL.SETTINGS.TITLE"),
        icon: CMenuParentAccountIcons.SETTINGS,
        action: CMenuParentAccountActions.SETTINGS,
      },
      {
        name: this.translate.instant("PARENT_PORTAL.SETTINGS.SECTION.ITEMS.TITLES.LOG_OUT"),
        icon: CMenuParentAccountIcons.LOGOUT,
        action: CMenuParentAccountActions.LOGOUT,
      },
    ];
  }
  //#endregion

  //#region [---- [LOGIC] ----]
  public itemClick(action: CMenuParentAccountAction): void {
    switch (action) {
      case CMenuParentAccountActions.ACCOUNT_DETAILS:
        this.goToAccountDetails();
        break;
      case CMenuParentAccountActions.SETTINGS:
        this.goToSettings();
        break;
      case CMenuParentAccountActions.LOGOUT:
        this.logout();
        break;
    }
  }

  private goToAccountDetails(): void {
    this.route.navigateByUrl("/parents/settings/account-details");
  }

  private goToSettings(): void {
    this.route.navigateByUrl("/parents/settings");
  }

  private logout(): void {
    this.trackingManager.trackEventV2(AUTH_EVENTS.SELECT_PARENT_LOGOUT_SUCCESS, {
      project_tag: PROJECT_TAGS.ACCOUNT_ACCESS
    });
    this.menuService.removeLinks();
    this.authFunctions.logout(false, true);
  }
  //#endregion
}
