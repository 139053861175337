export const PASSWORD_SERVICES_EVENTS = {
  API_CHANGE_PASSWORD_SUCCESS: "api_change_password_success",
  API_CHANGE_PASSWORD_ERROR: "api_change_password_error",
  API_RESET_PASSWORD_SUCCESS: "api_reset_password_success",
  API_RESET_PASSWORD_ERROR: "api_reset_password_error",
  API_FORGOT_PASSWORD_SUCCESS: "api_forgot_password_success",
  API_FORGOT_PASSWORD_ERROR: "api_forgot_password_error",
  API_SET_UP_PASSWORD_SUCCESS: "api_set_up_password_success",
  API_SET_UP_PASSWORD_ERROR: "api_set_up_password_error",
  API_CHANGE_PASSWORD_SEND_CODE_VERIFY_SUCCESS: "api_change_password_send_code_verify_success",
  API_CHANGE_PASSWORD_SEND_CODE_VERIFY_ERROR: "api_change_password_send_code_verify_error"
} as const;
