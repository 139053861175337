import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "replacementInfo",
})
export class ReplacementPipe implements PipeTransform {
    transform(value: string, money:number, to:string): string {
        return value.replace(/\$money/g, `\$${money}`).replace(/\$to/g, this.capitalize(to))
    }

    private capitalize(str: string = ''): string {
        if (str.length > 1) {
            return str[0]?.toUpperCase() + str.slice(1)
        }

        return str.toUpperCase()
    }
}