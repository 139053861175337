export const SEND_MONEY_EVENTS = {
    VIEW_PARENT_START_SEND_MONEY : 'view_parent_start_send_money',
    SELECT_PARENT_START_SEND_MONEY : 'select_parent_start_send_money',
    SELECT_PARENT_LINK_BANK_ACCOUNT : 'select_parent_link_bank_account',
    API_LINK_BANK_ACCOUNT_ERROR: 'api_link_bank_account_error',
    SELECT_PARENT_LINK_BANK_ACCOUNT_SUCCESS : 'select_parent_link_bank_account_success',
    VIEW_PARENT_LINK_BANK_ACCOUNT_ERROR : 'view_parent_link_bank_account_error',
    SELECT_PARENT_LINKED_BANK_ACCOUNT : 'select_parent_linked_bank_account',
    SELECT_PARENT_LINKED_DEBIT_CARD : 'select_parent_linked_debit_card',
    SELECT_PARENT_SEND_MONEY_AMOUNT : 'select_parenthome_send_money_amount',
    SELECT_PARENT_TRANSFER_OPTION : 'select_parent_transfer_option',
    SELECT_PARENT_TRANSFER_CONFIRM : 'select_parent_transfer_confirm',
    VIEW_PARENT_TRANSFER_CONFIRMED : 'view_parent_transfer_confirmed',
    VIEW_PARENT_TRANSFER_FAILED : 'view_parent_transfer_failed',
    VIEW_PLAID_REDIRECTION: 'view_plaid_redirection',
    LOG_PLAID_CONFIRMATION: 'log_plaid_confirmation',
    SELECT_PLAID_EXIT: 'select_plaid_exit',
    VIEW_PLAID_ERROR: 'view_plaid_error',
    SELECT_PARENT_LINK_NEW_DEBIT_CARD: 'select_parent_link_new_debit_card',
    SELECT_LINKED_ACCOUNTS_DIRECT_TRANSFER: 'select_linked_accounts_direct_transfer',
    API_GET_DIRECT_TRANSFER_DETAILS_SUCCESS: 'api_get_direct_transfer_details_success',
    API_GET_DIRECT_TRANSFER_DETAILS_ERROR: 'api_get_direct_transfer_details_error',
    API_CREATE_CONTACT_LINK_DEBIT_CARD_SUCCESS: 'api_create_contact_link_debit_card_success',
    API_CREATE_CONTACT_LINK_DEBIT_CARD_ERROR: 'api_create_contact_link_debit_card_error',
    API_CREATE_CONTACT_LINK_DEBIT_CARD_WITHOUT_RESPONSE: 'api_create_contact_link_debit_card_without_response',
    VIEW_PARENT_NEW_DEBIT_CARD_LINKED: 'view_parent_new_debit_card_linked',
    VIEW_PARENT_NEW_DEBIT_CARD_ERROR_LINKING: 'view_parent_new_debit_card_error_linking',
    SELECT_TEEN_DASHBOARD_ACCOUNT_DETAILS: 'select_teen_dashboard_account_details',
    SELECT_DIRECT_TRANSFER_ROUTING_NUMBER: 'select_directtransfer_routing_number',
    SELECT_DIRECT_TRANSFER_ACCOUNT_NUMBER: 'select_directtransfer_account_number',
    SELECT_ACCOUNT_DETAILS_BANK: 'select_account_details_bank',
    SELECT_ACCOUNT_DETAILS_PERSONAL: 'select_account_details_personal',
    API_FUNDING_SUCCESS: 'api_funding_success',
    API_FUNDING_ERROR: 'api_funding_error',
} as const;