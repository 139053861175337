import { Injectable } from '@angular/core';
import { C_REDIRECTION_MANAGER_KEYS } from '@core/redirection/models/constants/redirection.manager.constants';
import { IQueryParams, IRedirectElement } from '@core/redirection/models/interface/redirection.manager.interface';

@Injectable({
  providedIn: 'root'
})
export class RedirectionManager {

  //#region [---- PROPERTIES ----]

  private currentRedirection:IRedirectElement = {
    isActivate:false,
    urlRedirect:''
  }

  //#endregion

  //#region [---- LOGIC ----]

  public manageRedirection(url:string,queryParams:IQueryParams){
    const keys:string[] = Object.keys(queryParams)
    this.manageRedirectionByCase(keys,url)
  }

  private manageRedirectionByCase(keys:string[],url:string){
    if(keys.includes(C_REDIRECTION_MANAGER_KEYS.LIST_CHORES_EMAIL)){
      this.currentRedirection.isActivate = true
      this.currentRedirection.urlRedirect = url
    }
  }

 public getUrlRedirection():string{
    return this.currentRedirection.isActivate ? this.currentRedirection.urlRedirect : ''
 }

 public cleanRedirection():void{
  this.currentRedirection.isActivate = false
  this.currentRedirection.urlRedirect = ''
 }

  //#endregion


}
