// Angular Imports
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Another modules
import { DirectivesModule } from "../directives/directives.module";
import { FeatureFlagsModule } from "@core/features-flags/feature-flags.module"; //CHECK_THIS: why exists this here?
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { LottieModule } from "ngx-lottie";
import { PipesModule } from "../pipes/pipes.module";
import { PrimeNgModule } from "../primeng/primeng.module";
import { StaticServeModule } from "@core/static-serve/static-serve.module";
import { TranslateModule } from "@ngx-translate/core";

// Components
import { AgreementComponent } from "./agreement/agreement.component";
import { AmountComponent } from "./amount/amount.component";
import { AppStoreButtonComponent } from "./app-store-button/app-store-button.component";
import { BackButtonBannerComponent } from "./back-button-banner/back-button-banner.component";
import { BtnModakComponent } from "@shared/components/dumb/button/button.component";
import { CardExampleComponent } from "./card-example/card-example.component";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { CopyButtonComponent } from "@shared/components/copy-button/copy-button.component";
import { CopyClipboardComponent } from "./copy-clipboard/copy-clipboard.component";
import { CountryPhoneInputComponent } from "./dumb/country-phone-input/country-phone-input.component";
import { FloatTextLabelComponent } from "./float-text-label/float-text-label.component";
import { IconProfileComponent } from "./icon-profile/icon-profile.component";
import { InfoComponent } from "./info/info.component";
import { InputModakComponent } from "@shared/components/dumb/input/input.component";
import { LatestTransactionsComponent } from "./latest-transactions/latest-transactions.component";
import { ModalComponent } from "@shared/components/modal/modal.component";
import { ParentTransactionItemComponent } from "./parent-transaction-item/parent-transaction-item.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { TeenTransactionItemComponent } from "./teen-transaction-item/teen-transaction-item.component";
import { TipFinantialComponent } from "./tip-finantial/tip-finantial.component";
import { TooltipModalComponent } from "./tooltip-modal/tooltip-modal.component";
import { TransactionDetailPropertyComponent } from "./transaction-detail/components/dumb/transaction-detail-property/transaction-detail-property.component";
import { UserTitleParentPortalComponent } from "./user-title-parent-portal/user-title-parent-portal.component";

import player from "lottie-web";
import { BackButtonComponent } from "@shared/components/dumb/back-button/back-button.component";
import { FeatureCardComponent } from "./dumb/feature-card/feature-card.component";
import { TransactionBadgeStateComponent } from "./dumb/transaction-badge-state/transaction-badge-state.component";
import { NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { ChangeMoCardDesignComponent } from './dumb/change-mo-card-design/change-mo-card-design.component';
import { TooltipComponent } from "@shared/components/dumb/tooltip/tooltip.component";
import { ConfettiAnimationComponent } from './dumb/confetti-animation/confetti-animation.component';

//CHECK_THIS: should be here?
export function playerFactory() {
  return player;
}

const components = [
  AgreementComponent,
  AmountComponent,
  AppStoreButtonComponent,
  BackButtonBannerComponent,
  ConfirmModalComponent,
  CopyClipboardComponent,
  CountryPhoneInputComponent,
  FloatTextLabelComponent,
  IconProfileComponent,
  InfoComponent,
  InputModakComponent,
  LatestTransactionsComponent,
  ParentTransactionItemComponent,
  SpinnerComponent,
  TeenTransactionItemComponent,
  TooltipModalComponent,
  UserTitleParentPortalComponent,
  ConfettiAnimationComponent,
];

//CHECK_THIS: should be not here
const standAloneComponents = [
  TipFinantialComponent,
  BtnModakComponent,
  ModalComponent,
  CopyButtonComponent,
  TransactionDetailPropertyComponent,
  CardExampleComponent,
  BackButtonComponent,
  FeatureCardComponent,
  TransactionBadgeStateComponent,
  ChangeMoCardDesignComponent,
  TooltipComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    TranslateModule,
    StaticServeModule,
    GooglePlaceModule,
    DirectivesModule,
    PipesModule,
    NgOptimizedImage,
    FeatureFlagsModule,
    CardExampleComponent,
    TipFinantialComponent,
    ModalComponent,
    BtnModakComponent,
    CopyButtonComponent,
    NgxMaskPipe,
    LottieModule.forRoot({
      player: playerFactory,
    }),
    ...standAloneComponents,
  ],
  providers: [provideNgxMask()],
  declarations: [...components],
  exports: [...components, ...standAloneComponents],
})
export class ComponentsModule {}
