import { Injectable } from "@angular/core";
import { Roles } from "@pages/auth/auth-parent/types/auth.types";
import { CTokenDecoded, RolesAccount } from "@pages/auth/interfaces/auth.constant";
import { ITokenDecoded, ITokenDecodedUser } from "@pages/auth/interfaces/auth.interfaces";
import jwt_decode from "jwt-decode";

@Injectable({
    providedIn: 'root'
  })
  export class AuthUtils {

    public getTokenDecoded(token: string): ITokenDecoded | null {
        if (!token) return null;
        try {
            const tokenDecoded: ITokenDecoded = jwt_decode(token);
            return tokenDecoded ? tokenDecoded : null;
        } catch(error) {
            return null;
        }
    }

    public getTokenDecodedUser(token: string, defaultRole?: Roles): ITokenDecodedUser {
        if(!defaultRole) defaultRole = RolesAccount.PARENT
        const tokenDecoded = this.getTokenDecoded(token)
        if(!tokenDecoded) return null
        const userId = tokenDecoded[CTokenDecoded.CUSTOM_USER_UUID] ?? '';
        const role = tokenDecoded[CTokenDecoded.CUSTOM_ROLES] ?? defaultRole
        return {
            ...tokenDecoded,
            user_uuid: userId,
            role: role
        }
    }
  }