export const LINKS = {
  MODAK_MAKERS_LEGAL: 'https://www.modakmakers.com/legal',
  MODAK_SUPPORT: 'https://www.modakmakers.com/support',
  PROMOS: "https://www.modakmakers.com/promos",
  MODAK_MOBILE_STORE: "https://modak.go.link?adj_t=16ujgqll_163olcxq",
  MODAK_DOWNLOAD_PARENT_APP: "https://modak.go.link/?adj_t=1311mi7i_13qgp2xr",
  PRIVACY_POLICY: "https://www.modakmakers.com/legal/privacy",
  E_SIGNATURE_AGREEMENT: "https://www.modakmakers.com/legal/eca",
  CONSUMER_ACCOUNT_CARD_HOLDER_AGREEMENT: "https://www.modakmakers.com/legal/lcb-agreement",
  PROMO_WELCOME_BONUS: "https://www.modakmakers.com/promos/terms-and-conditions-welcome-bonus",
} as const