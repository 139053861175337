import { Component, Input, OnInit, inject } from "@angular/core";
import { ParentsFunctions } from "@pages/parents/parents.functions";
import {
  CParentTransactionType,
  CTransferStatus,
  ILastTransactionParent,
} from "src/app/pages/parents/interfaces/parent.interfaces";

@Component({
  selector: "mdk-teen-transaction-item",
  templateUrl: "./teen-transaction-item.component.html",
  styleUrls: ["./teen-transaction-item.component.scss"],
})
export class TeenTransactionItemComponent implements OnInit {

  //#region [---- [DEPENDENCIES] ----]
  private parentsFunctions: ParentsFunctions = inject(ParentsFunctions);
  //#endregion

  //#region [---- [INPUTS & OUTPUTS] ----]
  @Input() transaction: ILastTransactionParent;
  @Input() showDate: boolean = true;
  //#endregion

  //#region [---- [PROPERTIES] ----]
  feeTransaction: string = CParentTransactionType.FEE;
  creditTransaction: string = CParentTransactionType.CREDIT;
  debitTransaction: string = CParentTransactionType.DEBIT;
  refundTransaction: string = CParentTransactionType.REFUND;
  statusComplete: string = CTransferStatus.COMPLETE;
  transactionDate: string;
  //#endregion

  //#region [---- [LIFE CYCLES] ----]
  ngOnInit(): void {
    this.transactionDate = this.showDate
      ? this.transaction?.transaction_date_formatted
      : this.transaction?.transaction_hour;
  }
  //#endregion
}
