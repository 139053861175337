import { CHANGE_PASSWORD_EVENTS } from "./change-password.events";
import { FORGOT_PASSWORD_EVENTS } from "./forgot-password.events";
import { PASSWORD_STATE_EVENTS } from "./password-state.events";
import { RESET_PASSWORD_EVENTS } from "./reset-password.events";
import { PASSWORD_SERVICES_EVENTS } from "./services.events";
import { SET_UP_PASSWORD_EVENTS } from "./set-up-password.events";

export const PASSWORD_EVENTS = {
    ...PASSWORD_SERVICES_EVENTS,
    ...PASSWORD_STATE_EVENTS,
    ...CHANGE_PASSWORD_EVENTS,
    ...FORGOT_PASSWORD_EVENTS,
    ...RESET_PASSWORD_EVENTS,
    ...SET_UP_PASSWORD_EVENTS
} as const;