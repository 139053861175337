import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { LogsManager } from "@core/logs/logs.manager";
import { DeviceDetectorService } from "ngx-device-detector";
import { DeviceSO } from "src/app/configs/libraries/ngx-device-detector/constants/devices.constants";
import {
  CButtonTimer,
  CPositionButtonOption,
  CTypesButtonOption,
} from "./constants/btn-modak.constants";
import { TypeMdkButton } from "./interfaces/button.types";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { ILogData } from "@core/logs/models/interface/error.interface";
import { StaticServeModule } from "@core/static-serve/static-serve.module";
import { PrimeNgModule } from "@shared/primeng/primeng.module";

@Component({
  selector: "mdk-btn",
  templateUrl: "./button.component.html",
  styleUrls: [
    "./styles/button.component.mobile.scss",
    "./styles/button.component.desktop.scss",
  ],
  standalone: true,
  imports: [CommonModule, StaticServeModule, NgOptimizedImage, PrimeNgModule],
})
export class BtnModakComponent {
  //#region  [---- [DEPENDENCIES] ----]
  private detectorDevice: DeviceDetectorService = inject(DeviceDetectorService);
  private logManager: LogsManager = inject(LogsManager);
  //#endregion

  //#region [---- [INPUT & OUTPUTS] ----]
  @Input() type: TypeMdkButton = CTypesButtonOption.PRIMARY;
  @Input() buttonText: string;
  @Input() buttonIcon: string;
  @Input() buttonSrc: string;
  @Input() textPosition: string = CPositionButtonOption.RIGHT;
  @Input() disabled: boolean = false;
  @Input() autoWidth: boolean = false;
  @Input() removePadding: boolean = false;
  @Input() withBorder: boolean = false;
  @Input() overlay: boolean = true;
  @Input() contextViewForLog?: string;
  @Input() customPadding: string;
  @Input() customWidth: string;
  @Input() enableTimerOnClick?: boolean = false;
  @Input() mobileStickyStyle: boolean = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
  //#endregion

  //#region  [---- [PROPERTIES] ----]
  public readonly textButtonRight = CPositionButtonOption.RIGHT;
  private isIOS: boolean;
  private isMobileDevice: boolean;
  private isMobileAndIOS: boolean;
  private touchStarted = false;
  private touchMoved = false;
  public uniqueId: string;
  public buttonTimerOnClickDisabled: boolean = false;
  //#endregion

  //#region [---- [HOOKS] ----]
  constructor() {
    this.uniqueId = `QA_BUTTON_COMPONENT`;
  }
  //#endregion

  //#region [---- [LOGIC] ----]
  private isMobileIOS(): boolean {
    this.isIOS = this.detectorDevice.os === DeviceSO.IOS;
    this.isMobileDevice = this.detectorDevice.isMobile();
    this.isMobileAndIOS = this.isIOS && this.isMobileDevice;
    return this.isMobileAndIOS;
  }
  //#endregion

  //#region [---- [EVENTS COMPONENTS] ----]
  public onClick() {
    if (this.enableTimerOnClick && this.isMobileIOS()) return;

    if (this.enableTimerOnClick){
      this.emitWithTimerActivated()
      return;
    }

    if (!this.isMobileIOS()) {
      if (this.contextViewForLog) this.toLog(this.contextViewForLog, "CLICK");
      this.buttonClick.emit();
    }
  }

  public onTouchStart() {
    if (this.isMobileIOS()) this.touchStarted = true;
  }

  public onTouchMove() {
    if (this.isMobileIOS()) this.touchMoved = true;
  }

  public onTouchEnd() {
    if (this.isMobileIOS() && this.touchStarted && !this.touchMoved) {
      if (this.contextViewForLog) this.toLog(this.contextViewForLog, "TOUCH");
      if (this.enableTimerOnClick) {
        this.emitWithTimerActivated()
         return;
        }

      this.buttonClick.emit();
    }

    this.touchStarted = false;
    this.touchMoved = false;
    return;
  }

  private toLog(contextView: string, contextEvent: string): void {
    const message = `${contextView}-${contextEvent}`;
    const event = {
      os: this.detectorDevice.os,
      deviceType: this.detectorDevice.deviceType,
      browser: this.detectorDevice.browser,
    };

    const log: ILogData = {
      message,
      data: event,
    };

    this.logManager.log(log);
  }

  private emitWithTimerActivated(): void {
      this.buttonClick.emit();
      this.buttonTimerOnClickDisabled = true;
      setTimeout(() => {
        this.buttonTimerOnClickDisabled = false;
      }, CButtonTimer.TIME);
  }
  //#endregion
}
