import { Action, createReducer, on } from '@ngrx/store';
import { responsiveType, SizesDevice } from '@shared/models/constants/global.constants';
import { isMobile,isDesktop } from './responsive.actions';

export interface ResponsiveState {
    sizeDevice: null | responsiveType; 
}

export const initialState: ResponsiveState = {
    sizeDevice: null,
}

const _responsiveReducer = createReducer(initialState,

    on(isMobile,   state => ({ ...state, sizeDevice: SizesDevice.MOBILE})),
    on(isDesktop,  state => ({ ...state, sizeDevice: SizesDevice.DESKTOP})),

);

export function responsiveReducer(state: ResponsiveState | undefined, action: Action) {
    return _responsiveReducer(state, action);
}