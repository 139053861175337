import { Component, Input } from '@angular/core';
import { IMenuItem } from '../../models/interfaces/menu.interfaces';

@Component({
  selector: 'mdk-menu-parent-item',
  templateUrl: './menu-parent-item.component.html',
  styleUrls: ['./menu-parent-item.component.scss']
})
export class MenuParentItemComponent {

  @Input() item:IMenuItem
}
