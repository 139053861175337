<section class="container-spinner" *ngIf="manual || interceptor" [ngClass]="{ manual: manual, withoutBackground: withoutBackground }">
  <ng-container *ngIf="!isModal; else modalLoader">
    <ng-lottie class="mdk-lottie" [options]="lottieConfig" />

    <div class="message" *ngIf="inputMessage">
      <label class="text"> {{ inputMessage }} </label>
    </div>
  </ng-container>

  <ng-template #modalLoader>
    <section class="modal">
      <ng-lottie class="mdk-lottie" [options]="lottieConfig" />

      <div class="message" *ngIf="inputMessage">
        <label class="text"> {{ inputMessage }} </label>
      </div>
    </section>
  </ng-template>
</section>
