<span class="status" [ngSwitch]="state" [ngClass]="{
        'completed': ALL_STATES.SUCCESSFUL === state,
        'processed': ALL_STATES.PROCESSING === state,
        'failed': ALL_STATES.FAILED === state
    }">
    <ng-container class="title-transfer" *ngSwitchCase="ALL_STATES.SUCCESSFUL">
        {{ 'COMPONENTS.BADGE_TRANSACTION_STATE.STATUS_COMPLETED' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="ALL_STATES.PROCESSING">
        {{ 'COMPONENTS.BADGE_TRANSACTION_STATE.STATUS_PROCESSED' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="ALL_STATES.FAILED">
        {{ 'COMPONENTS.BADGE_TRANSACTION_STATE.STATUS_NOT_PROCESSED' | translate }}
    </ng-container>
</span>