import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router } from "@angular/router";
import { CPaymentLinkOutcomeQueryParams } from "@pages/parents/pages/banking/funds/pages/payment-link-outcome/models/constants/payment-link-outcome.constants";

export const AliasPaymentLinkRedirectGuard: CanActivateFn = (_routeActive: ActivatedRouteSnapshot) => {
  //#region [---- DEPENDENCIES ----]
  const router: Router = inject(Router);
  //#endregion

  const linkId = _routeActive.paramMap.get('link-id');

  const destinationRoute = linkId
  ? ['parents/banking/funds/payment-link', linkId]
  : [''];

  router.navigate(destinationRoute);

  return !linkId;
}

export const AliasPaymentLinkOutcomeRedirectGuard: CanActivateFn = (_routeActive: ActivatedRouteSnapshot) => {
  //#region [---- DEPENDENCIES ----]
  const router: Router = inject(Router);
  //#endregion

  const params = _routeActive.queryParams;

  const paramID = CPaymentLinkOutcomeQueryParams.ID
  const paramSinglePayment = CPaymentLinkOutcomeQueryParams.SINGLE_PAYMENT

  if (!(paramID in params))
    return false;
  
  const transactionId = params[paramID];
  const single_payment = params[paramSinglePayment];
  const singlePaymentValue = single_payment ?? false

  const pageUrl = `parents/banking/funds/payment-link-outcome`
  const queryParams = `${paramID}=${transactionId}&${paramSinglePayment}=${singlePaymentValue}`

  router.navigateByUrl(`${pageUrl}?${queryParams}`);

  return true;
}