import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutManager {

  //#region [---- PROPERTIES ----]
  public containerClass: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public layoutParent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showHeaderComponent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showParentFooterComponent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showPanelMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAdminMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isParentMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showChatButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  //#endregion

  //#region [---- [LOGIC] ----]
  public disableAllComponents(): void {
    this.showPanelMenu.next(false);
    this.showHeaderComponent.next(false);
    this.containerClass.next(false);
    this.isParentMenu.next(false);
    this.layoutParent.next(false);
  }

  public enableAllComponents(): void {
    this.showPanelMenu.next(true);
    this.showHeaderComponent.next(true);
    this.containerClass.next(true);
  }
  
  public toggleLayoutParent(value:boolean): void {
    this.layoutParent.next(value)
  }

  public toggleLayoutTeen(value:boolean): void {
    this.containerClass.next(value);
  }

  public toggleMenuComponent(value:boolean): void {
    this.showPanelMenu.next(value);
  }

  public toggleParentMenuComponent(value:boolean): void {
    this.isParentMenu.next(value);
  }

  public toggleParentFooterComponent(value:boolean): void {
    this.showParentFooterComponent.next(value);
  }

  public toggleAdminMenu(value:boolean): void {
    this.isAdminMenu.next(value);
  }

  public toggleHeaderComponent(value:boolean): void {
    this.showHeaderComponent.next(value);
  }

  public toggleChatButton(value:boolean): void {
    this.showChatButton.next(value)
  }
  //#endregion
}
