import { AUTH_PARENT_QUERY_PARAMS } from "@pages/auth/auth-parent/models/constants/auth-parent.queryparams.constants";

export const AUTH_PARENT_ROUTES = {
    ROOT: '/auth/parents'
} as const;

export const AUTH_PARENT_FULL_ROUTES = {
    SIGN_UP: `${AUTH_PARENT_ROUTES.ROOT}?${AUTH_PARENT_QUERY_PARAMS.SIGN_UP}=true`,
    SIGN_IN_PARENT: `${AUTH_PARENT_ROUTES.ROOT}?${AUTH_PARENT_QUERY_PARAMS.SIGN_IN_PARENT}=true`,
    SIGN_IN_TEEN: `${AUTH_PARENT_ROUTES.ROOT}?${AUTH_PARENT_QUERY_PARAMS.SIGN_IN_TEEN}=true`,
    PROTECTION_IS_HERE: `${AUTH_PARENT_ROUTES.ROOT}/password/protection-is-here`
} as const;