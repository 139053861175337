import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DialogModule } from "primeng/dialog";

@Component({
    selector: 'mdk-modal',
    templateUrl: './modal.component.html',
    styleUrls: [
        './styles/modal.component.mobile.scss',
        './styles/modal.component.desktop.scss',
    ],
    standalone: true,
    imports: [DialogModule]
})
export class ModalComponent {
    @Input() header:string = 'false';
    @Input() display:boolean = false;
    @Input() closable: boolean = true;
    @Input() closeWhenClickOutside: boolean = false;
    @Input() styleClass?:string

    @Output() hide: EventEmitter<boolean> = new EventEmitter();
    
    public onHideModal(): void {
        this.display = false;
        this.hide.emit(false);
    }

}