import { Metadata } from '@core/metadata/models/types/metadata.types';
import { createAction,props } from '@ngrx/store';
import { CStatusKYC } from '@pages/parents/pages/onboarding/pages/kyc/models/constants/kyc.constants';
import { IUser } from 'src/app/pages/auth/interfaces/auth.interfaces';

// User actions
export const setUser = createAction('[Authentication] setUser',props<{authentication:IUser}>());

export const setNotifyUser = createAction('[Notify] setNotifyUser',props<{ notify_me:boolean }>());

export const unSetUser = createAction('[Authentication] unSetUser')
export const setAccessToken = createAction('[Authentication] setAccessToken',props<{token:string}>());
export const refreshAccessToken = createAction('[Authentication] refreshAccessToken',props<{newToken:string}>());
export const setMetadataUser = createAction('[Authentication] setMetadataUser',props<{metadata:Metadata}>());
export const setCreatedAtUser = createAction('Authentication',props<{date:Date}>());

// KYC Actions
export const setKycStatus = createAction('[Authentication] setKycStatus',props<{status:typeof CStatusKYC[keyof typeof CStatusKYC]}>());

