import { inject, Injectable } from '@angular/core';
import { CanActivate, CanMatch, Router, UrlTree } from '@angular/router';
import { GlobalState } from '@core/global-state/app.reducer';
import { Store } from '@ngrx/store';
import { RolesAccount } from '@pages/auth/interfaces/auth.constant';
import { selectUser } from '@pages/auth/state/authentication.selectors';
import { first, map, Observable, tap } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PublicGuard implements CanActivate, CanMatch {

  //#region [---- DEPENDENCIES ----]

  private readonly router:Router = inject(Router)
  private store: Store<GlobalState> = inject(Store<GlobalState>)

  //#endregion

  //#region [---- METHODS GUARDS ----]

  canActivate(): Observable<boolean | UrlTree>{
    return this.checkLogged()
  }
  canMatch(): Observable<boolean | UrlTree> {
    return this.checkLogged()
  }

  //#endregion

  //#region [---- LOGIC ----]

  private checkLogged():Observable<boolean>{
    return this.store.select(selectUser).pipe(
      first(),
      tap(user => {
        if(user?.accessToken) this.router.navigateByUrl(this.getHomeUrlByRole(user?.role));
      }),
      map(user => !user?.accessToken)
    )
  }

  private getHomeUrlByRole(role: string = ""): string {
    const roleToUrl = {
      [RolesAccount.PARENT]: "/parents/home",
      [RolesAccount.TEEN]: "/home",
      'default': '/home'
    }
    return roleToUrl[role] || roleToUrl.default;
  }

  //#endregion


}
