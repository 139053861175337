import { Action, createReducer, on } from "@ngrx/store";
import * as referralActions from "./referrals.actions";
import { ReferralState, initialReferralState } from "@pages/parents/modules/referrals/state/referrals.state";

const _referralsReducer = createReducer(
    initialReferralState,
    on(referralActions.setReferrals, (state, { reward, code }) => ({ ...state, reward: reward, code: code})),
  );
  
  export function referralsReducer(state: ReferralState | undefined, action: Action) {
    return _referralsReducer(state, action);
  }