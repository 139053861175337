export const SizesDevice = {
    MOBILE: 'MOBILE',
    DESKTOP: 'DESKTOP'
} as const;

export type responsiveType = typeof SizesDevice[keyof typeof SizesDevice]


export const CFeatureIntroductionKey ={
    INTRO_SAVINGS: 'intro_savings'
} as const
