
import { IFeature } from "@core/features-flags/models/interfaces/feature-flags.interfaces";
import { CFeatures } from "@core/features-flags/models/constants/features-flags.constants";

// try to keep the alphabetic order

export const featuresProduction: IFeature[] = [
    {
        key_feature: CFeatures.AVS_CHECK,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.BANK_ACCOUNT,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.CARDS,
        isEnabled: false,
    },
    {
        key_feature: CFeatures.CHECKOUT,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.CHECKOUT_CARDS,
        isEnabled: false,
    },
    {
        key_feature: CFeatures.CHECKOUT_FRAUD,
        isEnabled: false,
    },
    {
        key_feature: CFeatures.CHORES,
        isEnabled: false,
    },
    {
        key_feature: CFeatures.DIRECT_TRANSFER,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.FUNDING_CAMPAIGN,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.ONBOARDING,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.PARENT_BALANCE,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.PARENT_SETTINGS,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.PASSWORD,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.PAYMENT_LINK,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.REFERRALS,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.REQUEST_CARD,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.SAVINGS,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.SEND_MONEY,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.SIGN_UP,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.SUPPORT_CHAT,
        isEnabled: false,
    },
    {
        key_feature: CFeatures.VGS_DEBIT_PULL,
        isEnabled: false,
    },
    {
        key_feature: CFeatures.WAF_PROTECTION,
        isEnabled: true,
    },
    {
        key_feature: CFeatures.NEW_AUTHENTICATION,
        isEnabled: false,
    },
    {
        key_feature: CFeatures.AUTHENTICATION_V3,
        isEnabled: true,
    },
];
