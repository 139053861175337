import { NgModule } from '@angular/core';

// Directives
import { CommonModule } from '@angular/common';
import { NoRightClickDirective } from './no-right-click.directive';
import { NoTranslateDirective } from './no-translate.directive';
import { ResponsiveDirective } from './responsive.directive';
import { GooglePlacesModakDirective } from '@shared/components/dumb/input-search-address/google-places-modak.directive';
import { ViewportDirective } from './viewport.directive';
import { ChangeColorSelectedDirective } from './change-color-selected.directive';



const directives = [
  GooglePlacesModakDirective,
  NoRightClickDirective,
  NoTranslateDirective,
  ResponsiveDirective,
  ViewportDirective,
  ChangeColorSelectedDirective,
]

@NgModule({
  imports: [CommonModule],
  declarations: [...directives],
  exports: [...directives]
})
export class DirectivesModule { }
