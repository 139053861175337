<section class="container" *ngIf="showMenu" [ngClass]="{ background: (showPanelMenu && isMobile) || (showAccountMenu && isMobile) }"
  (click)="handleContainerClick($event)">
  <section class="header-menu-container" [ngClass]="{ 'header-menu-showed': (showPanelMenu && isMobile) || (showAccountMenu && isMobile) }">
    <i class="pi pi-bars icon actionIcon" *ngIf="isMobile" (click)="showPanelMenu = !showPanelMenu"></i>

    <!-- Logo modak text -->
    <figure class="logo-container" routerLink="/parents/home">
      <img
        class="logo-text"
        [ngSrc]="'logo-header-light.svg' | staticServe"
        width="100"
        height="100"
        alt="" />
    </figure>

    <section class="actions-container" *ngIf="isMobile">
      <ng-container *featureFlags="referralsFeatureFlag">
        <mdk-menu-parent-referral-button
          [showButtonText]="false"
        />
      </ng-container>
  
      <mdk-icon-profile
        *ngIf="parentWallet"
        [name]="parentWallet.name"
        [isClickable]="true"
        (buttonClick)="showAccountMenu = !showAccountMenu" />
    </section>
    
  </section>

  <section class="menu-container" [ngClass]="{ 'menu-show': showPanelMenu || !isMobile }">
    <div class="close-panel" *ngIf="isMobile">
      <i class="pi pi-times icon actionIcon" (click)="showPanelMenu = false"></i>
    </div>

    <mdk-btn
      *ngIf="!isMobile"
      class="add-button"
      [type]="'secondary'"
      [withBorder]="true"
      [buttonText]="'PARENT_PORTAL.HOME.ADD_BUTTON' | translate"
      (buttonClick)="addFunds()" />

    <ul class="list-container">
      <ng-container *ngIf="links$ | async as links">
        <mdk-menu-parent-item
          *ngFor="let item of links | menuAlphabeticSort"
          [item]="item" />
      </ng-container>
    </ul>

    <mdk-reminder-download-app *ngIf="!isMobile" class="reminder-container" />

    <ng-container *featureFlags="referralsFeatureFlag">
      <mdk-menu-parent-referral-button
        *ngIf="!isMobile"
      />
    </ng-container>
    
    <mdk-menu-parent-balance
      *ngIf="!isMobile"
      [parentWallet]="parentWallet"
      (clickEvent)="showAccountMenu = !showAccountMenu" />
  </section>

  <section class="menu-account-container" [ngClass]="{ 'menu-show': showAccountMenu }">
    <div class="close-panel" *ngIf="isMobile">
      <i class="pi pi-times icon accountActionIcon" (click)="showAccountMenu = false"></i>
    </div>
    <mdk-menu-parent-account />
  </section>
</section>
