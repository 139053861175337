import { inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SentryService } from 'src/app/configs/libraries/sentry/sentry.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private readonly angularFireAuth: AngularFireAuth = inject(AngularFireAuth)
  private readonly sentryService: SentryService = inject(SentryService)

  private userAppFirebase:string;
  private tokenAppFirebase:string;


  constructor(){
    this.userAppFirebase = environment.firebase.userApp
    this.tokenAppFirebase = environment.firebase.token
  }

  async login():Promise<void>{
    try {
      await this.angularFireAuth.signInWithEmailAndPassword(this.userAppFirebase,this.tokenAppFirebase)
    } catch (error) {
      this.sentryService.error(error)
    }
  }
}
