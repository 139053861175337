import { ActionReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";

export const LOCAL_STORAGE_REDUCERS = {
    AUTH: 'authentication',
    PROJECTS: 'projects',
    LANGUAGE: 'language',
    DEVICES: 'devices',
    PARENT: 'parent',
    ONBOARDING: 'onboarding',
    REFERRALS: 'referrals'
} as const

export const LOCAL_STORAGE_REDUCERS_KEYS = Object.values(LOCAL_STORAGE_REDUCERS)

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: LOCAL_STORAGE_REDUCERS_KEYS, rehydrate: true })(reducer);
}

