import { Component, Input } from '@angular/core';

@Component({
  selector: 'mdk-float-text-label',
  templateUrl: './float-text-label.component.html',
  styleUrls: ['./float-text-label.component.scss']
})
export class FloatTextLabelComponent {
  @Input() text:string = ''
  @Input() marginRight:string= ''
  @Input() marginLeft:string = ''

}
