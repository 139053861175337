import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[mdkNoTranslate]'
})
export class NoTranslateDirective {

  constructor(private element: ElementRef) {
    if(this.element){
      element.nativeElement.setAttribute('translate', 'no')
    }

   }

}
