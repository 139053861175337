import { Action, createReducer, on } from '@ngrx/store';
import { IUser } from 'src/app/pages/auth/interfaces/auth.interfaces';
import * as authActions from './authentication.actions';


export interface AuthState {
    user: IUser | null;
}

export const initialState: AuthState = {
    user: null,
}

const _authenticationReducer = createReducer(initialState,

    // user Actions
    on(authActions.setUser, (state, {authentication}) => ({ ...state, user: {...authentication}})),
    on(authActions.unSetUser, state => ({ ...state, user: null})),
    on(authActions.setAccessToken, (state,action) => ({ ...state, user: {...state.user,accessToken : action.token}})),
    on(authActions.refreshAccessToken, (state,action) => ({ ...state, user: {...state.user,accessToken : action.newToken}})),
    on(authActions.setCreatedAtUser, (state,action) => ({ ...state, user: {...state.user, userCreatedAt : action.date}})),
    on(authActions.setMetadataUser, (state,action) => ({ ...state, user: {...state.user, metadata : action.metadata}})),

    // Notifications Actions
    on(authActions.setNotifyUser, (state, { notify_me }) => ({...state, user: {...state.user, notify_me}})),

    // kyc actions
    on(authActions.setKycStatus, (state,action) => ({ ...state, user: {...state.user,kycStatus : action.status}})),

);

export function authenticationReducer(state: AuthState | undefined, action: Action) {
    return _authenticationReducer(state, action);
}